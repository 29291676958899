//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// omAPI Login - Contract Flow Participant  -   Version 1.005 - May 17, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';           
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';  
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import { LogOn, LogOff, AddressLogOn, displayMessage, GetTableFilter, displayError } from './CommonCode.js';
import { SessionInfo } from './App';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});

const styles = theme => ({
  palette: {primary: blue, secondary: blue, error: red, contrastThreshold: 3, tonalOffset: 0.2,},
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    primary: blue,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: '#0288d1', //theme.palette.secondary.main,
    primary: blue,
  },
  form: {width: '100%', marginTop: theme.spacing.unit,},
  submit: {
    marginTop: 0, /*theme.spacing.unit * 3,*/
  },
});
let LoginInProgress = false;
            
function SignIn(props) {
  const { classes } = props;
  var Email = '';
  var PW = '';
  var useWalletAddress = '';
  const handleSubmit = async (event) => {
    event.preventDefault();
    //if (Email === "")
    //  Email = "CFUser";
    //if (PW === "")
    //  PW = "ContractF4#";  
    if (LoginInProgress)
      return;
    LoginInProgress = true;
    let m;
    //console.log("useWalletAddress: " + useWalletAddress);
    if (useWalletAddress)
      m = await AddressLogOn();
    else
      m = await LogOn(Email, PW);
    LoginInProgress = false;
    //console.log("Sign in - m: " + m + " UserID: " + SessionInfo.userID);
    if (m === 'ok') {
      if (SessionInfo.HasPerm51)
        props.history.push("/ProjectDirectory");
      else if (SessionInfo.userID) {
        let CD = await GetTableFilter(543619, "OMUserID=" + SessionInfo.userID); // Get Participant ID 
        if (!CD.d.rows || !CD.d.rows.length) {
          displayError("Unable to Find Participant for the current User: " + SessionInfo.logonName);
          await LogOff();
          if (SessionInfo.History)
            SessionInfo.History.push("/AppMain"); // Note - that the componentWillUnmount will execute for the current page 
        }
        else {
          //console.log("Get User CD: " + JSON.stringify(CD));
          SessionInfo.CFParticipantID = CD.d.rows[0]["CFParticipantID"];
          SessionInfo.SelectedParticipantID = CD.d.rows[0]["CFParticipantID"];
          props.history.push("/ParticipantProfile");
        }
      }
    }
    else if (m)
      displayMessage("Sign In Error: " + m);
    else if (Email !== '')
      displayMessage("Unable to Sign In");
  }
  const chgEmail = (event) => {
    event.preventDefault();
    Email = event.target.value;
  }
  const chgPW = (event) => {
    event.preventDefault();
    PW = event.target.value;
  }
  const chgUseWallet = (event) => {
    //event.preventDefault();
    //console.log("chgUseWallet: " + JSON.stringify(event.target.checked));
    useWalletAddress = event.target.checked;
  }
  const gotoRegister = (event) => {
    event.preventDefault();
    props.history.push("/Register");
    //click(ToRegister);
  }

  return (
    <div id="Login" className="pageMain">
      <PageHeader L1='Home' Select='0' Title='Contract Flow - Sign In' IsLogon='y' />
      <main className={classes.main}>
        <MuiThemeProvider theme={theme}>                                                                       
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Contract Flow Sign In</Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input id="email" name="email" autoComplete="email" onChange={chgEmail} autoFocus />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" autoComplete="current-password" onChange={chgPW} />
              </FormControl>
              <FormControlLabel control={<Checkbox value={useWalletAddress} color="primary" onChange={chgUseWallet} />} label="Use Wallet Address" />
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={handleSubmit}>Sign In</Button>
              <Typography className="mui--text-subhead"><br />Not Registered yet? </Typography>
              <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={gotoRegister}>Register</Button>
            </form>
          </Paper>
        </MuiThemeProvider>
      </main>
      <PageFooter L1='Home' />
    </div>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);