//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ProjectContract - The main page and functionality to support the Solidity Contract
//                   Version 1.007 - June 11, 2019
// NOTE: there is a problem with web3 version 0.55 not returning from the create contract call and a problem with padding byte32 values with 0
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import 'whatwg-fetch';                              
import './App.css';
import './custom.scss';                                   
//import '@progress/kendo-theme-default/dist/all.css'; // dist/all.css 
import './custom.scss';                                
import { Grid as TGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-react-layout';
import { DateInput, DateTimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
//import '@progress/kendo-drawing';
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesLabels } from '@progress/kendo-react-charts';
import 'hammerjs';                   
//import Web3 from 'web3'; // Note - if this fails to load - it is probably due to a metamask problem 
import { SessionInfo } from './App';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import EditGridCommandCell from './EditGridCommandCell.jsx';  // edit grid 
import { GetTableRow, GetTableFilter, UpdateRow, DeleteRow, SaveRow, displayMessage, displayError, GetDropdownData, GetDependentDropdownData, displayWarning, GetFileExtension } from './CommonCode.js';
import { InitializeContract, LoadExistingWorkflow, LoadExistingContract, GetTxReceipt, GetCurrentInfo, GoToWorkflowStep, CancelContract, SaveToIPFS, LoadFile, DisplayFile, SaveDocumentInContract, DecryptDocument, GetPrivateKey, DecodeDocKey, getWeb3, getEthAccounts} from './SolidityInterface.js';  
import FormControl from '@material-ui/core/FormControl';   
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';   
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import CFContract from './CFContract';
//import styled, { css } from 'styled-components'; 
                                        
//const web3 = new Web3(window.web3.currentProvider); 
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});
//const StyledGrid = styled(TGrid)`
//  color: #D81052;
//  & .k-master-row.k-state-selected {
//    background-color: blue
//  }
//  & .k-alt.k-state-selected {
//    background-color: blue
//  }
//`;
let CreatingContract = false;
let RoleDD = [];
const labelContent = (e) => (e.category); 
let lockedScreen = false;

class RoleDropDownCell extends React.Component { 
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (!dataValue)
      dataValue = 0;                                          
    if (!this.props.dataItem.inEdit) {
      if (RoleDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {RoleDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={RoleDD} textField="ddName" dataItemKey="ddID" value={RoleDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}

class ProjectContract extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    console.log("ProjectContract Construct: " + SessionInfo.session);
    this.loadExistingPage = false;
    if (SessionInfo.session) {
      if (SessionInfo.SelectedProjectContractID === 0 && SessionInfo.SelectedProjectID === 0) { // Restore Saved ProjectContract
        if (SessionInfo.ProjectContract) { // Data saved for this session                     
          this.state = SessionInfo.ProjectContract;
          this.state.isCreatingContract = false; 
          this.loadExistingPage = true;
        }
      }
      CreatingContract = false;
      this.initializeContract = InitializeContract.bind(this);  
      this.loadExistingWorkflow = LoadExistingWorkflow.bind(this);
      this.loadExistingContract = LoadExistingContract.bind(this);
      this.getTxReceipt = GetTxReceipt.bind(this);
      this.getCurrentInfo = GetCurrentInfo.bind(this);
      this.goToWorkflowStep = GoToWorkflowStep.bind(this);
      this.cancelContract = CancelContract.bind(this);
      this.saveToIPFS = SaveToIPFS.bind(this);
      this.loadFile = LoadFile.bind(this);
      this.displayFile = DisplayFile.bind(this);
      this.saveDocumentInContract = SaveDocumentInContract.bind(this);
      this.decryptDocument = DecryptDocument.bind(this);
      this.getPrivateKey = GetPrivateKey.bind(this);
      this.decodeDocKey = DecodeDocKey.bind(this);
      //------------- Edit Grid ------------------------------ 
      this.enterInsert = this.enterInsert.bind(this);
      this.itemChange = this.itemChange.bind(this);
      const enterEdit = this.enterEdit.bind(this);
      const save = this.save.bind(this);
      const cancel = this.cancel.bind(this);
      const remove = this.remove.bind(this);
      this.CommandCell = EditGridCommandCell(enterEdit, remove, save, cancel, "inEdit", "EmailAddress", "CFPPID");
      //-------- Standard Function Setup ------------------
      SessionInfo.currentPage = "ProjectContract";
      SessionInfo.currSaveFunc = this.saveRecord;
      SessionInfo.currDeleteFunc = this.deleteRecord;
      SessionInfo.currClearFunc = this.clearRecord;
      SessionInfo.searchFunc = this.searchCurrent;
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    CFProjectContractID: 0,
    CFProjectID: 0,
    CurrentStepID: 0,
    CurrentRoleID: 0,
    DocumentTypeID: 0,
    CFProjectStatusID: 1,
    TotalGasUsed: 0,
    TotalRefundGas: 0,
    CFWorkflowID: 0,
    StartDateTime: new Date(),
    EndDateTime: new Date(),
    LastAccessDate: new Date(),
    LastChangeDateTime: new Date(),
    ContractDate: new Date(),
    EmailAllowed: false,
    InitialContribution: 40,

    string: '',
    ProjectContractCode: '',
    ProjectPrefix: '',
    ProjectContractName: '',
    ProjectInstanceContractAddress: '',
    Description: '',
    Comments: '',    
    GasPrice: '3',
    ProjectURI: '',
    ContractVersion: '',
    DocumentKey: '',
    DocumentNonce: '',
    DocumentSalt: '',
    IPFSDocumentAddress: '',
    EthereumTransactionNumber: '',
    ProjectName: '',
    ProjectSummary: '',
    ContractAddress: '',

    selectedTab: 0,
    stateHasChanged: false,

    // Contract interact values
    spinnerStyle: { height: 200 },
    autoLoadContract: false,
    isParticipant: false,
    currentParticipantID: 0,
    gasUsed: '',
    currentGasPrice: 0,
    ownerBalance: 0,
    workflowFileName: '',
    workflowName: '',
    workflowDescription: '',
    workflowVersion: '',
    MetaMaskAccount: '',
    remindMetaMask: true,
    isNewContract: false,
    //----------------------------------------------- 
    ipfsDocumentAddress: '',
    documentEncrypt: '', // Encrypted Document
    documentClear: '', // Clear Text
    ContractFileName: '',
    ipfsEncObj: '',
    contractCreate: true,
    blockNumber: '',
    transactionHash: '',
    totalAllGasUsed: '',
    totalRoleGasUsed: '',
    txReceipt: '',
    DocumentKeyEncrypted: '',
    currentPrivateKey: '',
    baseKey: '',
    ContractDocumentType: '',
    encryptScheme: 'omN002',
    roleCount: 0,
    stepCount: 0,
    roleGridData: [],
    stateGridData: [],
    contractCurrentStep: '',
    contractCurrentRole: '',
    activeRoles: '',
    stepName: '',
    roles: [],              // Roles assigned in Project Contract - Loaded from DB
    contractRoles: [],      // Roles loaded from Ethereum Contract
    roleAddressesSet: false,
    walletAddresses: ['', '', '', '', '', '', '', ''], // Wallet addresses from Participant Profiles
    publicKeys: ['', '', '', '', '', '', '', ''],      // Public keys from Participant Profiles        
    contractWalletData: [], // Role and Wallet data from the contract //walletInitData.slice(0), 
    ownerAddress: '',
    addressFieldsRO: false,
    currentRole: '',
    procDefStr: '',
    statesForRole: [{ "ddID": "NONE", "ddName": "Select..." }],
    cancelStatus: '',
    isContractLoaded: false,
    numPages: null,
    initButtonTitle: 'Init Contract Info',
    isInitState: true,
    buttonsVisible: false,
    isCurrOwner: false,
    cancelComplete: '',
    isSavingDocument: false,
    isCreatingContract: false,
    isContractComplete: false,
    createButtonText: 'Create Ethereum Contract',
    //-----------------------------------------------

    sortParticipants: [ { field: 'LastName', dir: 'asc'},{ field: 'FirstName', dir: 'asc' }], 
    NextStepName: 'NONE',
    ProjectTypes: [],
    CFProjectParticipants: [],
    CFProjectContractParticipants: [],
    saveCFProjectContractParticipants: [],
    BlockchainTransactions: [],
    DocumentTypes: [],
    Workflows: [],
    WorkflowRoles: [],      // Roles DropDown Data
    WorkflowSteps: [],      // Steps DropDown Data
    CFProjectStatusSet: [],
    CFWorkflowSteps: [],     // Workflow Steps Data
    WorkflowStepChart: [],
    panes: [{ collapsible: true }, { size: '70%', min: '20px', collapsible: false }], // Note {}, removed
    nestedPanes: [{ size: '45%' }, { collapsible: true }]  // Note {}, removed
  };
  // SolidityInterface Functions
  initializeContract;
  //createContractFlow;
  getLastestContractAddress;
  loadExistingWorkflow;
  LoadExistingContract;
  getTxReceipt;
  getCurrentInfo;
  goToWorkflowStep;
  cancelContract;
  saveToIPFS;
  loadFile;
  displayFile;
  saveDocumentInContract;
  loadExistingPage = false;
  //--------------- Did Mount -------------------------------------------------------------------
  async componentDidMount() {            
    if (!SessionInfo.session || SessionInfo.session === '')
      this.props.history.push("/AppMain");
    else {
      if (!SessionInfo.web3)
        getWeb3();
      if (!SessionInfo.web3) {
        displayError("MetaMask is required to access the Contract Page");
        lockedScreen = true; // MetaMask is not available
        //this.props.history.push("/AppMain");
      }
      if (this.loadExistingPage === true)
        this.forceUpdate();
      else {
        if (SessionInfo.SelectedProjectContractID > 0) { // Existing ProjectContract - Auto-Load the Contract
          console.log("Load Project Contract ID: " + SessionInfo.SelectedProjectContractID);
          await this.getProjectRecord(SessionInfo.SelectedProjectID); // Get any updated Project Information and Workflow
          await this.getRecord(SessionInfo.SelectedProjectContractID);
          displayMessage("GREEN");
          this.state.CFProjectContractID = SessionInfo.SelectedProjectContractID;
          SessionInfo.SelectedProjectContractID = 0
          if (SessionInfo.web3) {
            let web3version = SessionInfo.web3.version;
            console.log("Metamask is active - Web3 Version: " + web3version);
          }
          console.log("DidMount Init - ProjectInstanceContractAddress: " + this.state.ProjectInstanceContractAddress);
          //this.getParticipantRecords();
          if (this.state.ProjectInstanceContractAddress && this.state.ProjectInstanceContractAddress !== '') { // Auto-Load Contract Information
            console.log("Load Existing Contract: " + this.state.ProjectInstanceContractAddress);
            this.state.isNewContract = false;
            if (SessionInfo.web3) {
              this.setState({ autoLoadContract: true });  // Auto LoadExisting Contract - Set Spinner
              this.setState({ createButtonText: "Loading..." });
              this.forceUpdate();
              //await this.onInitContract(); //-------- Load Existing Contract ------------ call InitializeContract 
              await this.ConvertModelToJson();
              //console.log("Load Contract - walletAddresses: " + JSON.stringify(this.state.walletAddresses));
              //this.state.contractCreate = false; // Load Contract Info
              //
              await this.initializeContract(); //-------- Verify that the Contract Address is Valid ------------ 
              //
              if (this.state.roleCount > 0)
                this.setState({ createButtonText: 'Refresh' });
              else
                this.setState({ createButtonText: 'Load Contract' });
              this.setState({ autoLoadContract: false });  // Turn off Spinner
            }
          }
          else {
            //--- Testing --- Normally commented out       
            //console.log("Get Contract Address");
            //this.state.contractCreate = false;
            //await this.ConvertModelToJson();
            ////console.log("procDefStr: " + JSON.stringify(this.state.procDefStr));
            //if (this.state.procDefStr) {
            //  let wb3version = SessionInfo.web3.version;
            //  displayMessage("Metamask is active - Create Contract - Web3 Version: " + wb3version);
            //  this.setState({ contractCreate: true }); // Create a new instance
            //  //
            //  await this.initializeContract(); //-------- INIT CONTRACT InitializeContract in SolidityInterface ------------ 
            //  //
            //  this.setState({ CFProjectStatusID: 3 });
            //}
            //if (this.state.roleCount > 0)
            //  this.setState({ createButtonText: 'Refresh' });
            //else
            //  this.setState({ createButtonText: 'Load Contract' });
            //--- Testing ---
            this.state.isNewContract = true;
          }
        }
        else if (SessionInfo.SelectedProjectID > 0) { // Project Exists - New ProjectContract - NOTE - the ProjectContractID is created in the Project Screen
          console.log("Create NEW Project Contract - Get Project Desc for ID: " + SessionInfo.SelectedProjectID);
          this.clearRecord();
          this.setState({ CFProjectID: SessionInfo.SelectedProjectID });
          this.setState({ GasPrice: '3' });
          this.setState({ InitialContribution: 30 });
          await this.getProjectRecord(SessionInfo.SelectedProjectID);
          this.setState({ CFProjectStatusID: 1 });
          if (this.state.CFProjectContractID === 0) {
            let contractID = this.state.ProjectPrefix.trim() + Math.floor(Math.random() * 100000000);
            //console.log("contractID: " + contractID);
            this.setState({ ProjectContractCode: contractID });
            displayMessage("Define New Project Contract: " + contractID);
          }
        }
        //else { // Re-Enter ProjectContract Screen - NOTE - This is done in the constructor
        //  if (SessionInfo.ProjectContract) { // Data saved for this session 
        //    this.state = SessionInfo.ProjectContract;
        //  }
        //}
        //console.log("---- ProjectContractID: " + this.state.CFProjectContractID + " Prefix: " + this.state.ProjectPrefix);
        if (this.state.ProjectTypes.length <= 1) {
          let CD = await GetDropdownData(492545);
          this.setState({ ProjectTypes: CD.d });
        }
        if (this.state.DocumentTypes.length <= 1) {
          let CD = await GetDropdownData(489136);
          //console.log("DocumentTypes: " + JSON.stringify(CD));
          this.setState({ DocumentTypes: CD.d });
        }
        if (this.state.Workflows.length <= 1) {
          let CD = await GetDropdownData(543767);
          this.setState({ Workflows: CD.d });
        }
        if (this.state.CFProjectStatusSet.length <= 1) {
          let CD = await GetDropdownData(544101);
          this.setState({ CFProjectStatusSet: CD.d });
        }
        //console.log("---- WorkflowID: " + this.state.CFWorkflowID);
        if (!RoleDD || RoleDD.length === 0) {
          let CD = await GetDependentDropdownData(543821, this.state.CFWorkflowID, 543769); // CFWorkflowRoles based on: this.state.CFWorkflowID  
          RoleDD = CD.d;
          if (!RoleDD || RoleDD.length <= 0)
            RoleDD = [{ ddName: '', ddID: 0 }];
          else
            console.log("RoleDD length: " + RoleDD.length + " RoleDD type: " + typeof RoleDD);
        }
        //console.log("ProjectContract - RoleDD: " + JSON.stringify(RoleDD));
        await this.getProjectParticipants();
        if (this.state.CFProjectContractParticipants.length === 0) {
          await this.getProjectContractParticipants();
        }
        await this.getProjectContractBlockchainTx();
      }
      this.setState({ autoLoadContract: false }); // Turn off Spinner
      displayMessage("GREEN");
      // Commented Out - May 27th
      //if (lockedScreen) // 21May19 - need to verify this
      //  return;
      //else { // This should not be necessary -  auto-loaded above
      //  console.log("Load ProjectContract Complete = autoLoad: " + this.state.autoLoadContract);
      //  this.setState({ autoLoadContract: false });  // Turn off for Test
      //  if (this.state.autoLoadContract === true) { // Auto LoadExisting Contract
      //    this.setState({ createButtonText: "Loading..." });
      //    await this.onInitContract(); //-------- Load (Refresh) Existing Contract ------------ 
      //    this.setState({ autoLoadContract: false });  // Auto LoadExisting Contract
      //    if (this.state.roleCount > 0)
      //      this.setState({ createButtonText: 'Refresh' });
      //    else
      //      this.setState({ createButtonText: 'Load Contract' });
      //  }
      //}
    }
  } // End of ComponentDidMount
  componentWillUnmount() {
    if (this.state.stateHasChanged === true && this.state.isParticipant === true) {
      this.setState({ stateHasChanged: false });
      this.saveRecord();
    }
    SessionInfo.ProjectContract = this.state;
  }
  CommandCell;              
  // Fetch the Invite List of Participants
  getProjectParticipants = async () => {
    let CD = await GetTableFilter(543727, "*CFProjectID=" + this.state.CFProjectID + " and CFProjectContractID=0"); // Get Participants attached to this Project 
    if (CD.x.o === 0)
      displayError("Access to OM Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
    else if (CD.x.o < 9500) {
      try {
        const CFProjectParticipants = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem)); // Add the Selected Column
        //console.log("Project Participants: " + JSON.stringify(CFProjectParticipants));    
        this.setState({ CFProjectParticipants });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  // Fetch the list of currently selected Participants
  getProjectContractParticipants = async () => {
    if (this.state.CFProjectContractID > 0) {
      let CD = await GetTableFilter(543727, "*CFProjectContractID=" + this.state.CFProjectContractID); // Get Participants attached to this Project 
      if (CD.x.o === 0)
        displayError("Access to OM Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
      else if (CD.x.o < 9500) {
        try {
          const CFProjectContractParticipants = CD.d.rows; // .map(dataItem => Object.assign({ selected: false }, dataItem));
          this.setState({ saveCFProjectContractParticipants: CFProjectContractParticipants });
          //console.log("ProjectContractParticipants Table: " + JSON.stringify(CFProjectContractParticipants));
          this.setState({ CFProjectContractParticipants });
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  }
  // Fetch the list of currently selected Participants
  getProjectContractBlockchainTx = async () => {
    if (this.state.CFProjectContractID > 0) {
      let CD = await GetTableFilter(544028, "*CFProjectContractID=" + this.state.CFProjectContractID); // Get BlockchainTransactions attached to this Project 
      if (CD.x.o === 0)
        displayError("Access to OM Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
      else if (CD.x.o < 9500) {
        try {
          const BlockchainTransactions = CD.d.rows; // .map(dataItem => Object.assign({ selected: false }, dataItem));  
          this.setState({ BlockchainTransactions });
          console.log("BlockChainTx Count: " + BlockchainTransactions.length);
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  }
  getWorkflowRoles = async () => {
    //console.log("DidMount RoleDD - props: " + JSON.stringify(this.props));
    let CD = await GetDependentDropdownData(543821, this.state.CFWorkflowID, 543769);
    //console.log("Workflow Roles for Workflow: " + this.state.CFWorkflowID + " DD: " + JSON.stringify(CD.d));
    this.setState({ WorkflowRoles: CD.d });
    RoleDD = CD.d;
  }
  getWorkflowSteps = async () => {
    //console.log("getWorkflowSteps - WFID: " + this.state.CFProjectContractID);
    let CD = await GetDependentDropdownData(543800, this.state.CFWorkflowID, 543769);
    //console.log("getWorkflowSteps: " + JSON.stringify(CD));
    this.state.WorkflowSteps = CD.d;
  }
  getWFSteps = async () => {
    let CD = await GetTableFilter(543800, "*CFWorkFlowID=" + this.state.CFWorkflowID); // Steps for Workflow  
    //console.log("Get WorkflowSteps: " + JSON.stringify(CD));
    if (CD.x.o === 0)
      displayError("Access to OM for Steps Has Failed in " + SessionInfo.currentPage);
    else if (CD.x.o < 9500) {
      try {
        this.setState({ CFWorkflowSteps: CD.d.rows });
        //console.log("WF rows: " + JSON.stringify(CD.d.rows));
        let WorkflowStepChart = [];
        for (let i = 0, row; i < CD.d.rows.length; i++) {
          row = CD.d.rows[i];
          //console.log("WF row: " + JSON.stringify(row));
          //console.log("StepName: " + row.StepName);
          if (WorkflowStepChart.findIndex(rw => rw.StepName === row.StepName) < 0) // If stepname does not already exist
            WorkflowStepChart.push({ "StepName": row.StepName, "value": 10 });
        }
        //console.log("WorkflowStepChart: " + JSON.stringify(WorkflowStepChart));
        this.setState({ WorkflowStepChart });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  onLayoutChange = (updatedState) => {
    this.setState({ panes: updatedState });
  }
  onNestedLayoutChange = (updatedState) => {
    this.setState({ nestedPanes: updatedState });
  }
  getTxReceipt = async () => { // Get Transaction Receipt
    try {
      //this.setState({ blockNumber: "waiting.." });
      //this.setState({ gasUsed: "waiting..." });
      // get Transaction Receipt in console on click
      // See: https://web3js.readthedocs.io/en/1.0/web3-eth.html#gettransactionreceipt
      await SessionInfo.web3.eth.getTransactionReceipt(this.state.transactionHash, (error, txReceipt) => {
        if (error)
          console.log("Get Tx Receipt Error: " + error);
        this.setState({ txReceipt });
      }); //await for getTransactionReceipt
      await this.setState({ blockNumber: this.state.txReceipt.blockNumber });
      await this.setState({ gasUsed: this.state.txReceipt.gasUsed });
    } //try
    catch (error) {
      console.log(error);
    } //catch
  } //getTxReceipt
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  saveRecord = async () => {
    if (lockedScreen)
      return;                
    //console.log("Save ProjectContract - state: " + JSON.stringify(this.state));  
    if (this.state.isParticipant === true || this.state.CFProjectStatusID <= 1) {
      this.setState({ isParticipant: true });
      let copyState = Object.assign({}, this.state);
      let type = 0;
      for (var prop in copyState) {
        if (prop === "string") {
          type = 1;
          copyState[prop] = undefined;
        }
        else if (prop === "selectedTab")
          type = 2;
        else if (type === 0) {
        }
        if (type === 2) {
          copyState[prop] = undefined;
        }
      }
      //console.log("Project Contract: " + JSON.stringify(copyState));
      let PKID = await SaveRow(543972, copyState, copyState.CFProjectContractID, "CFProjectContractID"); // Save ProjectContract
      console.log("After Save - PKID: " + PKID);
      SessionInfo.ProjectContractsUpdated = true;
      this.setState({ stateHasChanged: false });
      await this.setState({ CFProjectContractID: PKID });
    }
    else
      displayWarning("Only Participants can Save");
  }
  deleteRecord = async () => {
    if (lockedScreen)
      return;
    //console.log("Delete ProjectContract: " + this.state.CFProjectContractID);  
    if (this.state.isParticipant === true) {
      let CD = await DeleteRow(543972, this.state.CFProjectContractID);
      console.log("Message Level after delete: " + CD.x.o);
      SessionInfo.ProjectContractsUpdated = true;
      this.setState({ stateHasChanged: false });
    }
    else
      displayWarning("Only Participants can Delete");
  }
  clearRecord = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - state: " + JSON.stringify(this.state)); 
    let type = 0;
    for (var prop in this.state) {
      if (prop === "selectedTab")
        break;
      else if (prop === "string")
        type = 1;
      else if (type === 0) {
        let obj = {};
        if (prop.indexOf("Date") >= 0)
          obj[prop] = new Date();
        else
          obj[prop] = 0;
        this.setState(obj);
      }
      else if (type === 1) {
        let obj = {};
        obj[prop] = '';
        this.setState(obj);
      }
    }
    this.setState({ CFProjectContractParticipants: [] });
    this.setState({ stateHasChanged: false });
  }
  getRecord = async (Key) => {
    let CD = await GetTableRow(543972, Key); // Get ProjectContract 
    //console.log("Get Project Case: " + JSON.stringify(CD));
    this.setState({ CFProjectContractID: Key });
    for (var prop in CD.d.row) {
      if (prop in this.state) {
        //console.log("Set " + prop + ": " + CD.d.row[prop]); 
        let obj = {};
        obj[prop] = CD.d.row[prop];
        if (prop.indexOf("Date") >= 0) {
          //console.log("Set Date - " + prop + ": " + CD.d.row[prop]);
          obj[prop] = new Date(CD.d.row[prop]);
        }
        this.setState(obj);
        //this.state[prop] = CD.d[prop];
        //this.setState({ [prop]: CD.d[prop] });
      }
    }
  }
  getProjectRecord = async (Key) => {
    let CD = await GetTableRow(543609, Key); // Get Project   
    //console.log("after Get Project Record: " + JSON.stringify(CD.d));
    this.setState({ CFProjectID: Key });
    for (var prop in CD.d.row) {
      if (prop in this.state) {
        let obj = {};
        obj[prop] = CD.d.row[prop];
        if (prop.indexOf("Date") >= 0) {
          //console.log("Date - " + prop + ": " + CD.d.row[prop]);
          obj[prop] = new Date(CD.d.row[prop]); //new Date()
        }
        this.setState(obj);
      }
    }
    //console.log("CFWorkflowID: " + this.state.CFWorkflowID + " CFProjectID: " + this.state.CFProjectID);
    await this.getWorkflowRoles();
    await this.getWorkflowSteps();
    await this.getWFSteps();
  }
  rowClick = async (event) => {
    //let last = this.lastSelectedIndex;
    const current = this.state.CFProjectContractParticipants.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      if (this.state.CFProjectContractParticipants.filter(p => p.inEdit).length > 0) { // If any row is in Edit
        displayWarning("Table is currently being edited");
        return;
      }
      let PKID = this.state.CFProjectContractParticipants[current].CFParticipantID;
      SessionInfo.SelectedParticipantID = PKID;
      this.props.history.push("/ParticipantProfile");
    }
  }
  //------------------------- Edit Grid - Participants ------------------------------------------------------------------
  enterInsert() {
    if (this.state.CFProjectStatusID >= 3)
      displayWarning("Cannot Add Participants after Contract Set Up");
    else {
      const dataItem = { inEdit: true };
      const allRecords = this.state.CFProjectContractParticipants.slice();
      allRecords.unshift(dataItem); // Add to the beginning
      this.updateCFPP(allRecords, dataItem);
      this.setState({ CFProjectContractParticipants: allRecords });
    }
  }
  enterEdit(dataItem) {
    if (this.state.CFProjectStatusID >= 3)
      displayWarning("Cannot Edit Participants after Contract Set Up");
    else {
      //console.log("CFProjectParticipants: " + JSON.stringify(this.state.CFProjectParticipants));
      //console.log("dataItem: " + JSON.stringify(this.state.dataItem));
      this.updateCFPP(this.state.CFProjectContractParticipants, dataItem).inEdit = true;
      this.setState({ CFProjectContractParticipants: this.state.CFProjectContractParticipants.slice() });
    }
  }
  save(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.CFPPID === undefined)
      dataItem.CFPPID = 0;
    dataItem.CFPPID = this.updateCFPP(this.state.CFProjectContractParticipants, dataItem).CFPPID;
    this.setState({ CFProjectContractParticipants: this.state.CFProjectContractParticipants.slice() });
    UpdateRow(543720, this.state.CFProjectContractParticipants, dataItem, dataItem.CFPPID, "CFPPID"); // Save ProjectParticipants to OM
  }
  cancel(dataItem) {
    if (dataItem.CFPPID) {
      let originalItem = this.state.saveCFProjectContractParticipants.find(p => p.CFPPID === dataItem.CFPPID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.updateCFPP(this.state.CFProjectContractParticipants, originalItem);
    } else {
      this.updateCFPP(this.state.CFProjectContractParticipants, dataItem, !dataItem.CFPPID); // remove false
    }
    this.setState({ CFProjectContractParticipants: this.state.CFProjectContractParticipants.slice() });
  }
  remove(dataItem) {
    if (this.state.CFProjectStatusID >= 3)
      displayWarning("Cannot Remove Participants after Contract Advanced");
    else {
      dataItem.inEdit = undefined;
      let key = dataItem.CFPPID;
      //console.log("remove: " + key);
      this.updateCFPP(this.state.CFProjectContractParticipants, dataItem, true);
      this.updateCFPP(this.state.saveCFProjectContractParticipants, dataItem, true);
      DeleteRow(543720, key); // Delete in the CFProjectContractParticipants Table in OM
      this.setState({ CFProjectContractParticipants: this.state.CFProjectContractParticipants.slice() });
    }
  }
  itemChange(event) {
    const value = event.value;
    const name = event.field;
    if (name !== undefined) {
      const updatedData = this.state.CFProjectContractParticipants.slice();
      const item = this.updateCFPP(updatedData, event.dataItem);
      item[name] = value;
      this.setState({ CFProjectContractParticipants: updatedData });
    }
  }
  updateCFPP(data, item, remove) {  // data - is the entire data set (JSON), item - is the current line item
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.CFPPID && p.CFPPID === item.CFPPID); // Note - for new line will find 0
    if (index >= 0) {
      //console.log("update index: " + index);
      updated = Object.assign({}, item);
      data[index] = updated;
      if (remove) {
        //console.log("remove index: " + index);
        data = data.splice(index, 1);
      }
    }
    return data[index];
  }
  //------------------------- Select Main----------------------------------------------------------------------
  selectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  //------------------------- Field Edit ------------------------------------------------------------------  
  lastSelectedStepIndex;
  //stepsRowClick = async (event) => {
  //  //let last = this.lastSelectedStepIndex;
  //  //console.log("dataitem: " + JSON.stringify(event.dataItem));
  //  const index = this.state.CFWorkflowSteps.findIndex(dataItem => dataItem === event.dataItem);
  //  this.state.CFWorkflowStepID = this.state.CFWorkflowSteps[index].CFWorkflowStepID;
  //  //console.log("Row-Click CFWorkflowStepID: " + this.state.CFWorkflowStepID + " index: " + index);
  //  this.getStepRecord(this.state.CFWorkflowStepID);
  //  this.setState({ selectedTab: 5 });
  //}

  handleSelect = async (e) => {
    //console.log("selectedTab: " + e.selectedTab);
    if (e.selectedTab >= 3) {
      if (this.state.CFProjectContractID === 0) {
        displayWarning("You must Save the current information first");
        return;
      }
    }
    this.setState({ selectedTab: e.selected });
  }
  //getStepRecord = async (Key) => {
  //  let CD = await GetTableRow(543800, Key); // Get WorkflowStepInfo
  //  //console.log("StepRecord: " + JSON.stringify(CD));             
  //  //console.log("Roles: " + JSON.stringify(this.state.WorkflowRoles));
  //  //console.log("Steps: " + JSON.stringify(this.state.WorkflowSteps));
  //  const DDs = {
  //    "CFWorkflowRoleID": this.state.WorkflowRoles, "TransitionTo1ID": this.state.WorkflowSteps, "TransitionTo2ID": this.state.WorkflowSteps, "TransitionTo3ID": this.state.WorkflowSteps
  //  };
  //  this.setState({ CFWorkflowStepID: Key });
  //  for (var prop in CD.d.row) {
  //    if (prop in this.state) {
  //      //console.log("Set " + prop + ": " + CD.d.row[prop]);
  //      let obj = {};
  //      obj[prop] = CD.d.row[prop];
  //      if (prop.indexOf("ID") > 0 && prop !== "CFWorkflowStepID" && prop !== "CFProjectContractID" && DDs[prop] !== undefined) {
  //        //console.log("Lookup Prop: " + prop + " ddID: " + CD.d.row[prop] + " DDs: " + JSON.stringify(DDs[prop]) + " entries: " + DDs[prop].length);
  //        for (let ix = 0; ix < DDs[prop].length; ix++) {
  //          let objp = DDs[prop][ix];
  //          //console.log("Lookup val: " + JSON.stringify(objp));
  //          if (objp.ddID === CD.d.row[prop]) {
  //            obj[prop] = objp;
  //            //console.log("Found Prop: " + prop + " ddID: " + CD.d.row[prop] + " value:" + JSON.stringify(objp));
  //            break;
  //          }
  //        }
  //      }
  //      this.setState(obj);
  //      //this.state[prop] = CD.d[prop];
  //      //this.setState({ [prop]: CD.d[prop] });
  //    }
  //  }
  //}
  handleSubmit = (event) => {
    event.preventDefault();
    //console.log('Submitted: ' + this.state.Email + '-' + PW);
    //if (PW !== ConfirmPW) {
    //  message = "Passwords must match";
    //}
    //else {
    //  UserRegister(FirstName, LastName, Email, PW, ConfirmPW);
    //  props.history.push("/AppMain");
    //}
  }
  chgFldVal(event) {
    if (!lockedScreen) {
      var stateCopy = Object.assign({}, this.state[event.target.name]);
      stateCopy = event.target.value;
      this.setState({ [event.target.name]: stateCopy });
    }
  }
  chgDDFldVal = (event) => {
    if (!lockedScreen) {
      this.setState({ [event.target.name]: event.target.value.ddID });
      this.setState({ stateHasChanged: true });
    }
  }
  chgCheckboxVal(event) {
    if (!lockedScreen) {
      var stateCopy = Object.assign({}, this.state[event.target.name]);
      stateCopy = !stateCopy;
      this.setState({ [event.target.name]: stateCopy });
    }
  }
  //------------------------- Document Control ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  onSelectDocument = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    console.log("File: " + JSON.stringify(file));
    this.setState({ ContractFileName: file.name });
    let reader = new window.FileReader();
    try {
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader);
      displayMessage("Document: " + file.name + " Loaded");
    }
    catch (error) {
      console.log("FileReader Error: " + error);
      displayError("Error on File Read");
    } //catch      
    let ContractDocumentType = GetFileExtension(file.name);
    //console.log("DocType: " + ContractDocumentType);
    ContractDocumentType = ContractDocumentType.toUpperCase();
    //console.log("DocType: " + ContractDocumentType);
    this.setState({ ContractDocumentType });
    let ix = this.state.DocumentTypes.findIndex(c => c.ddName === ContractDocumentType);
    if (ix >= 0)
      this.setState({ DocumentTypeID: this.state.DocumentTypes[ix].ddID });
    else
      displayWarning("Unknown file type: " + ContractDocumentType);
  }
  convertToBuffer = async (reader) => { // File is converted to a buffer to prepare for uploading to IPFS
    const documentClear = await Buffer.from(reader.result);
    //set this buffer -using es6 syntax
    this.setState({ documentClear });
    //console.log("Document Length: " + documentClear.length);
  };
  //------------------------- Ethereum Contract Control ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  onCreateContract = async (event) => {
    if (lockedScreen)
      return;
    //console.log("Get Accounts"); 
    //if (this.state.isCreatingContract === true) {
    //  displayMessage("Contract Creation in Progress");
    //  return;
    //}  
    const accounts = await getEthAccounts();
    if (!accounts[0]) {
      displayError("MetaMask must be Setup and Logged On inorder to Create a Solidity Contract");
      return;
    }
    if (CreatingContract === true) {
      displayMessage("Contract Creation in Progress");
      return;
    }
    CreatingContract = true;
    try {
      this.setCurrentParticipantID(accounts[0]);
      this.setState({ autoLoadContract: true });  // Trigger Spinner
      //--------------------------------------------------------------------------
      if (lockedScreen)
        return;
      let web3version = SessionInfo.web3.version;
      console.log("onInitContract - Load Contract - wb3version: " + web3version);
      if (this.state.GasPrice === '0') {
        displayWarning("Gas Price must be Set greater than 0");
        return;
      }
      try {
        if (this.state.ProjectInstanceContractAddress) { // Load Existing Contract - Auto Load or Refresh button press 
          await this.ConvertModelToJson();
          //console.log("Load Contract - walletAddresses: " + JSON.stringify(this.state.walletAddresses));
          //this.state.contractCreate = false; // Load Contract Info
          await this.initializeContract(); //-------- Verify that the Contract Address is Valid ------------
          if (this.state.roleCount > 0)
            this.setState({ createButtonText: 'Refresh' });
        }
        else { // No Contract Address - Initialize a new Ethereum Contract - Only called from Button Press
          //console.log("Create JSON");
          await this.ConvertModelToJson();
          //console.log("procDefStr: " + JSON.stringify(this.state.procDefStr));
          if (this.state.procDefStr) {
            displayMessage("Create Contract - Web3 Version: " + web3version);
            this.setState({ contractCreate: true }); // Create a new instance
            await this.initializeContract(); //-------- INIT CONTRACT InitializeContract in SolidityInterface ------------  
            this.setState({ CFProjectStatusID: 3 });
          }
          //else
          //  displayError("Workflow Definition Failed");
        }
      }
      catch (error) {
        //displayError(error);
        console.log("InitContract Error: " + error);
        displayError("Contract Initialization Failed");
      } //catch

      //--------------------------------------------------------------------------
      this.setState({ autoLoadContract: false });
    }
    catch { }
    this.setState({ isCreatingContract: false });
    CreatingContract = false;
  }                                        
  // Load workflow information into JSON
  ConvertModelToJson = async () => {
    let ProcDef = {};
    let roles = [];
    let stepChanges = [];
    let fail = false;
    this.state.procDefStr = undefined;
    ProcDef.name = this.state.ProjectContractName;
    ProcDef.description = this.state.Description;
    ProcDef.version = "0.4";
    ProcDef.uri = "";
    console.log("Convert Model to Json");
    if (this.state.CFProjectContractParticipants.length === 0) {
      await this.getProjectContractParticipants();
    }
    let CD = await GetTableFilter(543821, "*CFWorkFlowID=" + this.state.CFWorkflowID); // Get Roles for the Workflow 
    if (CD.x.o === 0) {
      displayError("Access to OM for Get Workflow Roles Has Failed in " + SessionInfo.currentPage);
      fail = true;
    }
    else if (CD.x.o < 9500) {
      try {
        //console.log("-------------- Convert Model - rows: " + JSON.stringify(CD.d.rows));
        for (var ix = 0; ix < CD.d.rows.length; ix++) {
          let row = CD.d.rows[ix];
          //console.log("row: " + JSON.stringify(row));
          //console.log("role: " + row.RoleName);
          var obj = { "role": row.RoleName, "roleQual": "1" };
          //console.log("role obj: " + JSON.stringify(obj));
          roles.push(obj); // Load roles from DB
        }
        //console.log("roles: " + JSON.stringify(roles)); 
        ProcDef.roles = roles;
      } catch (error) {
        console.log("ConvertToJson Error: " + error.message);
        fail = true;
      }
    }
    if (fail === false) {
      CD = await GetTableFilter(543800, "*CFWorkFlowID=" + this.state.CFWorkflowID); // Get Steps for Workflow  
      if (CD.x.o === 0) {
        displayError("Access to OM for Steps Has Failed in " + SessionInfo.currentPage);
        fail = true;
      }
      else if (CD.x.o < 9500) {
        try {
          //console.log("Step rows: " + JSON.stringify(CD.d.rows));
          for (ix = 0; ix < CD.d.rows.length; ix++) {
            let row = CD.d.rows[ix];
            let obj = {};
            let SCR = {};
            let duplicate = false;
            let stepChangeRoles = [];
            //console.log("row: " + JSON.stringify(row));
            //console.log("step: " + row.StepName);        
            let index = stepChanges.findIndex(p => p.step === row.StepName);
            if (index > 0) {
              console.log("Duplicate State: " + row.StepName);
              stepChangeRoles = stepChanges[index].stepChangeRoles;
              duplicate = true;
            }
            obj.step = row.StepName;
            if (row.CFWorkflowRoleID !== "") {
              SCR.role = row.CFWorkflowRoleID;
              var steps = [];
              if (row.TransitionTo1ID !== "")
                steps.push(row.TransitionTo1ID);
              if (row.TransitionTo2ID !== "")
                steps.push(row.TransitionTo2ID);
              if (row.TransitionTo3ID !== "")
                steps.push(row.TransitionTo3ID);
              if (row.TransitionTo4ID !== "")
                steps.push(row.TransitionTo4ID);
              SCR.steps = steps;
              stepChangeRoles.push(SCR);
            }
            obj.stepChangeRoles = stepChangeRoles;
            if (duplicate === true) {
              //console.log("steps obj: " + JSON.stringify(obj));
              stepChanges[index] = obj;
            }
            else {
              //console.log("steps obj: " + JSON.stringify(obj));
              stepChanges.push(obj);
            }
          }
          ProcDef.stepChanges = stepChanges;
        } catch (error) {
          console.log("Convert Steps to Json Error: " + error.message);
          fail = true;
        }
      }
      if (fail === false) {
        // Load Wallet and Key information for each user - according to Roles
        let Roles = ProcDef.roles;
        console.log("Roles: " + JSON.stringify(Roles));
        for (let ix = 0; ix < Roles.length; ix++) {
          let WRix = this.state.WorkflowRoles.findIndex(p => p.ddName === Roles[ix].role);
          console.log("Role: " + Roles[ix].role + " Index: " + WRix);
          let PCPix = this.state.CFProjectContractParticipants.findIndex(p => p.CFWorkflowRoleID === this.state.WorkflowRoles[WRix].ddID);
          if (PCPix < 0) {
            displayError("Incomplete Participant List - No Assignment for Role: " + this.state.WorkflowRoles[WRix].ddName);
            fail = true;
            break;
          }
          //console.log("Role: " + Roles[ix].role + " Participant Wallet: " + this.state.CFProjectContractParticipants[PCPix].WalletAddress);
          this.state.walletAddresses[ix] = this.state.CFProjectContractParticipants[PCPix].WalletAddress;
          this.state.publicKeys[ix] = this.state.CFProjectContractParticipants[PCPix].PublicKey;
        }
        console.log("Roles: " + JSON.stringify(Roles));
        //console.log("onload walletAddresses: " + JSON.stringify(this.state.walletAddresses));
        //console.log("publicKeys: " + JSON.stringify(this.state.publicKeys));
        if (fail === false)
          this.state.procDefStr = JSON.stringify(ProcDef);
      }
    }
    //console.log("procDefStr: " + this.state.procDefStr);
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  //--------------- State Transition Options for Current Role and State -----------------------------------------------------------------------------
  getStepOptions = async () => {
    if (this.state.contractCurrentRole !== "") {
      const accounts = await getEthAccounts(); // Wallet Addresses  
      this.setCurrentParticipantID(accounts[0]);
      let statesFRole = [];
      statesFRole.push({ "ddID": "NONE", "ddName": "Select..." });
      let roleStateTrns = await CFContract.methods.getStepRoleStepTransitions().call({ from: accounts[0] });
      if (roleStateTrns.length > 0) {
        for (let mx = 0; mx < roleStateTrns.length; mx++)
          statesFRole.push({ "ddID": SessionInfo.web3.utils.toUtf8(roleStateTrns[mx]), "ddName": SessionInfo.web3.utils.toUtf8(roleStateTrns[mx]) });
      }
      //console.log("stateFRole: " + JSON.stringify(statesFRole));
      //displayMessage("Current State: " + this.state.contractCurrentStep + " Current Role: " + this.state.contractCurrentRole);
      this.setState({ stepName: '' });
      this.setState({ statesForRole: statesFRole });
    }
  }
  setCurrentParticipantID = (account) => {
    if (this.state.CFProjectContractParticipants) {
      let index = this.state.CFProjectContractParticipants.findIndex(p => p.WalletAddress === account); //p.CFParticipantID === PKID);
      console.log("setCurrentParticipantID - index: " + index);
      if (index >= 0)
        this.setState({ currentParticipantID: this.state.CFProjectContractParticipants[index].CFParticipantID });
    }
  }
  getCurrentRole = (account) => {
    if (this.state.CFProjectContractParticipants) {
      let index = this.state.CFProjectContractParticipants.findIndex(p => p.WalletAddress === account); //p.CFParticipantID === PKID);
      console.log("getCurrentRole - index: " + index);
      if (index >= 0)
        return this.state.CFProjectContractParticipants[index].CFWorkflowRoleID;
    }
    return 0;
  }
  getWorkflowControlRole = async () => {
    console.log("CFWorkflowID: " + this.state.CFWorkflowID);
    //let CD = await GetTableFilter(543767, '*CFWorkflowID=' + this.state.CFWorkflowID); // Search the workflow records - Note that this will return translated record 
    let CD = await GetTableRow(543767, this.state.CFWorkflowID); // Get Project     
    //console.log("GetRow: " + JSON.stringify(CD));
    //if (CD.x.o === 0) {
    //  displayError("Access to OM Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
    //  return -2;
    //}              
    let wfRec = CD.d.row;
    //console.log("wfRec: " + JSON.stringify(wfRec));
    if (wfRec)
      return wfRec.CFControlRoleID;
    return -1;
  }
  addBlockchainTransaction = async (transactionHash, Desc) => {
    this.setState({ transactionHash });
    const item = { CFProjectContractID: this.state.CFProjectContractID, TxHash: transactionHash, Description: Desc, BlockNumber: this.state.blockNumber, TxDateTime: new Date(), CFParticipantID: this.state.currentParticipantID, TxValue: 0, TxFee: 0, TxStatus: 0 };
    await SaveRow(544028, item, 0, "BlockchainTxID"); // Save BlockchainTransactions
    await this.getProjectContractBlockchainTx();
  }
  //------------------------- Show Transaction ----------------------------------------------------------------------
  txShowClick = async (event) => {
    const index = this.state.BlockchainTransactions.findIndex(dataItem => dataItem === event.dataItem);
    await this.setState({ transactionHash: this.state.BlockchainTransactions[index].TxHash });
    window.open('https://rinkeby.etherscan.io/tx/' + this.state.transactionHash, "_blank") //to open new page
  }
  //------------------------- Select Invite -------------------------------------------------------------------------
  selectionInviteChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }

  rowInviteClick = async (event) => {
    if (!this.state.CFProjectContractID)
      await this.saveRecord();
    console.log("Invite  CFProjectContractID: " + this.state.CFProjectContractID);
    // turn off current and turn on new Selected indicator
    let last = this.lastSelectedIndex;
    const current = this.state.CFProjectParticipants.findIndex(dataItem => dataItem === event.dataItem);
    this.lastSelectedIndex = last = current;
    this.state.CFProjectParticipants.forEach(item => item.selected = false);
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.CFProjectParticipants[i].selected = select;
    }
    let PKID = this.state.CFProjectParticipants[current].CFParticipantID;
    let RoleID = this.state.CFProjectParticipants[current].CFWorkflowRoleID;
    //console.log("Invite  RoleID: " + RoleID);
    const item = { CFProjectID: this.state.CFProjectID, CFProjectContractID: this.state.CFProjectContractID, CFParticipantID: PKID, CFWorkflowRoleID: RoleID };
    let index = this.state.CFProjectContractParticipants.findIndex(p => p.CFParticipantID === PKID); //p.CFParticipantID === PKID);
    //console.log("Select PKID: " + PKID + " index: " + index);
    if (index < 0) {
      console.log("Save ProjectParticipants: ProjectID: " + item.CFProjectID + " Participant: " + item.CFParticipantID);
      await SaveRow(543720, item, 0, "CFPPID"); // Save ProjectParticipants
      await this.getProjectContractParticipants();
    }
    else {
      displayWarning(this.state.CFProjectContractParticipants[index].FirstName + " " + this.state.CFProjectContractParticipants[index].LastName + " - Participant is already in Project");
    }
    //this.getRecord(PKID);
    //this.forceUpdate();
  }

  headerInviteSelectionChange = (event) => {
    //const checked = event.syntheticEvent.target.checked;
    //this.state.CFParticipants.forEach(item => item.selected = checked);
    //this.forceUpdate();
  }
  onDisplayContract = async () => {
    window.open('https://rinkeby.etherscan.io/address/' + this.state.ProjectInstanceContractAddress, "_blank") //to open new page
  }
  onDisplayAccount = async () => {
    window.open('https://rinkeby.etherscan.io/address/' + this.state.MetaMaskAccount, "_blank") //to open new page
  }
  onDisplayTx = async () => {
    if (this.state.transactionHash === '')
      displayMessage("No Transaction Specified");
    else
      window.open('https://rinkeby.etherscan.io/tx/' + this.state.transactionHash, "_blank") //to open new page
  }
  sortChange = (event) => {
    this.setState({ products: this.GetProducts(event.sort), sort: event.sort});
  }
  GetProductParticipants = (sort) => {
    return orderBy(this.state.CFProjectParticipants, sort);
  }

  //------------------------- Select Invite----------------------------------------------------------------------
  render() {
    if (!SessionInfo.session)
      return (<Redirect to='/' />);
    return (
      <div id="ProjectContract" className="pageMain">
        <PageHeader L1='Home' Select='3' Title='Project Contract' IsApp='y' />
        <div id="mainCntr">
          <Splitter style={{ height: '100%' }} panes={this.state.nestedPanes} orientation={'vertical'} onLayoutChange={this.onNestedLayoutChange}>
            <Splitter panes={this.state.panes} onLayoutChange={this.onLayoutChange}>
              <div id="splitterLeft" className="pane-content">
                <div id="div1" className="editInside">
                  <MuiThemeProvider theme={theme}>
                    <form >
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="ProjectContractCode">Project Contract Code</InputLabel>
                        <Input id="ProjectContractCode" name="ProjectContractCode" value={this.state.ProjectContractCode} readOnly />
                      </FormControl>
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="ProjectContractName">Project Contract Name</InputLabel>
                        <Input id="ProjectContractName" name="ProjectContractName" value={this.state.ProjectContractName} autoComplete="ProjectContractName" autoFocus onChange={evt => this.chgFldVal(evt)} />
                      </FormControl>
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="Description">Contract Description</InputLabel>
                        <Input id="Description" name="Description" autoComplete="Description" value={this.state.Description} onChange={evt => this.chgFldVal(evt)} />
                      </FormControl>
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="Description">Project Name</InputLabel>
                        <Input id="ProjectName" name="ProjectName" autoComplete="ProjectName" value={this.state.ProjectName} readOnly />
                      </FormControl>
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="Description">Project URI</InputLabel>
                        <Input id="ProjectURI" name="ProjectURI" autoComplete="ProjectURI" value={this.state.ProjectURI} />
                      </FormControl>
                    </form>
                  </MuiThemeProvider >
                </div>
              </div>
              <div id="splitterRight" className="pane-content">
                <TabStrip selected={this.state.selectedTab} name="ProjectContractTab" onSelect={this.handleSelect} animation={true}>
                  <TabStripTab title="Contract Info">
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <div id="div2" className="editField">
                            <div className="editField">
                              <span className="editFieldLabel">Role Count</span>
                              <input value={this.state.roleCount} name="roleCount" readOnly={true} className="editNumShortInput" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <div className="editField">
                              <span className="editFieldLabel">State Count</span>
                              <input value={this.state.stepCount} name="stepCount" readOnly={true} className="editNumShortInput" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Current Role</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.WorkflowRoles} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowRoles.find(c => c.ddID === this.state.CurrentRoleID)} name="CurrentRoleID" readOnly={true} className="editInputDD" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Current Step</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.WorkflowSteps} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowSteps.find(c => c.ddID === this.state.CurrentStepID)} name="CurrentStepID" readOnly={true} width="250px" className="editInputDD" />
                            </div>
                          </div>
                          <div className="editField">
                            <Button icon="refresh" color="primary" onClick={this.onCreateContract}>{this.state.createButtonText}</Button>
                          </div>
                          <br />
                          {this.state.isContractLoaded &&
                            <div>
                              <div className="editField">
                                <span className="editFieldLabel">Active Role(s)</span>
                                <input value={this.state.activeRoles} name="activeRoles" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                              </div>
                              {this.state.isParticipant &&
                                <div>
                                  <div id="div2" className="editField">
                                    <span className="editFieldLabel">Next Step</span>
                                    <div className="editDDPos">
                                      <DropDownList data={this.state.statesForRole} textField="ddName" dataItemKey="ddID" value={this.state.statesForRole.find(c => c.ddID === this.state.NextStepName)} name="NextStepName" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                                    </div>
                                  </div>
                                  <div className="editField">
                                    <Button icon="refresh" color="primary" onClick={this.goToWorkflowStep}>Go To Next Step</Button>
                                  </div>
                                </div>
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Contract Status</span>
                            <div className="editDDPos">
                              <DropDownList data={this.state.CFProjectStatusSet} textField="ddName" dataItemKey="ddID" value={this.state.CFProjectStatusSet.find(c => c.ddID === this.state.CFProjectStatusID)} name="CFProjectStatusID" readOnly="true" className="editInputDD" />
                            </div>
                          </div>
                          {lockedScreen ?
                            <img src={require("./images/loading.gif")} alt="" style={this.state.spinnerStyle} />
                            :
                            !this.state.isContractLoaded && this.state.autoLoadContract &&
                            <img src={require("./images/loading.gif")} alt="" style={this.state.spinnerStyle} />
                          }
                          {!lockedScreen && this.state.isContractLoaded &&
                            <div>
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Last Changed</span>
                                <div className="editDDPos">
                                  <DateTimePicker spinners={true} value={this.state.LastChangeDateTime} name="LastChangeDateTime" readOnly={true} className="editDateInput" />
                                </div>
                              </div>
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Contract Date</span>
                                <div className="editDDPos">
                                  <DateTimePicker spinners={true} value={this.state.ContractDate} name="ContractDate" readOnly={true} className="editDateInput" />
                                </div>
                              </div>
                              <div id="div2" className="editField">
                                <span className="editFieldLabel">Contract Version</span>
                                <input value={this.state.ContractVersion} name="ContractVersion" readOnly={true} className="editShortInput" />
                              </div>
                              <br />
                              {this.state.isParticipant &&
                                <div>
                                  <div id="div2" className="editField">
                                    <div className="editField">
                                      <span className="editFieldLabel">Owner Balance</span>
                                      <input value={(this.state.ownerBalance / 1000000000).toLocaleString()} name="ownerBalance" readOnly={true} className="editNumInput" />
                                    </div>
                                  </div>
                                  <div id="div2" className="editField">
                                    <div className="editField">
                                      <span className="editFieldLabel">Total Gas Used</span>
                                      <input value={this.state.totalAllGasUsed} name="totalAllGasUsed" readOnly={true} className="editNumInput" />
                                    </div>
                                  </div>
                                  <div className="editField">
                                    <span className="editFieldLabel">Total Refund Gas</span>
                                    <input value={this.state.totalRoleGasUsed} name="totalRoleGasUsed" readOnly={true} className="editNumInput" />
                                  </div>
                                </div>
                              }
                            </div>
                          }  
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Description">
                    <div className="editTab">
                      <div id="div1" className="editInside">
                        <h4>Description and Comments</h4>
                        <div className="editField">
                          <span className="editFieldLabel">Case Description</span>
                          <textarea value={this.state.Description} name="Description" onChange={evt => this.chgFldVal(evt)} className="editTAInput" />
                        </div>
                        <br />
                        <br />
                        <div className="editField">
                          <span className="editFieldLabel">Project Summary</span>
                          <textarea value={this.state.ProjectSummary} name="ProjectSummary" onChange={evt => this.chgFldVal(evt)} className="editTAInput" />
                        </div>
                        <br />
                        <br />
                        <div className="editField">
                          <span className="editFieldLabel">Comments</span>
                          <textarea value={this.state.Comments} name="Comments" onChange={evt => this.chgFldVal(evt)} className="editTAInput" />
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Documents">
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">IPFS Document</span>
                          <input value={this.state.IPFSDocumentAddress} name="IPFSDocumentAddress" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                        </div>
                        <br />
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Document Type</span>
                          <div className="editDDPos">
                            <DropDownList data={this.state.DocumentTypes} textField="ddName" dataItemKey="ddID" value={this.state.DocumentTypes.find(c => c.ddID === this.state.DocumentTypeID)} name="DocumentTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                          </div>
                        </div>
                        <br />
                        {this.state.isContractLoaded && this.state.isCurrOwner &&
                          <div>
                            <div className="editField">
                              <input type="file" style={{ marginLeft: '20px' }} onChange={this.onSelectDocument} />
                            </div>
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.saveToIPFS}>Save File to IPFS</Button>
                            </div>
                          </div>
                        }
                        {this.state.isContractLoaded && this.state.IPFSDocumentAddress &&
                          <div>
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.loadFile}>Load File from IPFS</Button>
                            </div>
                            {/*<div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.displayFile}>Display File</Button>
                            </div>*/}
                          </div>
                        }
                      </div>
                      <div className="editTabRight">
                        {this.state.isParticipant &&
                          <div id="div1" className="editInside">
                            <h4>Document Encryption Info</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Document Key</span>
                              <input value={this.state.DocumentKey} name="DocumentKey" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Nonce Value</span>
                              <input value={this.state.DocumentNonce} name="DocumentNonce" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Salt Value</span>
                              <input value={this.state.DocumentSalt} name="DocumentSalt" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <br />
                          </div>
                        }
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Ethereum Contract">
                    <div className="editTab">
                      <div className="editTabLeftWide">
                        <h4>Ethereum Address Information</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Contract Address</span>
                          <input value={this.state.ProjectInstanceContractAddress} name="ProjectInstanceContractAddress" readOnly={true} className="editInputWide" />
                        </div>
                        <div className="editField">
                          <Button icon="refresh" color="primary" onClick={this.onDisplayContract}>Display Contract</Button>
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Current Account</span>
                          <input value={this.state.MetaMaskAccount} name="MetaMaskAccount" readOnly={true} className="editInput" />
                        </div>
                        <div className="editField">
                          <Button icon="refresh" color="primary" onClick={this.onDisplayAccount}>Display Account</Button>
                        </div>
                        <br />
                        {this.state.isParticipant &&
                          <div>
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.cancelContract}>Cancel Contract</Button>
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Cancel Status</span>
                              <input value={this.state.cancelComplete} name="cancelComplete" readOnly={true} className="editInput" />
                            </div>
                          </div>
                        }
                      </div>
                      <div className="editTabRightNarrow">
                        <div id="div1" className="editInside">
                          <div className="editField">
                            <span className="editFieldLabel">Gas Price</span>
                            <input value={this.state.currentGasPrice} name="currentGasPrice" readOnly={true} className="editNrwNumShortInput" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Set Price</span>
                            <input value={this.state.GasPrice} name="GasPrice" onChange={evt => this.chgFldVal(evt)} className="editNrwNumShortInput" />
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Initial Gwei</span>
                            <input value={this.state.InitialContribution} name="InitialContribution" onChange={evt => this.chgFldVal(evt)} className="editNrwNumShortInput" />
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Start Date</span>
                            <div className="editNrwDDPos">
                              <DateInput spinners={true} value={this.state.StartDateTime} name="StartDateTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" />
                            </div>
                          </div>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">End Date</span>
                            <div className="editNrwDDPos">
                              <DateInput spinners={true} value={this.state.EndDateTime} name="EndDateTime" onChange={evt => this.chgFldVal(evt)} className="editDateInput" />
                            </div>
                          </div>
                          <div className="editField">
                            <span className="editFieldLabel">Workflow</span>
                            <div className="editNrwDDPos">
                              <DropDownList data={this.state.Workflows} textField="ddName" dataItemKey="ddID" value={this.state.Workflows.find(c => c.ddID === this.state.CFWorkflowID)} name="CFWorkflowID" readOnly={true} className="editInputDD" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Workflow">
                    <div className="editTab">
                      <div id="div4" className="editInside">
                        <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFWorkflowSteps} selectedField="selected" readOnly={true} rowHeight={30}>
                          <Column field="selected" width="0px" headerSelectionValue={this.state.CFWorkflowSteps.findIndex(dataItem => dataItem.selected === false) === -1} />
                          <Column field="CFWorkflowStepID" title="" filter="numeric" width='1px' />
                          <Column field="StepName" title="Name" width='110px' />
                          <Column field="StepDescription" title="Description" width='250px' />
                          <Column field="CFWorkflowRoleID" title="Role" />
                          <Column field="TransitionTo1ID" title="Next Step" />
                          <Column field="TransitionTo2ID" title="Next Step" />
                          <Column field="TransitionTo3ID" title="Next Step" />
                          <Column field="TransitionTo4ID" title="Next Step" />
                        </TGrid>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Transactions">
                    <div className="editTab">
                      <div id="div4" className="editInside">
                        <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.BlockchainTransactions} sortable={true} onRowClick={this.txShowClick} resizable={true} rowHeight={30}>
                          <Column field="BlockchainTxID" title="" filter="numeric" width='1px' />
                          <Column field="TxHash" title="Tx Hash" width='210px' />
                          <Column field="Description" title="Description" width='350px' />
                          <Column field="TxDateTime" title="Tx Date Time" width='200px' />
                          <Column field="CFParticipantID" title="From Participant" />
                        </TGrid>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Invite">
                    {this.state.CFProjectStatusID < 3 &&
                      <div className="editTab">
                        <div id="div4" className="editInside">
                        <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFProjectParticipants} selectedField="selected" sortable={{allowUnsort: true, mode: 'multiple'}}
                          onSelectionChange={this.selectionInviteChange}
                          onHeaderSelectionChange={this.headerInviteSelectionChange}
                          onRowClick={this.rowInviteClick} resizable={true} sort={this.state.sortParticipants} onSortChange={(event) => { this.setState({ CFProjectParticipants: this.GetProductParticipants(event.sort), sortParticipants: event.sort });}}>
                            <Column field="selected" width="0px" headerSelectionValue={this.state.CFProjectParticipants.findIndex(dataItem => dataItem.selected === false) === -1} />
                            <Column field="CFPPID" title="Participant Id" filter="numeric" width='1px' />
                            <Column field="FirstName" title="First Name" />
                            <Column field="LastName" title="Last Name" />
                            <Column field="EmailAddress" title="Email Address" />
                            <Column field="WalletAddress" title="Wallet Address" />
                          </TGrid>
                        </div>
                      </div>
                    }
                  </TabStripTab>
                  <TabStripTab title="Status">
                    <div className="editTab">
                      <div id="divC" className="editInside">
                        <Chart id="chartSingle" className="chartSingle">
                          <ChartSeries>
                            <ChartSeriesItem type="donut" data={this.state.WorkflowStepChart} categoryField="StepName" field="value">
                              <ChartSeriesLabels color="#fff" background="none" content={labelContent} />
                            </ChartSeriesItem>
                          </ChartSeries>
                          <ChartLegend visible={true} />
                        </Chart>
                      </div>
                    </div>
                  </TabStripTab>
                </TabStrip>
              </div>
            </Splitter>
            <div id="splitterBottom" className="pane-content">
              <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFProjectContractParticipants} onItemChange={this.itemChangeRole} editField="inEdit"
                onSelectionChange={this.selectionChange}
                onHeaderSelectionChange={this.headerSelectionChange}
                onRowClick={this.rowClick} resizable={true}>
                <Column field="CFParticipantID" title="Participant Id" filter="numeric" width='1px' />
                <Column field="FirstName" title="First Name" />
                <Column field="LastName" title="Last Name" />
                <Column field="EmailAddress" title="Email Address" />
                <Column field="WalletAddress" title="Wallet Address" />
                <Column field="CFWorkflowRoleID" title="Role" cell={RoleDropDownCell} />
                <Column cell={this.CommandCell} width="260px" />
              </TGrid>
            </div>
          </Splitter>
        </div>
        <PageFooter L1='Home' />
      </div>
    );
  }
}

export default ProjectContract;