//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Settings - Participant Settings
//              Version 1.007 - June 12, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { } from 'react';
import { Redirect } from 'react-router-dom'    
import './App.css';             
import { SessionInfo } from './App';
import { GetTableRow, SaveRow, displayMessage } from './CommonCode.js';
import { getEthAccounts } from './SolidityInterface.js';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';

class SettinsPage extends React.Component {
  constructor(props) {
    super(props);
    if (!SessionInfo.session)
      return;
    console.log("settings history: " + JSON.stringify(props.history));
    if (SessionInfo.CFParticipantID > 0) {
      console.log("Get Participant Desc for: " + SessionInfo.CFParticipantID);
      this.getRecord(SessionInfo.CFParticipantID);
      displayMessage("GREEN"); 
      //SessionInfo.SelectedParticipantID = 0;
    }
    else {
      if (SessionInfo.SettingsPage) { // Data saved for this session
        //console.log("contractAccess: " + JSON.stringify(SessionInfo.contractAccess));
        this.state = SessionInfo.SettingsPage;
      }
    }
    SessionInfo.currSaveFunc = this.saveRecord;
    SessionInfo.currDeleteFunc = this.deleteDefault;
    SessionInfo.currClearFunc = this.clearDefault;
    SessionInfo.searchFunc = this.searchDefault;
  }
  state = {
    CFParticipantID: 0,
    CurrencyID: 0,
    EmailAllowed: true,
    string: '',
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    Address: '',
    CompanyName: '',
    PhoneNumber: '',
    BusinessPhone: '',
    LinkedIn: '',
    WalletAddress: '',
    SwiftNumber: '',
    PublicKey: '',
    PrivateKey: '',

    selectedTab: 0,
    stateHasChanged: false,

    searchValue: '',
    MessagesValue: '',
    currentTime: '',
    isWalletUser: false,
    CFParticipants: [],
    saveCFParticipants: [],
    Currencies: [],                            
  };
  refreshCount = 6;
  componentDidMount() {
    if (!SessionInfo.session)
      return;
    console.log("settings history 2: " + JSON.stringify(this.props.history));
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.SettingsPage = this.state;
  }               
  getRecord = async (Key) => {
    let CD = await GetTableRow(543619, Key); // Save to OM   
    this.setState({ CFParticipantID: Key });
    for (var prop in CD.d.row) {
      if (prop in this.state) {
        //console.log("Set " + prop + ": " + CD.d.row[prop]); 
        let obj = {};
        obj[prop] = CD.d.row[prop];
        if (prop.indexOf("ID") > 0 && prop !== "CFParticipantID" && prop !== "CurrencyID") {
        }
        this.setState(obj);
      }
    }
    SessionInfo.Name = this.state.FirstName + " " + this.state.LastName;
    SessionInfo.EmailAddress = this.state.EmailAddress;
    const accounts = await getEthAccounts();
    if (accounts[0] === this.state.WalletAddress)
      this.setState({ isWalletUser: true });
    else
      this.setState({ isWalletUser: false });
  }
  saveRecord = async () => {
    //console.log("Save ParticipantProfile");
    let copyState = Object.assign({}, this.state);
    let type = 0;
    for (var prop in copyState) {
      //console.log("Prop: " + prop + " type: " + type);
      if (prop === "string") {
        type = 1;
        copyState[prop] = undefined;
      }
      else if (prop === "selectedTab")
        type = 2;
      else if (type === 0) {
      }
      if (type === 2) {
        copyState[prop] = undefined;
      }
    }
    console.log("copystate: " + JSON.stringify(copyState));
    await SaveRow(543619, copyState, copyState.CFParticipantID, "CFParticipantID"); // Save to OM 
    await this.getRecords();
    this.setState({ stateHasChanged: false });
  }
  deleteRecord = async () => {
    displayMessage("Cannot Delete Profile");
  }
  clearDefault = () => {
    displayMessage("Cannot Clear Profile");
  }
  searchDefault = () => {
    displayMessage("No Data to Search");
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }

  render() {
    if (!SessionInfo.session)
      return (<Redirect to='/' />);
    return (
      <div id="appMain" className="pageMain">
        <PageHeader L1='Home' Title='Personalize' IsApp='y'/>
        <div id="mainCntr">
          <div className="headingBox">
            <div className="heading">
              <h2>Participant Settings</h2>
            </div>
          </div>
          <div id="bottomCntr" style={{ height: '900px' }}>
            <div className="leftC">
              <div className="getBox">
                <img src={require("./images/sub/get_icon.png")} alt="" />
                <h2>Get Started</h2>
                <p>Add the information you want to maintain in your personal profile. The different projects will have different requirements for the information you need to provide to participate to the projects.<br />
                  You determine what gets saved in the Contract Flow system. And you decide what others can see of your information.</p>
              </div>
              <div className="ourblogBox">
                <div className="top">
                  <div className="bottom">
                    <h2>Security is Job One</h2>
                    <p><span>You will need a key pair</span>
                      To save sensitive business documents in a public forum - so that they are accessible and immutable - means that the documents need to be encrypted.<br/>To encrypt the documents - and to ensure that the information does not change - the key information must be kept on the Blockchain.<br />To keep the key information private, it must be encoded with your Public Key. Then, only you can decrypt it with your private key.
							        <a href="#">&nbsp;&nbsp;More Info...</a></p>
                    <p className="last"><span>This is your Quick Reference</span>
                      To view more complete information and settings, go to the Profile Page. Your Profile Page gives you ALL of your information - and it shows you the Projects and Contracts you are assigned to.
							  <a href="#">&nbsp;&nbsp;Profile Page...</a></p>
                  </div>
                </div>
              </div>
            </div>

            <div id="rightCntr">
              <div className="textBox">
                <img src={require("./images/sub/participant.jpg")} alt="" className="headImage" /> <h4><span>Participant Information</span></h4>
                <p className="first">This is your Participant Profile, your key information and your preferences.<br /><em>Some of this information is sensitive and valuable - only you can see it.</em><br />You can choose which information will be stored with your profile and which information you would prefer to manage yourself</p>
              </div>
              <div className="contactBox">
                <div id="div2" className="editField">
                  <label>First Name*<span></span></label>
                  <input name="FirstName" type="text" className="field1" readOnly value={this.state.FirstName} />
                </div>
                <div id="div2" className="editField">
                  <label>Last Name* <span></span></label>
                  <input name="LastName" type="text" className="field1" readOnly value={this.state.LastName} />
                </div>
                <div id="div2" className="editField">
                  <label>Email Address* <span>User Name</span></label>
                  <input name="EmailAddress" type="text" className="field1" readOnly value={this.state.EmailAddress} />
                </div>
                <div id="div2" className="editField">
                  <label>Address <span>Physical address</span></label>
                  <input name="Address" type="text" className="field1" value={this.state.Address} onChange={evt => this.chgFldVal(evt)} />
                </div>
                <div id="div2" className="editField">
                  <label>Company Name <span>Who do you represent</span></label>
                  <input name="CompanyName" type="text" className="field1" value={this.state.CompanyName} onChange={evt => this.chgFldVal(evt)} />
                </div>
                <div id="div2" className="editField">
                  <label>Phone Number<span></span></label>
                  <input name="PhoneNumber" type="text" className="field1" value={this.state.PhoneNumber} onChange={evt => this.chgFldVal(evt)} />
                </div>
                <div id="div2" className="editField">
                  <label>Business Number<span></span></label>
                  <input name="BusinessPhone" type="text" className="field1" value={this.state.BusinessPhone} onChange={evt => this.chgFldVal(evt)} />
                </div>
                <div id="div2" className="editField">
                  <label>LinkedIn<span></span></label>
                  <input name="LinkedIn" type="text" className="field1" value={this.state.LinkedIn} onChange={evt => this.chgFldVal(evt)} />
                </div>
                <div id="div2" className="editField">
                  <label>WalletAddress<span></span></label>
                  <input name="WalletAddress" type="text" className="field1" value={this.state.WalletAddress} readOnly />
                </div>
                <div id="div2" className="editField">
                  <label>PublicKey<span></span></label>
                  <input name="PublicKey" type="text" className="field1" value={this.state.PublicKey} onChange={evt => this.chgFldVal(evt)} />
                </div>
                <div id="div2" className="editField">
                  <label>PrivateKey<span></span></label>
                  <input name="PrivateKey" type="text" className="field1" value={this.state.PrivateKey} onChange={evt => this.chgFldVal(evt)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageFooter L1='Home' />
      </div >
    );
  }
}

export default SettinsPage