import React, { } from 'react'; 
import './style.css';     
import './App.css';
import { Button } from 'react-bootstrap'; 
import { SessionInfo } from './App';
import { SaveRow, KeyLogOn, notify } from './CommonCode.js';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';

class ContactUs extends React.Component {
  //constructor(props) {
  //  super(props);
  //}
  state = {
    QuestionID: 0,
    OMUserID: 0, 
    string: '',
    Name: '',   
    EmailAddress: '',
    QuestionTypeID: '',
    MessageDetail: '',
    IPAddress: '', 

    selectedTab: 0, 
  };
  currentType = '';
  refreshCount = 6;
  componentDidMount() {
    this.setState({ Name: SessionInfo.Name });
    this.setState({ EmailAddress: SessionInfo.EmailAddress });
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    //let value = this.context; 
  }
  submitMessage = async () => { // Get Transaction Receipt
    try {     
      let isTemp = true;              
      //console.log("after logon - session: " + SessionInfo.session);
      if (!this.state.EmailAddress || this.state.EmailAddress === '') {
        notify("Email Address is Required");
        return;
      }
      var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (this.state.EmailAddress.search(emailRegEx) === -1) {
        notify("Please enter a valid email address");
        return;
      }
      if (!this.state.MessageDetail || this.state.MessageDetail === '') {
        notify("A Message is Required");
        return;
      }
      console.log("submit Message - session: " + SessionInfo.session);
      if (SessionInfo.session)
        isTemp = false; // Session Pre-Exists
      else {
        SessionInfo.tempLogon = true;
        await KeyLogOn();
        isTemp = true;
      }    
      let copyState = Object.assign({}, this.state);
      let type = 0;
      for (var prop in copyState) {
        //console.log("Prop: " + prop + " type: " + type + " val: " + copyState[prop]);
        if (prop === "string") {
          type = 1;
          copyState[prop] = undefined;
        }
        else if (prop === "selectedTab")
          type = 2;                   
        else if (type === 0) {
        }
        else if (type === 1) {
          if (prop === "QuestionTypeID") {
            let cType = copyState[prop];
            let iType = 0;
            this.currentType = cType;
            switch (cType) {
              case "Add To Project":
                this.currentType = "Request to be added to a Project";
                iType = 1;
                break;
              case "Bug":
                this.currentType = "Bug Report";
                iType = 2;
                break;
              case "Enhancement":
                this.currentType = "Enhancement Request";
                iType = 3;
                break;
              case "Help":
                this.currentType = "Help Information";
                iType = 4;
                break;
              case "Issue":             
                iType = 5;
                break;
              case "Other":
                this.currentType = "Other? Input";
                iType = 6;
                break;
              case "Permission Add":
                this.currentType = "Request to add a Permission";
                iType = 7;
                break;
              case "Question":
                iType = 8;
                break;
              case "Request":              
                iType = 9;
                break;
              case "Request Ether":
                this.currentType = "Request for Ether";
                iType = 10;
                break;
              default:
                iType = 0;                
                break;
            }
            if (iType === 0) {
              notify("A Contact Type must be selected ");
              return;
            }                       
            copyState[prop] = iType;
          }
        }
        if (type === 2) {
          copyState[prop] = undefined;
        }
      }
      copyState.OMUserID = SessionInfo.OMUserID;  
      //console.log("copystate: " + JSON.stringify(copyState));
      await SaveRow(544162, copyState, copyState.QuestionID, "QuestionID"); // Save to OM  
      if (isTemp)
        SessionInfo.session = '';
      SessionInfo.tempLogon = false;
      notify("Thank you for your " + this.currentType);
      type = 0;
      for (var prop in this.state) {
        if (prop === "string")
          type = 1;
        else if (prop === "selectedTab")
          type = 2;
        else if (type === 0)
          this.state[prop] = 0;
        else if (type === 1)
          this.state[prop] = '';
      }
      this.forceUpdate();
    } //try
    catch (error) {
      console.log(error);
    } //catch
  } //submitMessage 
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  updateType(evt) {
    //console.log("Type: " + evt.target.value);
    this.setState({ QuestionTypeID: evt.target.value });
  }
  render() {
    return (                            
      <div id="appMain" className="pageMain">
        <PageHeader L1='Home' Title='Contact Us' />
        <div id="mainCntr">
          <div className="headingBox">
            <div className="heading">
              <h2>Contact Us</h2>
            </div>
          </div>
          <div id="bottomCntr" style={{ height: '685px' }} >
            <div className="leftC">
              <div className="getBox">
                <img src={require("./images/sub/get_icon.png")} alt="" />
                <h2>Get Started</h2>
                <p>We would like to hear from you.<br />Whether it is a simple question, something that you need us to do for you, or if you are reporting a problem - this is the place to do it.</p>
              </div>
              <div className="ourblogBox">
                <div className="top">
                  <div className="bottom">
                    <h2>Ask Your Questions</h2>
                    <p><span>Would you like to know more?</span>
                      Ask any question that you have about Contract Flow. We'll get back to you using your email address.
							      <a href="/AboutPage">&nbsp;&nbsp;Contract Flow...</a></p>
                      <p className="last"><span>Have a Suggestion or Bug?</span>
                        Describe the problem - or the feature that you would like added to Contract Flow and send it to us. We'll evaluate it and get back to you.
                      <a href="#">&nbsp;&nbsp;The List...</a></p>
                    <p className="last"><span>Do you have a Request?</span>
                      Whether it is getting added to a particular Project, or applying for permission to access restricted Contract Flow capability - we'll evaluate your request and get back to you.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id="rightCntr">
              <div className="textBox">
                <img src={require("./images/sub/contact.png")} alt="" className="headImage" /> <h4><span>Contact Us</span></h4>
                <p className="first">Your Name, your Email Address, the Type of Communication and a Clear Description &nbsp;&nbsp;<em>It's really quite simple!</em></p>
                <p>And - we're always looking for Ideas - Big and Small - Use your Imagination!</p>
              </div>
              <div className="contactBox">
                <div id="div2" className="editField">
                  <label>Your Name*<span>Add your name </span></label>
                  <input name="Name" type="text" className="field1" value={this.state.Name} onChange={evt => this.chgFldVal(evt)} />
                </div>
                <div id="div2" className="editField">
                  <label>Your Email Address* <span>Add a valid address</span></label>
                  <input name="EmailAddress" type="text" className="field1" value={this.state.EmailAddress} onChange={evt => this.chgFldVal(evt)}/>
                </div>
                <div id="div2" className="editField">
                  <label>Contact Type*<span>Type of Request</span></label>
                  <select name="QuestionTypeID" className="field1" value={this.state.QuestionTypeID} onChange={evt => this.updateType(evt)}>
                    <option>Select...</option>
                    <option>Add To Project</option>    
                    <option>Bug</option>   
                    <option>Enhancement</option>  
                    <option>Help</option>
                    <option>Issue</option>        
                    <option>Other</option>    
                    <option>Permission Add</option>
                    <option>Question</option>
                    <option>Request</option>
                    <option>Request Ether</option> 
                  </select>
                </div>                              
                <label>Your Message*<span>Communicate with us</span></label>
                <textarea name="MessageDetail" cols="" rows="30" value={this.state.MessageDetail} onChange={evt => this.chgFldVal(evt)}></textarea>   
                {/*<input type="button" className="button" value="Send Information" />*/} 
                <Button onClick={this.submitMessage}>&nbsp;&nbsp;Send Information&nbsp;&nbsp;</Button>
              </div>
            </div>
          </div>
        </div>
        <PageFooter L1='Home' />
      </div >
    );
  }
}

export default ContactUs