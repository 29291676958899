import React from 'react';
import ReactDOM from 'react-dom';   
import App from './App';   
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
                      

ReactDOM.render((
  <div> 
    {/*<RedirectStart />  
    <meta http-equiv="refresh" content="10; URL=/" />*/} 
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </div>
), document.getElementById('root'));
// If you want your app to work offline and load faster, you can change unregister() to register() below. Note this comes with some pitfalls. - check out: http://bit.ly/CRA-PWA
serviceWorker.unregister(); // Without this, changes may not update 
