// //overrides metamask v0.2 for our 1.0 version.  
//1.0 lets us use async and await instead of promises
import Web3 from 'web3';
//overrides metamask v0.2 for version 1.0                                           
var web3 = undefined;
try {
  web3 = new Web3(window.web3.currentProvider);
}
catch (error) {
  console.log("Unable to access MetaMask in web3 - It must be installed - error: " + error);
}

export default web3;