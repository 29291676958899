import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default function EditGridCommandCell(enterEdit, remove, save, cancel, editField, nameField, idField) {
  return class extends GridCell {
    render() {                                
      return !this.props.dataItem[editField]
        ? (
          <td>
            <button className="k-primary k-button k-grid-edit-command" style={{ 'backgroundColor': '#0059b3', height: '30px', borderRadius: '5px' }} onClick={(e) => enterEdit(this.props.dataItem)}>Edit</button>
            <button className="k-button k-grid-remove-command" onClick={(e) => window.confirm('Confirm Delete: ' + this.props.dataItem[nameField]) && remove(this.props.dataItem)}>Remove</button>
          </td>
        )
        : (
          <td>
            <button className="k-button k-grid-save-command" style={{ 'backgroundColor': '#0059b3', height: '30px', borderRadius: '5px' }} onClick={(e) => save(this.props.dataItem)}>
              {this.props.dataItem.AccountID ? 'Update' : 'Add'}
            </button>
            <button
              className="k-button k-grid-cancel-command" style={{ 'backgroundColor': '#00ff37', height: '30px', borderRadius: '5px' }}
              onClick={(e) => cancel(this.props.dataItem)}>{this.props.dataItem[idField] ? 'Cancel' : 'Discard'}
            </button>
          </td>
        );
    }
  }
};
