//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// PageHeader - General Application Support Header
//              Version 1.007 - February 4, 2020
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';                        
import './App.css';
import './custom.scss';                                
//import '@progress/kendo-theme-default/dist/all.css'; 
import { Popup } from '@progress/kendo-react-popup';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {  Button } from 'react-bootstrap'; 
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { SessionInfo } from './App';
import { displayMessage, LogOff } from './CommonCode.js';  

class PageHeader extends React.Component {
  constructor(props) {               
    super(props);
    if (this.props.IsApp)
      SessionInfo.IsApp = true;
    else
      SessionInfo.IsApp = false; 
    this.searchButton = this.searchButton.bind(this);
    this.state.currentTime = this.getTime();
    this.handleLogoff = this.handleLogoff.bind(this); 
    if (props.Select !== undefined)
      this.state.colors[props.Select] = '#16f053';
  }
  state = {
    searchValue: '',
    MessagesValue: '', 
    notifyMessage: '',
    currentTime: '',
    logonName: '',
    appDisplay: false,
    msgDisplay: false,
    showFlag1: false,
    showFlag2: false,
    showFlag3: false,
    colors: ['', '', '', '', '', '', ''],
    showPopup: false,
    dialogVisible: false,
    showNotify: false,
  };
  anchor = null;
  offset = { left: 150, top: 90 };
  refreshCount = 6;
  flagTime = 0;    
  popupTimer: 0;
  notifyTimer: 0;
  componentDidMount() {
    this.updateClock();
    if (this.props.IsApp)
      this.setState({ appDisplay: true });
    if (this.props.IsLogon)
      this.setState({ msgDisplay: true });
    console.log("Header Session: " + SessionInfo.session);
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {     
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  searchInput() {
    console.log("Search Input: " + this.state.searchValue);
    //addMessage(searchStr);
  }
  searchButton = async () => {
    displayMessage("Search: " + this.state.searchValue);
    if (SessionInfo.searchFunc !== undefined)
      await SessionInfo.searchFunc(this.state.searchValue);
  }
  updateSearchValue(evt) {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.searchValue = evt.target.value;
    this.setState(stateCopy)
  }
  getTime() { // Get CLOCK time
    const curTime = new Date();
    const currentTime =
    {
      hours: curTime.getHours(),
      minutes: curTime.getMinutes(),
      //seconds: curTime.getSeconds(),
      //ampm: curTime.getHours() >= 12 ? "pm" : "am"
    };
    return currentTime;
  }
  setTimer() {
    this.timeout = setTimeout(this.updateClock.bind(this), 200); // 200 microseconds  
    //this.timeout = setInterval(this.updateClock.bind(this), 200); // 200 microseconds
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  updateClock() {
    this.setTimer();
    if (this.refreshCount++ > 4) { // Once a second
      this.setState({ currentTime: this.getTime() });
      this.refreshCount = 0;
      if (this.state.logonName !== SessionInfo.logonName)
        this.setState({ logonName: SessionInfo.logonName });
      if (SessionInfo.registerCounter > 0)
        SessionInfo.registerCounter--;
    }
    if (SessionInfo.message !== this.state.MessagesValue)
      this.setState({ MessagesValue: SessionInfo.message });
    //console.log("flag: " + SessionInfo.flagLevel);
    if (SessionInfo.flagLevel === 1) {
      this.setState({ showFlag1: true }); // Green
      this.setState({ showFlag2: false });
      this.setState({ showFlag3: false });
      this.flagTime = 25;
      SessionInfo.flagLevel = 0;
    }
    else if (SessionInfo.flagLevel === 2) {
      this.setState({ showFlag1: false });
      this.setState({ showFlag2: true }); // Yellow
      this.setState({ showFlag3: false });
      this.flagTime = 30;
      SessionInfo.flagLevel = 0;
    }
    else if (SessionInfo.flagLevel === 3) {
      this.setState({ showFlag1: false });
      this.setState({ showFlag2: false });
      this.setState({ showFlag3: true }); // Red
      this.flagTime = 35;
      SessionInfo.flagLevel = 0;
    }
    else if (this.flagTime <= 0) {
      this.setState({ showFlag1: false });
      this.setState({ showFlag2: false });
      this.setState({ showFlag3: false });
    }
    else {
      this.flagTime--;
    }
    if (SessionInfo.notifyMessage !== "") {
      this.setState({ notifyMessage: "<br/><span style='color:#ed1cd1'>&nbsp;&nbsp;" + SessionInfo.notifyMessage + "&nbsp;&nbsp;</span><br/><br/>" });
      this.notifyTimer = 35;
      this.setState({ showNotify: true });
      SessionInfo.notifyMessage = '';
    }
    if (this.popupTimer > 0) {                  
      if (--this.popupTimer <= 0)
        this.setState({ showPopup: false });     
    }
    if (this.notifyTimer > 0) {
      if (--this.notifyTimer <= 0)
        this.setState({ showNotify: false });
    }
  }
  ClearMessage = async () => {
    //console.log("ClearMessage Pressed - for: " + SessionInfo.currentPage);
    this.setState({ MessagesValue: '' });
    SessionInfo.message = ''; // MessagesValue is set to SessionInfo.message in the Timer loop  
    this.setState({ showPopup: false });     
  }
  PopupMessage = async () => { 
    // Keep this code for example
    //var tag = document.createElement('div');
    //var newString = this.state.MessagesValue.replace(/<br\/>/g, "XXX");
    //console.log("newString: " + newString);
    //tag.innerHTML = newString;
    //newString = tag.innerHTML.replace(/XXX/g, "&#10;");  
    //console.log("newString: " + newString);
    //this.setState({ MessagesText: newString });
    if (this.state.showPopup === false)
      this.popupTimer = 50;
    else
      this.popupTimer = 0;
    this.setState({ showPopup: !this.state.showPopup });
    //this.setState({ dialogVisible: true });
  }
  toggleDialog = async () => {
    this.setState({
      dialogVisible: !this.state.dialogVisible
    });
  }               
  onSaveCurrent = async() => {
    //console.log("onSaveCurrent Pressed - for: " + SessionInfo.currentPage); 
    await SessionInfo.currSaveFunc();
  }
  onDeleteCurrent = async () => {
    //console.log("onDeleteCurrent Pressed - for: " + SessionInfo.currentPage);
    await SessionInfo.currDeleteFunc();
  }
  onClearCurrent = async () => {
    //console.log("onClearCurrent Pressed - for: " + SessionInfo.currentPage);
    SessionInfo.currClearFunc();
  }
  // Log off, logoff, sign out, sign off, signoff
  handleLogoff = async () => {
    //console.log("SessionInfo History: " + JSON.stringify(SessionInfo.History));  
    await LogOff();   
    console.log("End SessionInfo History: " + JSON.stringify(SessionInfo.History));
    if (SessionInfo.History) {
      console.log("Session goto AppMain");
      SessionInfo.History.push("/AppMain"); // Note - that the componentWillUnmount will execute for the current page
    }
    else if (this.props.History) {
      console.log("Goto AppMain");
      this.props.History.push("/AppMain"); // Note - that the componentWillUnmount will execute for the current page
    }
    else {
      return (<Redirect to='/' />);
    }
  }              

  render() {
    //let value = this.context;
    //const { hours, minutes, seconds, ampm } = this.state.currentTime;
    const { hours, minutes } = this.state.currentTime;
    //this.state.color0 = "#16f053";
    return (
      <div id="headerCntr">
        <div id="headerTopLeft">
          <a id="headerLogo" href="https://objetx.com/" target="_blank" rel="noopener noreferrer" />
          {this.state.appDisplay ?
            <div id="headerSearch">
              <textarea name="Search" onChange={evt => this.updateSearchValue(evt)} id="Search" value={this.state.searchValue}></textarea>
              <Button className="ButtonImg" onClick={this.searchButton}><img src={require("./images/Search.png")} alt="" className="searchBtnImg" /></Button>
            </div>
            : null}
        </div>
          <div id="headerBotLeft">
          <h2>{this.props.Title}</h2>
          <Popup offset={this.offset} show={this.state.showNotify} popupClass={'popup-content'} ><div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }}></div></Popup> 
            {this.state.appDisplay ?
              <div id="functionDiv">
                <img src={require("./images/Save.png")} alt="" id="Save" className="functionImage" onClick={this.onSaveCurrent}/>
              {/*<img src={require("./images/Printer.png")} alt="" id="Print" className="functionImage" />*/}
                <img src={require("./images/Screen.png")} alt="" id="Clear" className="functionImage" onClick={this.onClearCurrent}/>
                <img src={require("./images/Cancel.png")} alt="" id="Delete" className="functionImage" onClick={this.onDeleteCurrent}/>
              </div> 
            : null}
          {this.state.dialogVisible &&
            <Dialog title={"Please confirm"} onClose={this.toggleDialog}>
              <p style={{ margin: "25px", textAlign: "center" }}>Are you sure you want to continue?</p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleDialog}>No</button>
                <button className="k-button" onClick={this.toggleDialog}>Yes</button>
              </DialogActionsBar>
            </Dialog>}
          </div>
        {this.state.appDisplay || this.state.msgDisplay || (SessionInfo.session !== '' && SessionInfo.tempLogon === false) ?
          <div id="headerTopMid">                                   
            <img src={require("./images/DocumentNew.png")} alt="" id="ClearMessage" className="messageImage" onClick={this.ClearMessage} />
            <img src={require("./images/DirectionDiag2.png")} alt="" id="PopupMessage" className="popupImage" onClick={this.PopupMessage}/>
            <Popup anchor={this.anchor} show={this.state.showPopup} popupClass={'popup-content'} ><div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.MessagesValue }}></div></Popup>
            <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.MessagesValue }} ref={(div) => { this.anchor = div; }}></div>
          </div>
          : null}
        <div id="headerTopRight">
          {this.state.appDisplay || this.state.msgDisplay || (SessionInfo.session !== '' && SessionInfo.tempLogon === false) ?
            <div id="statusDiv">                                                                                                    
              {this.state.showFlag1 ? <img src={require("./images/ball_green.png")} alt="" id="Flag1" className="statusImage" /> : null}
              {this.state.showFlag2 ? <img src={require("./images/ball_yellow.png")} alt="" id="Flag2" className="statusImage" /> : null}
              {this.state.showFlag3 ? <img src={require("./images/ball_red.png")} alt="" id="Flag3" className="statusImage" /> : null}
              <div id="logonName">{this.state.logonName}</div>
            </div> : null
          }     
          {SessionInfo.session === '' ?
            <div id="loginDiv"> 
              <Link to="/Login"><img src={require("./images/Lock.png")} alt="" className="loginImageBig" /></Link>
              <Link to="/AppMain"><img src={require("./images/Home2.png")} alt="" className="loginImageSB" /></Link>
              <Link to="/AboutPage"><img src={require("./images/Info2.png")} alt="" className="loginImageSB" /></Link> 
              <Link to="/ContactUs"><img src={require("./images/WriteMessage.png")} alt="" className="loginImageSB" /></Link>
            </div> :
            <div id="loginDiv"> 
              <img src={require("./images/LockOpen.png")} alt="" className="loginImage" onClick={this.handleLogoff} />
              <Link to="/AppMain"><img src={require("./images/Home2.png")} alt="" className="loginImage" /></Link>
              <Link to="/AboutPage"><img src={require("./images/Info2.png")} alt="" className="loginImage" /></Link>
              <Link to="/SettingsPage"><img src={require("./images/Gear.png")} alt="" className="loginImage" /></Link>
              <Link to="/ContactUs"><img src={require("./images/WriteMessage.png")} alt="" className="loginImage" /></Link>
            </div>
          }
          <div id="clockDiv">
            {
              hours === 0 ? 12 : (hours > 12) ? hours - 12 : hours
            }:{
              minutes > 9 ? minutes : `0${minutes}`
            } {/*:{
              seconds > 9 ? seconds : `0${seconds}`
            } {ampm}*/}
          </div>
        </div>
        <div id="headerBotRight">  {/*-- Tab Header --*/}
          <div id="menuCntr">
            <ul>
              <li><Link style={{ color: this.state.colors[0] }} to="/AppMain" className="select">{this.props.L1}</Link></li>
              {SessionInfo.session !== '' ? <li><Link id="1000" style={{ color:this.state.colors[1] }} to="/ProjectDirectory">Projects</Link></li> : null}
              {SessionInfo.session !== '' ? <li><Link id="1001" style={{ color: this.state.colors[2] }} to="/ProjectDetail">Detail</Link></li> : null}
              {SessionInfo.session !== '' ? <li><Link id="1002" style={{ color: this.state.colors[3] }} to="/ProjectContract">Contract</Link></li> : null}   
              {/*{SessionInfo.session !== '' && SessionInfo.HasPerm51 ? <li><Link id="1003" style={{ color: this.state.colors[4] }} to="/ParticipantProfile">Profile</Link></li> : null} */}
              {SessionInfo.session !== '' ? <li><Link id="1003" style={{ color: this.state.colors[4] }} to="/ParticipantProfile">Profile</Link></li> : null}    
              {SessionInfo.session !== '' ? <li><Link id="1004" style={{ color: this.state.colors[5] }} to="/WorkflowDefinition">Workflow</Link></li> : null}      
            </ul>
          </div>
        </div>
        <div id="headerBotStrip"></div>
      </div>
    )
  }
}
PageHeader.propTypes = {
  counts: PropTypes.array,
  users: PropTypes.arrayOf(PropTypes.object),
  alarmColor: PropTypes.oneOf(['red', 'blue'])
}
//PageHeader.contextType = SessionContext;

export default PageHeader;