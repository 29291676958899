//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// SolidityInterface - Code to support working with Ethereum Solidity Contract
//              Version 1.007 - May 29, 2019
//---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
//import { Grid } from 'react-bootstrap';
import 'whatwg-fetch';                                                                         
import './App.css';                             
import { SessionInfo } from "./App";                     
import Web3 from 'web3'; // Note - if this fails to load - it is probably due to a metamask problem                          
// https://www.npmjs.com/package/react-pdf 
import CFFactory from './CFFactory';
import CFContract from './CFContract';
//import { withState } from './WithState.js';
import { GetTableRow, displayMessage, displayWarning, displayError, toBitArrayCodec, fromBitArrayCodec, GetFileExtension, displayNotify, askQuestion } from './CommonCode.js'; 

//overrides metamask v0.2 for our v 1.0
//const web3 = new Web3(window.SessionInfo.web3.currentProvider);
const sjcl = require('sjcl'); // Not sure about this - required for codec sub 
const IPFS = require('ipfs-api');
const ipfs = new IPFS({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' }); 
//const Home = () => (<div><h1>Welcome to Omecron</h1><Link to='/home'>Go To Contract Flow</Link></div>);
//const About = () => (<div><h1>About</h1><Link to='/'>Go home</Link></div>);
var crypto = require('crypto');  
var txHash;
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
// Initialize the Contract - Load or Create a new copy of the Smart Contract
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------                     
export async function InitializeContract() { // Called from onInitContract
  // Collections      
  console.log("InitializeContract");
  let roles = [];
  let rolesText = [];
  let steps = [];
  let stepsText = [];
  let qualifiers = [];
  let jx = 0;
  let lx = 0;
  let ix = 0;
  let roleCnt = 0;
  let stepCnt = 0;       
  //------------------------------------------------------------------------------------------------------------------------
  // Verify the Definition JSON and Initialize the StateMachine Contract 
  const accounts = await getEthAccounts();
  //const accounts = await SessionInfo.web3.eth.getAccounts();
  if (!accounts[0]) {
    displayError("MetaMask must be Setup and Logged On to Access Ethereum");
    return;
  }
  this.setCurrentParticipantID(accounts[0]);
  //------------------------------------------------------------------------------------------------------------------------ 
  console.log("Initializing Contract - Using Metamask Account: " + accounts[0]);
  this.setState({ MetaMaskAccount: accounts[0] });
  let web3version = SessionInfo.web3.version;
  console.log("Metamask is active - Create Contract - Web3 Version: " + web3version);
  //------------------------------------------------------------------------------------------------------------------------
  var ProjectInstanceContractAddress = '';
  var contractCnt = await CFFactory.methods.getContractCount().call({ from: accounts[0] });
  console.log("Contract Flow Contract Count: " + contractCnt);
  let currentGasPrice = 0;
  await SessionInfo.web3.eth.getGasPrice(function (error, result) {
    currentGasPrice = Number(result) / 1000000000;
  });
  let setGasPrice = SessionInfo.web3.utils.toWei(this.state.GasPrice.toString(), 'gwei').toString();
  //console.log("gaspriceA: " + setGasPrice + " type: " + typeof setGasPrice);
  console.log("Ethereum Gas Price is " + currentGasPrice + " gwei - Gas Price Set to: " + setGasPrice + " gwei"); // "10000000000000" 
  this.setState({ currentGasPrice });
  let gasUsed = 0;
  if (this.state.ProjectInstanceContractAddress) { // Use Existing Contract - Load Information from the Contract
    console.log("Use existing contract: " + this.state.ProjectInstanceContractAddress);
    ProjectInstanceContractAddress = this.state.ProjectInstanceContractAddress;
    let isValid = false;
    try {
      isValid = await CFFactory.methods.isContractValid(ProjectInstanceContractAddress).call({ from: accounts[0] });
    }
    catch { //Invalid Address
      isValid = false;
      console.log("Invalid Address");
    }
    if (isValid === false) {
      displayError("The Contract Address Provided is Invalid");
      if (askQuestion("Do you want to Replace the Ethereum Contract?") === true) {
        this.setState({ ProjectInstanceContractAddress: '' });
        this.setState({ CFProjectStatusID: 1 });
        await this.saveRecord();
        displayMessage("Reference to old Contract removed");
      }
      else {
        displayError("Contract Intialization cannot proceed");
      }
      return;
    }
    // Set the Working Address in the CFContract ABI
    CFContract.options.address = ProjectInstanceContractAddress;
    if (this.state.roleCount > 0) { // Contract Already Initialized/Loaded
      await this.getCurrentInfo(false); // Refresh the Contract Information
      return;
    }
    stepCnt = await CFContract.methods.getStepCount().call({ from: accounts[0] });
    roleCnt = await CFContract.methods.getRoleCount().call({ from: accounts[0] });
    //console.log("Contract stepCnt: " + stepCnt + " roleCnt: " + roleCnt);
    //------------------------------------------------------------------------------------------------------------------------
    if (stepCnt > 0 && roleCnt > 0) { // Load Existing
      //displayMessage("Contract already Initialized - stepCnt: " + stepCnt + " - roleCnt: " + roleCnt);
      await this.loadExistingContract();
      return; // Use the existing Contract setup
    }
    if (!this.state.procDefStr) {
      displayMessage("A Workflow Description is Required to Initialize a Contract Flow");
      return;
    }
    // Otherwise, the contract has not been initialized - carry on with initialization
  }
  else { // No Contract Address - Load most recent or Create new Contract
    console.log("No Contract Address - Initialize - contractCreate: " + this.state.contractCreate);
    //this.state.contractCreate = false; //******* TESTING ******
    if (this.state.contractCreate === false) { // Note - this should NEVER be the case except for TESTING - this is the ONLY place it is tested
      displayError("Contract Address Not Provided - And - Create Contract NOT Selected");
      this.getLastestContractAddress(contractCnt, accounts[0]); 
      return;
    } //-----------------------------------------------------------------------------------------------------------------------------------------------
    else { // Create NEW Instance of the Contract Flow Contract 
      if (!this.state.procDefStr) {
        displayMessage("A Workflow Description is Required to Create a Contract Flow");
        return;
      }
      let roleID = this.getCurrentRole(accounts[0]);
      let controlRoleID = await this.getWorkflowControlRole();
      console.log("RoleID: " + roleID + " controlRoleID: " + controlRoleID);
      if (roleID !== controlRoleID) {
        let index = this.state.CFProjectContractParticipants.findIndex(p => p.CFWorkflowRoleID === controlRoleID); //p.CFParticipantID === PKID);
        //console.log("setCurrentParticipantID - index: " + index);
        if (index >= 0) {
          //console.log("WorkflowRoles: " + JSON.stringify(this.state.WorkflowRoles));
          index = this.state.WorkflowRoles.findIndex(p => p.ddID === controlRoleID);
          if (index >= 0)
            displayError("Only the Workflow Control Role: '" + this.state.WorkflowRoles[index].ddName + "' can Initialize the Contract");
          else
            displayError('Workflow does not contain the Control Role'); // Should never happen
        }
        else {
          index = this.state.WorkflowRoles.findIndex(p => p.ddID === controlRoleID);
          if (index >= 0)
            displayError("The Participants do not include the Workflow Control Role: '" + this.state.WorkflowRoles[index].ddName + "'");
          else
            displayError('Workflow does not contain the Control Role'); // Should never happen
        }
        return;
      }
      //--------------------------------------------
      // Check for duplicate Wallet Addresses
      let blankAddress = false;
      let duplicateAddress = false;
      let dupAddress = '';
      let addresses = [];
      let addressUsed = new Map();
      let rCnt = this.state.roles.length;
      for (ix = 0; ix < this.state.roles.length; ix++) {
        if (this.state.walletAddresses[ix] === '')
          blankAddress = true;
        else if (addressUsed[this.state.walletAddresses[ix]] === true) {
          dupAddress = this.state.walletAddresses[ix];
          duplicateAddress = true;
        }
        else {
          addressUsed[this.state.walletAddresses[ix]] = true;
          addresses.push(this.state.walletAddresses[ix]);
        }
      }                                                            
      if (blankAddress === true || duplicateAddress === true) {
        if (duplicateAddress === true)
          displayError("Duplicate Wallet (" + dupAddress + ") Address - Not Permitted");
        else
          displayError("All Role Addresses (" + rCnt + ") Must be Defined");
        this.setState({ autoLoadContract: false });
        return;
      }
      //--------------------------------------------
      if (this.state.isCreatingContract === true) {
        displayMessage("Contract Creation in Progress 3");
        return;
      }
      else {
        displayMessage("Creating Instance of onNovos Contract Flow Contract");
        this.state.isCreatingContract = true;
        this.setState({ autoLoadContract: true });
        txHash = undefined;
        if (this.state.remindMetaMask === true)
          displayNotify("Click on MetaMask and Confirm the Create Smart Contract Transaction");
        try {                                                                                       
          ProjectInstanceContractAddress = await CFFactory.methods.createContractFlow().send({ from: accounts[0], gas: '5000000', gasPrice: setGasPrice }, (txerror, transactionHash) => {
            if (txerror)
              console.log("Create Contract Error: " + txerror);
            else
              console.log("Contract Flow Created - Object: " + JSON.stringify(ProjectInstanceContractAddress) + " TxHash: " + transactionHash);
            //console.log("After create-1");
            txHash = transactionHash;
            this.setState({ transactionHash });
            //console.log("After create-2"); 
          });
          //console.log("After create-3");                     
          if (txHash)
            await this.addBlockchainTransaction(txHash, "Create Instance of onNovos Contract Flow Contract");
          //console.log("after createContractFlow");
          //------------------
          //console.log("2- Contract Flow Created - Object: " + JSON.stringify(ProjectInstanceContractAddress) + " TxHash: " + txHash);
          ProjectInstanceContractAddress = await CFFactory.methods.getCurrentSMAddress().call({ from: accounts[0] }); // Call this to get the actual address              
          //console.log("After Call to CurrentSMAddress: " + JSON.stringify(ProjectInstanceContractAddress));
          this.setState({ ProjectInstanceContractAddress });
          CFContract.options.address = ProjectInstanceContractAddress;
          gasUsed = await CFFactory.methods.getGasUsed().call({ from: accounts[0] }); // Call this to get the actual address  
          const ContractVersion = await CFContract.methods.getContractVersion().call({ from: accounts[0] });
          this.setState({ ContractVersion });
          for (ix = 0; ix < this.state.contractRoles.length; ix++) { // Find the address that matches the current MetaMask Account
            if (this.state.contractWalletData[ix].WalletAddress === accounts[0]) {
              this.setState({ contractCurrentRole: this.state.contractRoles[ix] });
              break;
            }
          }
          //this.setState({ isParticipant: (currRole > 0) });
          displayMessage("Contract Flow Ethereum Contract Created - Gas Used: " + gasUsed.toLocaleString());
        }
        catch (error) {
          console.log("Error on Initialize: " + error);
        }
        //console.log("After create-Last");
        this.state.isCreatingContract = false;  
      }
    }
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------
  //   Initialize the Ethereum Contract with the Workflow Information              ------------------------------------------------------------------------ 
  //-------------------------------------------------------------------------------------------------------------------------------------------------------
  let ownerAddress;
  try {
    ownerAddress = await CFContract.methods.getOwnerAddress().call({ from: accounts[0] });
  }
  catch { // Invalid Contract Address?  
    console.log("getOwnerAddress Failed");
    ProjectInstanceContractAddress = await CFFactory.methods.getCurrentSMAddress().call({ from: accounts[0] }); // Call this to get the actual address 
    this.setState({ ProjectInstanceContractAddress });
  }
  try {
    ownerAddress = await CFContract.methods.getOwnerAddress().call({ from: accounts[0] });
  }
  catch { // Invalid Contract Address - Again! 
    displayError("Unable to get OwnerAddress from Contract - restart Application");
    this.setState({ autoLoadContract: false });
    return;
  }
  await this.setState({ ownerAddress });                  
  displayMessage("Initializing Ethereum Contract");
  let processDefinition;
  try {
    processDefinition = JSON.parse(this.state.procDefStr);
    if (processDefinition.version !== "0.4") {
      displayError("Invalid Process Definition Version: " + processDefinition.version);
      this.setState({ autoLoadContract: false });
      return;
    }
  }
  catch (error) {
    console.log("Parse JSON Error: " + error);
    displayError("Error on JSON Parse of Workflow Description - Fix and reload file");
    this.setState({ autoLoadContract: false });
    return;
  } //catch       
  let rolesUsed = new Map();
  this.state.contractRoles = [];
  roleCnt = processDefinition.roles.length;
  if (processDefinition.roles.length > 7) {
    displayError("The Number of Roles: " + roleCnt + " exceeds the current maximum of 7");
    roleCnt = 7;
  }
  for (ix = 0; ix < roleCnt; ix++) {
    if (rolesUsed[processDefinition.roles[ix].role] === true) {
      displayError("Duplicate Role in Workflow Definition: '" + processDefinition.roles[ix].role + "'");
      this.setState({ autoLoadContract: false });
      return;
    }
    this.state.roles.push(processDefinition.roles[ix].role); 
    rolesUsed[processDefinition.roles[ix].role] = true;
    roles.push(SessionInfo.web3.utils.fromAscii(processDefinition.roles[ix].role).padEnd(66, '0'));
    rolesText.push(processDefinition.roles[ix].role);
    qualifiers.push(SessionInfo.web3.utils.fromAscii(processDefinition.roles[ix].roleQual).padEnd(66, '0'));
  }                                                              
  roleCnt = roles.length;
  displayMessage("Initialize Workflow Roles: " + roles.length);
  let stepsReferenced = new Map();
  for (ix = 0; ix < processDefinition.stepChanges.length; ix++) {
    if (stepsText.indexOf(processDefinition.stepChanges[ix].step) >= 0) {
      displayError("Duplicate Step in Workflow Definition: '" + processDefinition.stepChanges[ix].step + "'");
      this.setState({ autoLoadContract: false });
      return;
    }
    steps.push(SessionInfo.web3.utils.fromAscii(processDefinition.stepChanges[ix].step).padEnd(66, '0'));
    stepsText.push(processDefinition.stepChanges[ix].step);
    stepsReferenced[processDefinition.stepChanges[ix].step] = false;
  }
  stepCnt = steps.length;
  displayMessage("Initialize Workflow Steps - Count: " + stepCnt);
  steps.push(SessionInfo.web3.utils.fromAscii("X").padEnd(66, '0')); // Flag 
  for (ix = 0; ix < processDefinition.stepChanges.length; ix++) {
    if (processDefinition.stepChanges[ix].stepChangeRoles.length === 0)
      steps.push(SessionInfo.web3.utils.fromAscii(processDefinition.stepChanges[ix].step).padEnd(66, '0')); // Step
    else {
      for (jx = 0; jx < processDefinition.stepChanges[ix].stepChangeRoles.length; jx++) {
        //if (rolesText.indexOf(processDefinition.stepChanges[ix].stepChangeRoles[jx].role) < 0)
        //console.log("State Change Role: " + processDefinition.stepChanges[ix].stepChangeRoles[jx].role + "  - for State: " + processDefinition.stepChanges[ix].step);
        if (rolesUsed[processDefinition.stepChanges[ix].stepChangeRoles[jx].role] !== true) {
          displayMessage("Unknown Role: '" + processDefinition.stepChanges[ix].stepChangeRoles[jx].role + "' - In Roles for State: " + processDefinition.stepChanges[ix].step);
          this.setState({ autoLoadContract: false });
          return;
        } else {
          if (jx === 0)
            steps.push(SessionInfo.web3.utils.fromAscii(processDefinition.stepChanges[ix].step).padEnd(66, '0')); // State
          steps.push(SessionInfo.web3.utils.fromAscii(processDefinition.stepChanges[ix].stepChangeRoles[jx].role).padEnd(66, '0')); // Role
          let stepChngNames = [];
          for (lx = 0; lx < processDefinition.stepChanges[ix].stepChangeRoles[jx].steps.length; lx++) {
            if (stepsText.indexOf(processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx]) < 0) {
              displayMessage("Unknown State: '" + processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx] + "' - In Steps for Role: " + processDefinition.stepChanges[ix].stepChangeRoles[jx].role);
              this.setState({ autoLoadContract: false });
              return;
            }
            else {
              if (stepChngNames.indexOf(processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx]) >= 0) {
                displayError("Duplicate State in State Change Definition: '" + processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx] + "' - In Steps for Role: " + processDefinition.stepChanges[ix].stepChangeRoles[jx].role);
                this.setState({ autoLoadContract: false });
                return;
              }
              steps.push(SessionInfo.web3.utils.fromAscii(processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx]).padEnd(66, '0'));    
              stepChngNames.push(processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx]);
              if (stepsReferenced[processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx]] !== true)
                stepsReferenced[processDefinition.stepChanges[ix].stepChangeRoles[jx].steps[lx]] = true;
            }
          }
          if (jx < processDefinition.stepChanges[ix].stepChangeRoles.length - 1)
            steps.push(SessionInfo.web3.utils.fromAscii("Y").padEnd(66, '0'));     
        }
      }
    }
    if (ix >= processDefinition.stepChanges.length - 1)
      steps.push(SessionInfo.web3.utils.fromAscii("Z").padEnd(66, '0'));
    else
      steps.push(SessionInfo.web3.utils.fromAscii("X").padEnd(66, '0'));
  }
  let noRefCnt = 0;
  for (ix = 0; ix < stepCnt; ix++) {
    if (stepsReferenced[stepsText[ix]] === false) {
      displayMessage("Step: '" + stepsText[ix] + "' is Not referenced in your Workflow");
      noRefCnt++;
    }
  }
  if (noRefCnt > 1) {
    displayError("More than one non-referenced Step in the Workflow is not allowed");
    this.setState({ autoLoadContract: false });
    return;
  }
  //console.log("---------------------------------------------");
  //console.log("Initialize Step Roles & Transitions - Role Count: " + roles.length + " - Step Count: " + stepCnt);
  //console.log("roles: " + JSON.stringify(roles));
  //for (ix = 0; ix < roles.length; ix++)
  //  console.log("ix: " + ix + "  role: " + SessionInfo.web3.utils.toUtf8(roles[ix]) + " qual: " + SessionInfo.web3.utils.toUtf8(qualifiers[ix]));
  //console.log("---------------------------------------------");
  //----------------------------------------------------------------
  displayMessage("Set Role Addresses and Public Keys");
  //console.log("InitializeContract - Set Roles: " + JSON.stringify(this.state.roles));
  //console.log("Set Role Addresses: " + JSON.stringify(this.state.walletAddresses));
  //console.log("Set Role Keys: " + JSON.stringify(this.state.publicKeys));
  // Check for duplicate Wallet Addresses - Again (see above)
  let blankAddress = false;
  let duplicateAddress = false;  
  let dupAddress = '';         
  let addresses = [];
  let addressUsed = new Map();
  let rCnt = this.state.roles.length;
  for (ix = 0; ix < this.state.roles.length; ix++) {
    if (this.state.walletAddresses[ix] === '')
      blankAddress = true;
    else if (addressUsed[this.state.walletAddresses[ix]] === true) {
      dupAddress = this.state.walletAddresses[ix];
      duplicateAddress = true;
    }
    else {                                                                                           
      addressUsed[this.state.walletAddresses[ix]] = true;  
      addresses.push(this.state.walletAddresses[ix]);             
    }
  }
  //----------------------------------------------------------------  
  this.setState({ workflowName: processDefinition.name });
  this.setState({ workflowDescription: processDefinition.description });
  this.setState({ workflowVersion: processDefinition.version });                    
  if (blankAddress === true || duplicateAddress === true) {
    if (duplicateAddress === true)
      displayError("Duplicate Wallet (" + dupAddress + ") Address - Not Permitted");
    else
      displayError("All Role Addresses (" + rCnt + ") Must be Defined");
    this.setState({ autoLoadContract: false });
    return;
  }
  else {
    txHash = undefined;
    displayMessage("Initialize the Roles, Steps and Transitions for the Workflow");
    //console.log("roles: " + JSON.stringify(roles));
    //console.log("qualifiers: " + JSON.stringify(qualifiers));
    //console.log("addresses: " + JSON.stringify(addresses));
    //console.log("steps: " + JSON.stringify(steps));
    if (this.state.remindMetaMask === true)
      displayNotify("Click on MetaMask and Confirm the Initialize Roles, Steps and Transitions Transaction");
    await CFContract.methods.setRolesSteps(roles, qualifiers, addresses, steps, this.state.workflowName, this.state.workflowDescription, this.state.workflowVersion, this.state.encryptScheme).send({  //, stateInit
      from: accounts[0], gas: 5500000, gasPrice: SessionInfo.web3.utils.toWei(this.state.GasPrice.toString(), 'gwei')
    }, (error, transactionHash) => {
      if (error)
        console.log("set Roles Steps Error: " + error);
      this.addBlockchainTransaction(transactionHash, "Set the Roles, Steps and Transitions for the Workflow");
      this.setState({ transactionHash });
    });                
  }                         
  //------------------------------------------------------------------
  if (this.state.InitialContribution > 0) {
    displayMessage("Initialize Contract Workflow Complete - Contribute " + this.state.InitialContribution + " Milli to Contract");
    if (this.state.remindMetaMask === true)
      displayNotify("Click on MetaMask and Confirm the Contribute Transaction"); 
    await CFContract.methods.contribute().send({
      from: accounts[0], value: new SessionInfo.web3.utils.toBN(SessionInfo.web3.utils.toWei(this.state.InitialContribution.toString(), 'milli')), gas: 1000000, gasPrice: SessionInfo.web3.utils.toWei(this.state.GasPrice.toString(), 'gwei')
    }, (error, transactionHash) => {
      if (error)
        console.log("Contribute Error: " + error);
        //console.log(transactionHash);     
      this.addBlockchainTransaction(transactionHash, "Contribute " + this.state.InitialContribution + " Milli to the Contract");
      this.setState({ transactionHash });
    });
  }
  else
    displayWarning("No Initial Contribution specified for Contract")
  this.state.baseKey = await CFContract.methods.getKey().call({ from: accounts[0] }); // Get BaseKey created by Contract
  //console.log("Contract baseKey : " + this.state.baseKey);
  await this.loadExistingWorkflow(roles);    
  displayMessage("Contract Initialization Complete - Contract Address in Ethereum Contract Tab");
  this.setState({ CFProjectStatusID: 3 }); // Ethereum Setup
  this.setState({ isCurrOwner: true });  // This should not be necessary - but just to be sure for now - 3Jun19  
  this.setState({ isParticipant: true });
  this.setState({ buttonsVisible: true });
  this.setState({ isContractLoaded: true });
  this.forceUpdate();
  await this.saveRecord();
  await this.getCurrentInfo(true);
  if (this.state.ownerAddress === '')
    this.setState({ ownerAddress: accounts[0] }); 
  this.setState({ buttonsVisible: true }); 
  this.setState({ isCurrOwner: true });  // This should make IPFS controls visible  
  this.setState({ isParticipant: true });
  this.setState({ isContractLoaded: true });
  this.setState({ autoLoadContract: false });  
  this.setState({ createButtonText : 'Refresh Contract' });
} // InitializeContract
//------------------------------------------------------------------------------
//export async function CreateContractFlow(fromAccount, gasPrice) {
//  try {
//    txHash = undefined;
//    let ProjectInstanceContractAddress = await CFFactory.methods.createContractFlow().send({ from: fromAccount, gas: '5000000', gasPrice: gasPrice }, (txerror, transactionHash) => {
//      if (txerror)
//        console.log("Create Contract Error: " + txerror);
//      else
//        console.log("Contract Flow Created - Object: " + JSON.stringify(ProjectInstanceContractAddress) + " TxHash: " + transactionHash);
//      console.log("After create-1");
//      txHash = transactionHash;
//      console.log("After create-1.5");
//    });
//    console.log("After create-2");
//    if (!txHash) {
//      displayError("Failure on Create Smart Contract");
//      this.state.isCreatingContract = false;
//      return;
//    }
//    else {
//      await this.addBlockchainTransaction(txHash, "Create Instance of onNovos Contract Flow Contract");
//    }
//  } catch (error) {
//    console.log("createContractFlow error: " + error);
//  }
//  console.log("After create-3");
//}
export async function GetLastestContractAddress(contractCnt,fromAccount) {        
  let ProjectInstanceContractAddress;
  if (contractCnt > 0) { // At least one contract has been created - get the latest one
    ProjectInstanceContractAddress = await CFFactory.methods.getCurrentSMAddress().call({ from: fromAccount });
    console.log("Current CF Contract Address: " + ProjectInstanceContractAddress);
  }
  if (ProjectInstanceContractAddress && ProjectInstanceContractAddress !== '') {
    this.setState({ ProjectInstanceContractAddress });
    CFContract.options.address = ProjectInstanceContractAddress; // Set the Working Address in the CFContract ABI
    let stepCnt = await CFContract.methods.getStepCount().call({ from: fromAccount });
    let roleCnt = await CFContract.methods.getRoleCount().call({ from: fromAccount });
    console.log("stepCnt: " + stepCnt + " roleCnt: " + roleCnt);
    if (stepCnt > 0) {
      this.setState({ initButtonTitle: 'Load Contract Info' });
      this.setState({ isInitState: false });
    }
    else {
      this.setState({ createButtonText: 'Load Contract' });
      this.setState({ isInitState: true });
    }
  }
  return;
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------                    
export async function LoadExistingContract() {
  displayMessage("Loading Ethereum Contract Information");
  const accounts = await getEthAccounts();
  this.setCurrentParticipantID(accounts[0]);
  let roles = await CFContract.methods.getRoles().call({ from: accounts[0] });
  await this.loadExistingWorkflow(roles);
  //console.log("Setup Wallet Data - contractRoles: " + this.state.contractRoles.length);
  if (this.state.contractRoles.length === 0) { // Not Initialized Yet
    for (let ix = 0; ix < roles.length; ix++) {
      this.state.contractRoles.push(SessionInfo.web3.utils.toUtf8(roles[ix]));
      if (this.state.contractWalletData.length <= ix)
        this.state.contractWalletData.push({ "RoleID": ix + 1, "RoleName": "", "WalletAddress": "", "PublicKey": "" });
      this.state.contractWalletData[ix].RoleName = SessionInfo.web3.utils.toUtf8(roles[ix]);
    }
  }
  //console.log("contractWalletData: " + JSON.stringify(this.state.contractWalletData));
  //console.log("contractRoles: " + JSON.stringify(this.state.contractRoles));
  this.state.addressFieldsRO = true;
  const workflowName = await CFContract.methods.getCFName().call({ from: accounts[0] });
  this.setState({ workflowName });
  const workflowDescription = await CFContract.methods.getCFDescription().call({ from: accounts[0] });
  this.setState({ workflowDescription });
  const workflowVersion = await CFContract.methods.getCFVersion().call({ from: accounts[0] });
  this.setState({ workflowVersion });
  const ContractVersion = await CFContract.methods.getContractVersion().call({ from: accounts[0] });
  this.setState({ ContractVersion });
  let timestamp = await CFContract.methods.getInitTimestamp().call({ from: accounts[0] });
  let ContractDate = new Date(timestamp * 1000);
  this.setState({ ContractDate });
  const roleCount = await CFContract.methods.getRoleCount().call({ from: accounts[0] });
  this.setState({ roleCount });
  const stepCount = await CFContract.methods.getStepCount().call({ from: accounts[0] });
  this.setState({ stepCount });
   // Load in the rest of the contract information - including the Account Addresses for each Role
  await this.getCurrentInfo(true);
} // LoadExistingContract                               
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
// This is called from InitializeContract and LoadExistingContract
export async function LoadExistingWorkflow(roles) { 
  const accounts = await getEthAccounts();
  this.setCurrentParticipantID(accounts[0]);
  let ix = 0;
  let jx = 0;
  let kx = 0;
  let roleJson = '[';
  for (ix = 0; ix < roles.length; ix++) {
    //console.log("ix: " + ix + "  role: " + SessionInfo.web3.utils.toUtf8(roles[ix]));
    roleJson += '{"RoleName":"' + SessionInfo.web3.utils.toUtf8(roles[ix]) + '","RoleQualifier":"1","RoleDescription":"Description for: ' + SessionInfo.web3.utils.toUtf8(roles[ix]).toLowerCase() + '"}';
    if (ix < roles.length - 1)
      roleJson += ',';
  }
  roleJson += "]";
  let roleGridData = JSON.parse(roleJson);
  this.setState({ roleGridData });
  //console.log("---------------------------------------------");
  let stepsJson = '[';
  let steps = await CFContract.methods.getSteps().call({ from: accounts[0] });
  for (ix = 0; ix < steps.length; ix++) {
    //console.log("ix: " + ix + "  state: " + SessionInfo.web3.utils.toUtf8(steps[ix]));
    let stepRoles = await CFContract.methods.getCurrentRolesForStepName(steps[ix]).call({ from: accounts[0] });
    if (stepRoles.length === 0)
      stepsJson += '{"NextStepName":"' + SessionInfo.web3.utils.toUtf8(steps[ix]) + '"}';
    else {
      for (jx = 0; jx < stepRoles.length; jx++) {
        //console.log("   jx: " + jx + "  state Role: " + SessionInfo.web3.utils.toUtf8(stepRoles[jx]));
        let stepRoleSteps = await CFContract.methods.getStepRoleStepNameTransitions(steps[ix], stepRoles[jx]).call({ from: accounts[0] });
        if (stepRoleSteps.length === 0)
          stepsJson += '{"NextStepName":"' + SessionInfo.web3.utils.toUtf8(steps[ix]) + '","RoleName":"' + SessionInfo.web3.utils.toUtf8(stepRoles[jx]) + '"}';
        else {
          stepsJson += '{"NextStepName":"' + SessionInfo.web3.utils.toUtf8(steps[ix]) + '","RoleName":"' + SessionInfo.web3.utils.toUtf8(stepRoles[jx]) + '",';
          for (kx = 0; kx < stepRoleSteps.length; kx++) {
            stepsJson += '"State' + (kx + 1) + '":"' + SessionInfo.web3.utils.toUtf8(stepRoleSteps[kx]) + '"';
            //console.log("     kx: " + kx + "  state Role State: " + SessionInfo.web3.utils.toUtf8(stepRoleSteps[kx]));
            if (kx < stepRoleSteps.length - 1)
              stepsJson += ",";
          }
        }
        if (jx < stepRoles.length - 1)
          stepsJson += "},";
        else
          stepsJson += "}";
      }
    }
    if (ix < steps.length - 1)
      stepsJson += ",";
  }
  stepsJson += "]";
  //console.log("steps Data: " + stepsJson);
  let stepsGridData = JSON.parse(stepsJson);
  this.setState({ stepsGridData });
  //console.log("Debug - Roles Data: " + JSON.stringify(this.state.roleGridData));
  //console.log("Debug - steps Data: " + JSON.stringify(this.state.stepsGridData));
  displayMessage("Workflow Loaded from Ethereum Contract");
} // LoadExistingWorkflow
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
// Get the Current Ethereum Contract Information - This is called from InitializeContract, LoadExistingContract, GoToWorkflowStep and CancelContract
export async function GetCurrentInfo(isLoad) { // Get (Refresh) the Current Ethereum Contract Information 
  try {
    let hashStr;
    const accounts = await getEthAccounts();
    this.setCurrentParticipantID(accounts[0]);
    console.log('GetCurrentInfo - using Metamask account: ' + accounts[0]);
    let ownerAddress = await CFContract.methods.getOwnerAddress().call({ from: accounts[0] });
    let ownerBalance = await CFContract.methods.getBalance().call({ from: accounts[0] });
    let isParticipant = await CFContract.methods.isAddressParticipant().call({ from: accounts[0] }); // Note 1May19 - this is returning true - when false 
    if (!this.state.ownerAddress)
      this.setState({ ownerAddress });
    console.log("-- Participant: " + isParticipant + " balance: " + ownerBalance + " owner adrress: " + this.state.ownerAddress + " SM OWN: " + ownerAddress);
    if (isParticipant === true || accounts[0] === this.state.ownerAddress) {
      this.setState({ buttonsVisible: true });
      this.setState({ isParticipant: true });
      if (accounts[0] === this.state.ownerAddress)
        this.setState({ isCurrOwner: true });
      else
        this.setState({ isCurrOwner: false });
      this.setState({ ownerBalance });
      //console.log("OwnerBalance: " + ownerBalance + "  Is 0: " + (ownerBalance == 0));
      if (ownerBalance === 0) {
        displayMessage("Owner Balance is zero");
        txHash = undefined;
        if (accounts[0] === this.state.ownerAddress) {
          displayMessage("Initialize Owner Balance"); 
          await CFContract.methods.contribute().send({
            from: accounts[0], value: new SessionInfo.web3.utils.toBN(SessionInfo.web3.utils.toWei('10', 'milli')), gas: 1000000, gasPrice: SessionInfo.web3.utils.toWei(this.state.GasPrice.toString(), 'gwei')
          }, (error, transactionHash) => {
            if (error)
              console.log("Error on Init Owner Balance: " + error); 
            txHash = transactionHash;
          });
          displayMessage("Contribute 10 gwei to Contract");
          ownerBalance = await CFContract.methods.getBalance().call({ from: accounts[0] });
          this.setState({ ownerBalance });
        }
        else
          displayMessage("Owner Balance is 0 - Will not be able to Transition State");
        if (txHash)
          await this.addBlockchainTransaction(txHash, "Initialize Owner Balance to 10 gwei");
      }
      if (isLoad === true) {
        let baseKey = await CFContract.methods.getKey().call({ from: accounts[0] });
        //console.log("init - baseKey: " + baseKey);
        this.setState({ baseKey });
        try {
          hashStr = await CFContract.methods.getDocumentAddress().call({ from: accounts[0] });
          this.setState({ IPFSDocumentAddress: hashStr });
          if (hashStr !== '') { // Document has been saved                                                                                                                      
            let encObj = JSON.parse('{"iv":"RFmjqpg7jFXAM2rcIdUiRw==","v":1,"iter":10000,"ks":256,"ts":64,"mode":"ccm","adata":"","cipher":"aes","salt":"gVlF5kg5lXAwjK2dGzwQ","ct":""}');
            hashStr = await CFContract.methods.getDocumentNonce().call({ from: accounts[0] });
            //console.log("Nonce: " + hashStr);
            encObj.ct = hashStr;
            //console.log("Nonce Enc: " + JSON.stringify(encObj));
            hashStr = sjcl.decrypt(baseKey, JSON.stringify(encObj));
            //console.log("decrypt Nonce: " + hashStr);
            this.setState({ DocumentNonce: hashStr });
            //
            hashStr = await CFContract.methods.getDocumentSalt().call({ from: accounts[0] });
            //console.log("Salt: " + hashStr);
            encObj.ct = hashStr;
            hashStr = sjcl.decrypt(baseKey, JSON.stringify(encObj));
            //console.log("decrypt Salt: " + hashStr);
            this.setState({ DocumentSalt: hashStr });
            //
            hashStr = await CFContract.methods.getDocumentType().call({ from: accounts[0] });
            //console.log("Type: " + hashStr);
            encObj.ct = hashStr;
            hashStr = sjcl.decrypt(baseKey, JSON.stringify(encObj));
            this.setState({ ContractDocumentType: hashStr });
          }
        }
        catch (error) {
          console.log("Document Key Load Error: " + error);
        } //catch 
        hashStr = await CFContract.methods.getEncryptScheme().call({ from: accounts[0] });
        this.setState({ encrytScheme: hashStr });
      }
    }
    else {
      this.setState({ isCurrOwner: false });
      this.setState({ buttonsVisible: false });
      console.log("isLoad: false");
      //for (let ix = 1; ix < this.state.walletAddresses.length; ix++)
      //  this.state.walletAddresses[ix] = '';
    }
    hashStr = await CFContract.methods.getCurrentStepId().call({ from: accounts[0] });
    let contractCurrentStep = SessionInfo.web3.utils.toUtf8(hashStr);
    this.setState({ contractCurrentStep });
    //console.log("walletAddresses: " + JSON.stringify(this.state.walletAddresses));
    //console.log("Steps: " + JSON.stringify(this.state.WorkflowSteps));   
    let currStep = this.state.WorkflowSteps.find(p => p.ddName === contractCurrentStep).ddID;
    //console.log("Current Step: " + contractCurrentStep + "  ID: " + currStep);
    this.setState({ CurrentStepID: currStep });
    let wfsidx = this.state.WorkflowStepChart.findIndex(c => c.StepName === contractCurrentStep);
    this.state.WorkflowStepChart.forEach(item => item.value = 10);  
    this.state.WorkflowStepChart[wfsidx].value = 50;
    this.state.CFWorkflowSteps.forEach(item => item.selected = false);
    wfsidx = this.state.CFWorkflowSteps.findIndex(c => c.CFWorkflowStepID === currStep);
    console.log("Load - currentstep: " + currStep + " steps idx: " + wfsidx);
    this.state.CFWorkflowSteps[wfsidx].selected = true; // Set the Step Highlighted in Workflow Steps
    this.forceUpdate();
    if (isLoad === true) {
      //------------------ Set Role Addresses ----------------------
      //console.log("Load wallet Addresses");
      let addressesSet = false;
      let roleAddress = '';
      if (isParticipant === true) {
        //console.log("Contract Roles: " + JSON.stringify(this.state.contractRoles));
        //console.log("walletAddresses: " + JSON.stringify(this.state.walletAddresses));
        for (let ix = 0; ix < this.state.contractRoles.length; ix++) {
          let roleHex = SessionInfo.web3.utils.fromAscii(this.state.contractRoles[ix]).padEnd(66,'0');
          //console.log("RoleHex: " + roleHex);
          roleAddress = await CFContract.methods.getRoleAddress(roleHex).call({ from: accounts[0] });
          //console.log(" For Role: " + this.state.contractRoles[ix] + " Address: " + roleAddress);
          if (roleAddress && roleAddress !== '0x0000000000000000000000000000000000000000') {
            this.state.contractWalletData[ix].WalletAddress = roleAddress; // Get the Address from the Ethereum Contract 
            this.state.contractWalletData[ix].PublicKey = this.state.publicKeys[ix]; // Get the Address from the Ethereum Contract
            // NOTE: 23Apr19 - Used the RoleID to get the PublicKey from the Database
            // NOTE: Verify that they match the DB walletAddresses 
            if (this.state.contractWalletData[ix].WalletAddress !== this.state.walletAddresses[ix]) {
              displayError("Contract Address: " + this.state.contractWalletData[ix].WalletAddress + " is not equal DB Address: " + this.state.walletAddresses[ix]);
              console.log("ContractRole: " + this.state.contractRoles[ix] + " WalletDateRole: " + this.state.contractWalletData[ix].RoleName);
            }
            addressesSet = true;
          }
        }
      }
      this.setState({ roleAddressesSet: addressesSet });
      //console.log("contractWalletData with Addresses: " + JSON.stringify(this.state.contractWalletData));
    }   
    //-------------------------------------------------------
    let ix;
    for (ix = 0; ix < this.state.contractRoles.length; ix++) { // Find the address that matches the current MetaMask Account
      if (this.state.contractWalletData[ix].WalletAddress === accounts[0]) {
        this.setState({ contractCurrentRole: this.state.contractRoles[ix] });
        break;
      }
    }
    let totalAllGasUsed = await CFContract.methods.getTotalAllGasUsed().call({ from: accounts[0] });
    totalAllGasUsed /= 1000000000;
    this.setState({ totalAllGasUsed });
    let totalRoleGasUsed = await CFContract.methods.getTotalRoleGasUsed().call({ from: accounts[0] });
    totalRoleGasUsed /= 1000000000;
    this.setState({ totalRoleGasUsed });
    //console.log("total Gas: " + totalAllGasUsed + " totalRoleGas: " + totalRoleGasUsed);
    let timestamp = await CFContract.methods.getLastChangeDateTime().call({ from: accounts[0] });
    let LastChangeDateTime = new Date(timestamp * 1000);
    //console.log("LastChangeDateTime: " + LastChangeDateTime);
    this.setState({ LastChangeDateTime });
    let currRole = this.state.WorkflowRoles.find(p => p.ddName === this.state.contractCurrentRole).ddID;
    this.setState({ isParticipant: (currRole > 0) });
    console.log("Current Role: " + this.state.contractCurrentRole + " ID: " + currRole + " isParticipant: " + this.state.isParticipant ); 
    this.setState({ CurrentRoleID: currRole });                       
    this.getStepOptions();
    this.setState({ currentRole: this.state.contractCurrentRole });
    //console.log("Complete: " + this.state.isContractComplete)
    if (this.state.isContractComplete === false) {
      let currentstepRoles = await CFContract.methods.getCurrentRolesForStep().call({ from: accounts[0] });
      let isContractComplete = await CFContract.methods.isContractComplete().call({ from: accounts[0] });
      //console.log("stepRoles Length: " + currentstepRoles.length + " - Is Complete: " + isContractComplete);
      //console.log("stepRoles: " + JSON.stringify(currentstepRoles));
      if (currentstepRoles.length > 0) {
        let rolesList = '';
        for (ix = 0; ix < currentstepRoles.length; ix++) {
          if (rolesList.length > 0)
            rolesList += ',';
          rolesList += SessionInfo.web3.utils.toUtf8(currentstepRoles[ix]);
        }
        this.setState({ activeRoles: rolesList });
      }
      else {
        this.setState({ activeRoles: 'Contract is Complete' });
        if (this.state.CFProjectStatusID <= 5) {
          this.setState({ CFProjectStatusID: 7 });
          this.setState({ stateHasChanged: true});
        }
      }
      if (isContractComplete === true) {
        this.setState({ isContractComplete });
        if (this.state.CFProjectStatusID <= 5) {
          this.setState({ CFProjectStatus: 7 });
          this.setState({ stateHasChanged: true });
        }
      }
      let roleCancelCnt = await CFContract.methods.getRolesTerminateCount().call({ from: accounts[0] });
      if (roleCancelCnt > 0) {
        let rolesCancel = await CFContract.methods.getRolesTerminate().call({ from: accounts[0] });
        if (rolesCancel.length > 0) {
          let cancelList = '';
          for (ix = 0; ix < rolesCancel.length; ix++) {
            if (cancelList.length > 0)
              cancelList += ',';
            cancelList += SessionInfo.web3.utils.toUtf8(rolesCancel[ix]);
          }
          this.setState({ cancelStatus: roleCancelCnt + " Cancel Votes: " + cancelList });
          if (roleCancelCnt === this.state.roles.length) {
            if (this.state.CFProjectStatusID <= 5) {
              this.setState({ CFProjectStatus: 8 });
              this.setState({ stateHasChanged: true });
            }
          }
        }
      }
      else
        this.setState({ cancelStatus: "None" });
    }
    //console.log(" Is Complete: " + this.state.isContractComplete);
    if (this.state.isContractComplete === true) { // Contract is Complete
      this.setState({ cancelComplete: "Contract is Complete" });
      if (this.state.CFProjectStatusID <= 5) {
        this.setState({ CFProjectStatusID: 7 });
        this.setState({ stateHasChanged: true });
      }
    }
    this.setState({ isCurrOwner: true });  // This should make IPFS controls visible  
    this.setState({ isParticipant: true });   
    this.setState({ isContractLoaded: true });
    if (isLoad === true)
      displayMessage("Ethereum Contract Information Load Complete");
    displayMessage("GREEN");
  } //try
  catch (error) {
    console.log("GetCurrentInfo - Contract Load Failure: " + error);
  } //catch
} // GetCurrentInfo  
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export function getWeb3() {
  if (!SessionInfo.web3) {
    try {
      console.log("--- Get web3 Provider ---");
      SessionInfo.web3 = new Web3(window.web3.currentProvider);
      let web3version = SessionInfo.web3.version;
      console.log("MetaMask is active - Web3 Version: " + web3version);
    }
    catch (error) {
      console.log("Unable to access MetaMask - It must be installed - error: " + error);
    }
  }
}
export async function getEthAccounts() {
  if (!SessionInfo.web3) {
    try {
      console.log("--- Get Eth Account Get");
      if (!window.web3) {
        return [0];
      }
      else {
        console.log("--- Get web3 Provider");
        SessionInfo.web3 = new Web3(window.web3.currentProvider);
        if (!SessionInfo.web3)
          return [0];
        let web3version = SessionInfo.web3.version;
        console.log("Get Accounts - Web3 Version: " + web3version);
      }
    }
    catch (error) {
      console.log("Unable to access MetaMask - It must be installed - error: " + error);
      return [0];
    }
  }
  return await SessionInfo.web3.eth.getAccounts(); // get MetaMastk Accounts  
  //return await SessionInfo.web3.eth.getAccounts(); // get MetaNas
//--------------------------------------------------------------------------------------------------------------------------- k Wallet Addresses  
}
export async function DecodeDocKey() {
  const accounts = await getEthAccounts();
  this.setCurrentParticipantID(accounts[0]);
  try {
    //console.log("Get privateKey");
    let privateKey = await this.getPrivateKey();
    //console.log("privateKey: " + privateKey);
    if (!this.state.DocumentKeyEncrypted) {
      let keyP = await CFContract.methods.getDocumentKey().call({ from: accounts[0] });;
      let docKey = SessionInfo.web3.utils.toUtf8(keyP[0]) + SessionInfo.web3.utils.toUtf8(keyP[1]) + SessionInfo.web3.utils.toUtf8(keyP[2]) + SessionInfo.web3.utils.toUtf8(keyP[3]) + SessionInfo.web3.utils.toUtf8(keyP[4]) + SessionInfo.web3.utils.toUtf8(keyP[5]);
      //displayHex("Document Key: ", docKey);  
      this.state.DocumentKeyEncrypted = docKey;
    }
    privateKey = privateKey.replace(/\s+/g, '\n');
    privateKey = "-----BEGIN RSA PRIVATE KEY-----\n" + privateKey + "\n-----END RSA PRIVATE KEY-----\n";
    console.log("Private Key: " + privateKey);
    if (this.state.DocumentKey === '' || this.state.DocumentKey === this.state.DocumentKeyEncrypted) {
      let toDecrypt = Buffer.from(this.state.DocumentKeyEncrypted, "base64");
      //console.log("encrypted doc Key: " + toDecrypt);
      let decrypted = crypto.privateDecrypt(privateKey, toDecrypt).toString("utf8");
      this.setState({ DocumentKey: decrypted })
      //this.setState({ currentPrivateKey: '' })
      displayMessage("Document Key Decrypted");
    }
    else
      displayMessage("Document Key Already Decrypted");
  }
  catch (error) {
    displayError("Unable to Decode Document Key (" + error + ")");
  }

}  
export async function GetPrivateKey() {
  const accounts = await getEthAccounts();
  this.setCurrentParticipantID(accounts[0]);
  const index = this.state.CFProjectContractParticipants.findIndex(row => row.WalletAddress === accounts[0]);
  //console.log("Participant index: " + index);
  if (index >= 0) {
    let Key = this.state.CFProjectContractParticipants[index].CFParticipantID;
    //let FldNames = ["PrivateKey"];
    //console.log("FldNames: " + JSON.stringify(FldNames));
    //let CD = await GetTableValues(543619, 'CFParticipantID', Key, FldNames);
    let CD = await GetTableRow(543619, Key);
    //console.log("CD: " + JSON.stringify(CD));
    return CD.d.row.PrivateKey;
  }
}
export async function GetPublicKey() {
  const accounts = await getEthAccounts();
  this.setCurrentParticipantID(accounts[0]);
  const index = this.state.CFProjectContractParticipants.findIndex(row => row.WalletAddress === accounts[0]);
  //console.log("Participant index: " + index);
  if (index >= 0) {
    let Key = this.state.CFProjectContractParticipants[index].CFParticipantID;
    //let FldNames = ["PrivateKey"];
    //console.log("FldNames: " + JSON.stringify(FldNames));
    //let CD = await GetTableValues(543619, 'CFParticipantID', Key, FldNames);
    let CD = await GetTableRow(543619, Key);
    //console.log("CD: " + JSON.stringify(CD));
    return CD.d.row.PublicKey;
  }
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------                    
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export async function SMFileLoad(event) { // onDisplayFile for Save to Disk 
  event.stopPropagation();
  event.preventDefault();
  this.setState({ procDefStr: '' });
  const file = event.target.files[0];
  this.setState({ workflowFileName: file.name });
  let reader = new window.FileReader();
  try {
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => this.convertToSMBuffer(reader);
  }
  catch (error) {
    console.log("File Load Error: " + error);
    displayError("Error on State Machine File Read");
  } //catch    
};
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------                      
export async function convertToSMBuffer(reader) {
  // file is converted to a buffer to prepare for JSON Parsing
  const procDefStr = await Buffer.from(reader.result);
  //set this buffer -using es6 syntax
  this.setState({ procDefStr });
  displayMessage("Contract Workflow Loaded from: " + this.state.workflowFileName);
};
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export async function CaptureFile(event) { // onDisplayFile for Save to Disk
  event.stopPropagation();
  event.preventDefault();
  const file = event.target.files[0];
  this.setState({ ContractFileName: file.name });
  let reader = new window.FileReader();
  try {
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => this.convertToBuffer(reader);
  }
  catch (error) {
    console.log("File Read Error: " + error);
    displayError("Error on File Read");
  } //catch   
};
export async function convertToBuffer(reader) {
  //file is converted to a buffer to prepare for uploading to IPFS
  const documentClear = await Buffer.from(reader.result);
  //set this buffer -using es6 syntax
  this.setState({ documentClear });
};
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
// Called from Save File On IPFS
export async function SaveDocumentInContract() {
  try {
    let baseKey = '';  //1234567890123456789012
    const accounts = await getEthAccounts();
    this.setCurrentParticipantID(accounts[0]);
    try {
      baseKey = await CFContract.methods.getKey().call({ from: accounts[0] });
    }
    catch {
      baseKey = this.state.baseKey;
      displayMessage("Fail BaseKey: " + baseKey);
    }
    console.log("save - baseKey : " + baseKey);
    this.setState({ baseKey });
    console.log("Save IPFS Document - Address: " + this.state.IPFSDocumentAddress);
    if (this.state.remindMetaMask === true)
      displayNotify("Click on MetaMask and Confirm the Document Save Transaction");
    let roleKeys = [];
    // Store the Assymetrically encrypted documentKey for each Role using Role Public Key
    let toEncryptDocKey = Buffer.from(this.state.DocumentKey, "utf8"); //this.state.DocumentKey.convertToBuffer();
    console.log("Key to Encrypt: " + toEncryptDocKey);
    for (var ix = 0; ix < this.state.contractRoles.length; ix++) {
      let pubKey = this.state.publicKeys[ix]; //.PublicKey;
      if (!pubKey || pubKey.length < 10) {
        displayError("Invalid Public Key for Role: " + this.state.contractRoles[ix]);
        displayWarning("Document Save Cancelled");
        return;
      }
      console.log("pubKey: " + pubKey + " Lth: " + pubKey.length);
      pubKey = pubKey.replace(/\s+/g, '\n');
      pubKey = "-----BEGIN RSA PUBLIC KEY-----\n" + pubKey + "\n-----END RSA PUBLIC KEY-----\n";
      //console.log("pubKey: " + pubKey + " Lth: " + pubKey.length);
      let encryptedDocKey = crypto.publicEncrypt(pubKey, toEncryptDocKey).toString("base64");
      //console.log("Role: " + this.state.contractRoles[ix] + " Enc Doc Key: " + encryptedDocKey + " lth: " + encryptedDocKey.length);
      let encDocKey1 = encryptedDocKey.substr(0, 15);
      let encDocKey2 = encryptedDocKey.substr(15, 15);
      let encDocKey3 = encryptedDocKey.substr(30, 15);
      let encDocKey4 = encryptedDocKey.substr(45, 15);
      let encDocKey5 = encryptedDocKey.substr(60, 15);
      let encDocKey6 = encryptedDocKey.substr(75);       
      roleKeys.push(SessionInfo.web3.utils.fromAscii(encDocKey1).padEnd(66, '0')); // Six Entries for each Role
      roleKeys.push(SessionInfo.web3.utils.fromAscii(encDocKey2).padEnd(66, '0')); // Convert to Bytes32
      roleKeys.push(SessionInfo.web3.utils.fromAscii(encDocKey3).padEnd(66, '0')); // Convert to Bytes32     
      roleKeys.push(SessionInfo.web3.utils.fromAscii(encDocKey4).padEnd(66, '0')); // Convert to Bytes32
      roleKeys.push(SessionInfo.web3.utils.fromAscii(encDocKey5).padEnd(66, '0')); // Convert to Bytes32
      roleKeys.push(SessionInfo.web3.utils.fromAscii(encDocKey6).padEnd(66, '0')); // Convert to Bytes32
    }
    //let encObj = JSON.parse(sjcl.encrypt(baseKey, this.state.DocumentKey, { iv: "RFmjqpg7jFXAM2rcIdUiRw==", salt: "gVlF5kg5lXAwjK2dGzwQ", ks: 256 }));
    //let key = encObj.ct;
    let encObj = JSON.parse(sjcl.encrypt(baseKey, this.state.DocumentNonce, { iv: "RFmjqpg7jFXAM2rcIdUiRw==", salt: "gVlF5kg5lXAwjK2dGzwQ", ks: 256 }));
    let nonce = encObj.ct;
    encObj = JSON.parse(sjcl.encrypt(baseKey, this.state.DocumentSalt, { iv: "RFmjqpg7jFXAM2rcIdUiRw==", salt: "gVlF5kg5lXAwjK2dGzwQ", ks: 256 }));
    let salt = encObj.ct;
    encObj = JSON.parse(sjcl.encrypt(baseKey, this.state.ContractDocumentType, { iv: "RFmjqpg7jFXAM2rcIdUiRw==", salt: "gVlF5kg5lXAwjK2dGzwQ", ks: 256 }));
    let docType = encObj.ct;
    txHash = undefined;
    console.log("SaveDoc to Contract - roleKeys: " + JSON.stringify(this.state.roleKeys)); 
    await CFContract.methods.setDocumentKeyAddress(roleKeys, nonce, salt, docType, this.state.IPFSDocumentAddress).send({
      from: accounts[0], gas: 2000000, gasPrice: SessionInfo.web3.utils.toWei(this.state.GasPrice.toString(), 'gwei')
    }, (error, transactionHash) => {
      if (error)
        console.log("SaveDoc Error: " + error);  
      txHash = transactionHash;
    });
    displayMessage("Document Address and Keys Saved to Contract");
    if (txHash)        
      await this.addBlockchainTransaction(txHash, "Save Document Hash Address and Keys");
    if (this.state.CFProjectStatusID < 4) {
      this.setState({ CFProjectStatusID: 4 });
      await this.saveRecord();
    }
  } //try
  catch (error) {
    console.log("saveDoc Error: " + error);
  } //catch
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------                     
export async function GetTxReceipt() { // Get Transaction Receipt
  try {
    //this.setState({ blockNumber: "waiting.." });
    //this.setState({ gasUsed: "waiting..." });
    // get Transaction Receipt in console on click
    // See: https://web3js.readthedocs.io/en/1.0/web3-eth.html#gettransactionreceipt
    await SessionInfo.web3.eth.getTransactionReceipt(this.state.transactionHash, (error, txReceipt) => {
      if (error)
        console.log("get Transaction Receipt Error: " + error);
      console.log("Receipt: " + txReceipt);
      this.setState({ txReceipt });
    }); //await for getTransactionReceipt
    await this.setState({ blockNumber: this.state.txReceipt.blockNumber });
    await this.setState({ gasUsed: this.state.txReceipt.gasUsed });
  } //try
  catch (error) {
    console.log("GetTxReceipt Error: " + error);
  } //catch
} //getTxReceipt 
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
// Go to workflow step 
export async function GoToWorkflowStep() { // Go to a new Step
  try {
    //console.log("Go To Next Step: " + this.state.NextStepName);
    //console.log("WorkflowSteps: " + JSON.stringify(this.state.WorkflowSteps));
    let NextStepName = this.state.NextStepName; //this.state.WorkflowSteps.find(c => c.ddID === this.state.NextStepName).ddName 
    let RoleName = '';
    const accounts = await getEthAccounts();
    this.setCurrentParticipantID(accounts[0]);
    for (var ix = 0; ix < this.state.contractRoles.length; ix++) {
      if (this.state.walletAddresses[ix] === accounts[0]) {
        RoleName = this.state.contractRoles[ix];
        this.setState({ contractCurrentRole: this.state.contractRoles[ix] });
        break;
      }
    }
    if (this.state.contractCurrentRole !== this.state.currentRole) {
      this.getStepOptions();
      this.state.currentRole = this.state.contractCurrentRole;
    }                           
    let currRole = this.state.WorkflowRoles.find(p => p.ddName === RoleName).ddID;  
    this.setState({ isParticipant: (currRole > 0) });
    this.setState({ CurrentRoleID: currRole });
    console.log("Current Role: " + this.state.contractCurrentRole + " ID: " + currRole + " isParticipant: " + this.state.isParticipant); 
    if (NextStepName === '' || NextStepName === "Select...")
      displayMessage("Next STEP Not Selected for current Role: " + this.state.contractCurrentRole);
    else {
      if (this.state.IPFSDocumentAddress === '')
        displayError("Can Not Advance STEP Until a Document is Saved");
      else {
        displayMessage("Go To Step: " + NextStepName + " - for Role: " + RoleName); // + " - Account: " + accounts[0]   
        txHash = undefined;
        if (this.state.remindMetaMask === true)
          displayNotify("Click on MetaMask and Confirm the Step Advance Transaction");
        await CFContract.methods.goToNamedStep(SessionInfo.web3.utils.fromAscii(NextStepName).padEnd(66, '0')).send({
          from: accounts[0], gas: 1000000, gasPrice: SessionInfo.web3.utils.toWei(this.state.GasPrice.toString(), 'gwei').toString()
        }, (error, transactionHash) => {
          if (error)
              console.log("goto STEP Error: " + error);  
            txHash = transactionHash;
          });
        if (txHash)                
          await this.addBlockchainTransaction(txHash,"Go to Step: " + NextStepName + " - for Role: " + RoleName);
        await this.getTxReceipt();
        await this.getCurrentInfo(false);
        if (this.state.CFProjectStatusID <= 4)
          this.setState({ CFProjectStatusID : 5}); // Advance Status
      }
    }
  } //try
  catch (error) {
    console.log("GoToWorkflowStep Error: " + error);
  } //catch
} 
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
export async function CancelContract() { // Cancel Contract - Terminate - onCancel = async () => { // on Cancel - Complete Contract
  try {
    const accounts = await getEthAccounts();
    this.setCurrentParticipantID(accounts[0]);
    if (this.state.isContractComplete === false) {
      displayMessage("Vote to terminate the Contract"); 
      await CFContract.methods.voteTerminateContract().send({ from: accounts[0], gas: 1000000, gasPrice: SessionInfo.web3.utils.toWei(this.state.gasPrice.toString(), 'gwei') });
      let roleCancelCnt = await CFContract.methods.getRolesTerminateCount().send({ from: accounts[0] });
      if (roleCancelCnt > 0) {
        //this.setState({ cancelStatus: "Role Cancel Count: " + JSON.stringify(roleCancelCnt) });
        let rolesCancel = await CFContract.methods.getRolesTerminate().call({ from: accounts[0] });
        if (rolesCancel.length > 0) {
          let cancelList = '';
          for (let ix = 0; ix < rolesCancel.length; ix++) {
            if (cancelList.length > 0)
              cancelList += ',';
            cancelList += SessionInfo.web3.utils.toUtf8(rolesCancel[ix]);
          }
          this.setState({ cancelStatus: roleCancelCnt + " Cancel Votes: " + cancelList });
          if (roleCancelCnt === this.state.roles.length) {
            if (this.state.CFProjectStatusID <= 5) {
              this.setState({ CFProjectStatus: 8 });
              this.setState({ stateHasChanged: true });
            }
          }
        }
      }
      else
        this.setState({ cancelStatus: "Select..." });
    }
    else { // Contract is Complete - Process the completion
      if (this.state.ownerAddress === accounts[0]) {
        displayMessage("Complete the Contract");
        await CFContract.methods.completeContract().send({ from: accounts[0], gas: 1000000, gasPrice: SessionInfo.web3.utils.toWei(this.state.GasPrice.toString(), 'gwei') });
        await this.getCurrentInfo(false);
        displayMessage("Contract Finished");
      }
      else
        this.displayErr("Only the Owner can Complete the Contract");
    }
  } //try
  catch (error) {
    console.log("CancelContract Error: " + error);
  } //catch
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------   
export async function SaveToIPFS() { // save File on IPFS - onSaveToIPFS = async (event) => {  // Save File to IPFS
  //event.preventDefault();
  const accounts = await getEthAccounts(); //bring in user's metamask account walletAddresses 
  this.setCurrentParticipantID(accounts[0]);
  if (this.state.ownerBalance === 0)
    displayError('The contract must be Initialized First - Press: Init Contract Info');
  else if (accounts[0] !== this.state.ownerAddress) {
    console.log("Account0: " + accounts[0] + " ownerAddress: " + this.state.ownerAddress + " Current Role: " + this.state.currentRole + " isCurrOwner: " + this.state.isCurrOwner);
    displayError('Only the Contract Owner can Save a Document');
  }
  else {
    let isInitial = await CFContract.methods.isStepInitial.call({ from: accounts[0] });
    if (this.state.isSavingDocument) {
      displayMessage("Document Save in Progress");
      return;
    }
    else if (isInitial === false)
      displayError("Can only Save a File when in Initial State");
    else if (!this.state.documentClear)
      displayError('A file must be selected');
    else if (!this.state.DocumentKey)
      displayError('A Document Key Must be Provided');
    else if (this.state.DocumentKey.length < 8)
      displayError('The Document Key Must be at least 8 Characters');
    else {
      this.state.isSavingDocument = true;
      displayMessage('Saving Document');                
      let ownerAddress = await CFContract.methods.getOwnerAddress().call({ from: accounts[0] });
      if (ownerAddress === '') {
        this.setState({ ownerAddress: accounts[0] });
        this.state.ownerAddress = accounts[0];
      }
      else
        await this.setState({ ownerAddress });
      this.state.walletAddresses[0] = this.state.ownerAddress;
      const key = sjcl.random.randomWords(4);
      let saltStr = sjcl.codec.base64.fromBits(key);
      //console.log("saltStr:  " + saltStr);
      let lth = saltStr.length;
      //console.log("saltStr lth:  " + lth);
      if (lth > 16)
        saltStr = saltStr.toString().substring(0, 16);
      //console.log("saltStr: " + saltStr);
      //console.log("document length: " + this.state.documentClear.length);
      let bytes = new Uint8Array(this.state.documentClear); // Fr-1
      //console.log("bytes length: " + bytes.length);
      let bits = toBitArrayCodec(bytes); // Fr-2
      //console.log("bits length: " + bits.length);
      let base64bits = sjcl.codec.base64.fromBits(bits); // Fr-3 - Base64 Encode Document  
      //console.log("base64bits - length: " + base64bits.length);
      //this.saveTextFile(base64bits, 'before.txt');
      //-----------------------------------------------------------------------------------
      // Test decrypt and decode - Keep 
      //let docBuf = sjcl.codec.base64.toBits(base64bits); // Reduce size of document  
      //console.log("docBuf-3: " + docBuf); // Bits   
      //let byteBuf = this.fromBitArrayCodec(docBuf);
      //console.log("docBuf-2: " + byteBuf);
      //let byteBuf2 = Uint8Array.from(byteBuf);       
      //docBuf = new TextDecoder("utf-8").decode(byteBuf2.buffer);
      //console.log("docBuf-1: " + docBuf);
      //-----------------------------------------------------------------------------------
      //let enObj = sjcl.encrypt(this.state.DocumentKey, base64bits, { count: 2048, salt: saltStr, ks: 256 }); 
      //console.log("before encrypt dockey Length: " + base64bits.length); // Bits  
      let encDocumentStr = sjcl.encrypt(this.state.DocumentKey, base64bits, { salt: saltStr, ks: 256 }); //, { count: 2048, salt: saltStr, ks: 256 }); 
      //console.log("encrypted document Length: " + encDocumentStr.length); // Bits  
      //--------------------------------------------------------  
      //let salt = "jrEp464459dE2Tyls="; //                                                                                         
      //var out = sjcl.encrypt("password", "The quick brown fox jumps over the lazy dog", { count: 2048, salt: salt, ks: 256 });
      //console.log("Encrypt Object: " + enObj);
      //let encObj = JSON.parse(out);
      //displayMessage("encrypt: " + encObj.ct);
      //out = sjcl.decrypt("password", out);
      //displayMessage("original: " + out);
      //------------------------------------------------------------
      let encObj = JSON.parse(encDocumentStr);
      let encBuffer = encObj.ct;
      //console.log("Encrypted: " + buffer);
      //console.log("Plain: " + this.state.buffer);
      //out = sjcl.decrypt("password", this.state.DocumentKey);
      //displayMessage("encrypted: " + out);

      this.setState({ DocumentNonce: encObj.iv }); // Nonce
      this.setState({ DocumentSalt: saltStr });      // Salt 
      let ContractDocumentType = GetFileExtension(this.state.ContractFileName);
      this.setState({ ContractDocumentType });
      //encObj.ct = '';
      encDocumentStr = JSON.stringify(encObj);
      this.setState({ ipfsEncDocument: encDocumentStr });
      //console.log("Encrypt Str: " + encObjStr);
      //---------------------------------------------------

      //save document to IPFS,return its hash#, and set hash# to state
      //https://github.com/ipfs/interface-ipfs-core/blob/master/SPEC/FILES.md#add  
      await ipfs.add(Buffer.from(encBuffer), (ipfsError, IPFSDocumentAddress) => {
        if (ipfsError)
          console.log("IPFS Error: " + ipfsError);
        console.log("IPFS Doc Address: " + IPFSDocumentAddress);
        this.setState({ IPFSDocumentAddress: IPFSDocumentAddress[0].hash });
        this.saveDocumentInContract(); // Save the Document and supporting information in the Contract
        // Init Contract will also save the document hash (address in IPFS)
        if (this.state.CFProjectStatusID >= 4)
          displayMessage("Encrypted Document (" + ContractDocumentType + ") saved on IPFS and in the Contract");
      }); //await ipfs.add 
      if (this.state.CFProjectStatusID >= 4 && ContractDocumentType === 'pdf') {
        // Display the file
        var blob = new Blob([this.state.documentClear], { type: 'application/pdf' }); //"text/plain;charset=utf-8" });
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.download = "loadFile.pdf";
        a.href = url;
        a.click();
      }
      this.state.isSavingDocument = true;
    }
  }
}; //onSaveToIPFS  
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
// Load file into the Buffer   
export async function LoadFile() { // Load File from IPFS   - onLoadFile = async () => { // Load File from IPFS  
  if (this.state.CFProjectStatusID < 4) {
    if (this.state.ProjectInstanceContractAddress !== '' && this.state.IPFSDocumentAddress !== '') {
      this.setState({ CFProjectStatusID: 4 });
      await this.saveRecord();
    } else {
      displayError('The Contract must be Initialized - and a Document Saved');
      return;
    }
  }
  //if (!this.state.DocumentKeyEncrypted)
  await this.decodeDocKey();
  if (this.state.DocumentKey === '')
    displayError('The Document Key Must be Entered');
  else if (this.state.DocumentKey.length < 8)
    displayError('The Document Key Must be at Least 8 and No More Than 22 Characters');
  else {
    //let DocKey = this.state.DocumentKey;
    //displayMessage('Loading File from IPFS'); // + this.state.buffer);
    await ipfs.files.get(this.state.IPFSDocumentAddress, function (error, files) {
      if (error)
        displayError("Unable to Load File: " + error);
      else if (!files)
        displayError("Unable to Load File");
      else {
        files.forEach( async(file) => {
          //console.log("Path: " + file.path);
          //console.log("File content >> ", file.content.toString('utf8'));
          //console.log("DocKey >> ", DocKey);
          this.state.documentEncrypt = file.content;
          //this.decryptDocument();
          displayMessage('File Loaded from IPFS');
          displayMessage("GREEN");  
          await this.displayFile();
        });
      }
    }.bind(this));
    //displayMessage('After IPFS fetch'); // + this.state.buffer); 
    //console.log("File content >> ", this.state.documentBuffer); 
  }
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
// Display File - See captureFile for load  
export async function DisplayFile() { // Display IPFS File   - onDisplayFile = async () => {
  //console.log("DisplayFile - encrypt Length: " + this.state.documentEncrypt.length); // Bits  
  let decryptBuff = this.decryptDocument();
  //console.log("after decrypt Length: " + decryptBuff.length); // Bits   
  let bitBuf = sjcl.codec.base64.toBits(decryptBuff); // To-3  Reduce size of document  
  //console.log("bits Length: " + bitBuf.length); // Bits   
  let byteBuf = fromBitArrayCodec(bitBuf);
  //console.log("bytes Length: " + byteBuf.length);

  let byteBuf2 = Uint8Array.from(byteBuf);
  //this.saveTextFile(byteBuf2, 'after.txt');
  //console.log("byteBuf2 Lth: " + byteBuf2.length + " buffer lth: " + byteBuf2.buffer.length);
  //let docBuf = new TextDecoder("utf-8").decode(byteBuf2.buffer); // Final Step
  this.state.documentClear = byteBuf2;
  //console.log("document length: " + this.state.documentClear.length);  
  //console.log("docBuf-1 length: " + docBuf.length);
  //console.log("docBuf buffer: " + docBuf.buffer);
  let blob, url, a;
  if (this.state.ContractDocumentType === 'pdf') {
    // Display the PDF file
    blob = new Blob([byteBuf2], { type: 'application/pdf' }); //"text/plain;charset=utf-8" });
    url = URL.createObjectURL(blob);
    //console.log("url: " + url);
    a = document.createElement('a');
    a.download = "ContractFile.pdf";
    a.href = url;
    a.click();
  }
  else if (this.state.ContractDocumentType === 'txt') {
    // Download the Text file
    blob = new Blob([byteBuf2], { type: "text/plain;charset=utf-8" });
    url = URL.createObjectURL(blob);
    //console.log("url: " + url);
    a = document.createElement('a');
    a.download = "ContractFile.txt";
    a.href = url;
    a.click();
  }
  else { // Download the file  
    blob = new Blob([byteBuf2], { type: 'application' }); //"text/plain;charset=utf-8" });
    url = URL.createObjectURL(blob);
    //console.log("url: " + url);
    a = document.createElement('a');
    a.download = "ContractFile." + this.state.ContractDocumentType;
    a.href = url;
    a.click();
  }
  displayMessage("ContractFile." + this.state.ContractDocumentType + " Downloaded");
}
export function DecryptDocument() {
  let encObj = JSON.parse('{ "iv": "", "v": 1, "iter": 10000, "ks": 256, "ts": 64, "mode": "ccm", "adata": "", "cipher": "aes", "salt": "", "ct": "" }');
  //encObj.ct = this.state.documentBuffer;
  //let docBuffer = sjcl.decrypt(this.state.DocumentKey, JSON.stringify(encObj)); // this.state.documentBuffer);
  //console.log("Decrypted content >> ", docBuffer); 
  //console.log("encObj >> ", this.state.ipfsEncDocument);
  //encObj = JSON.parse(this.state.ipfsEncDocument);
  if (!this.state.DocumentKey)
    this.DecodeDocKey();
  //console.log("Document Key: " + this.state.DocumentKey);
  //console.log("Nonce: " + this.state.DocumentNonce);
  //console.log("Salt: " + this.state.DocumentSalt);
  //console.log("documentBuffer type: " + typeof this.state.documentEncrypt.toString());
  //console.log("encObj ct type: " + typeof encObj.ct);
  encObj.ct = this.state.documentEncrypt.toString();
  encObj.iv = this.state.DocumentNonce.toString();
  encObj.salt = this.state.DocumentSalt.toString();
  let encObjStr = JSON.stringify(encObj);
  //console.log("encObjStr: " + encObjStr);
  let docBuffer = sjcl.decrypt(this.state.DocumentKey, encObjStr);
  //console.log("Decrypted content >> ", docBuffer);
  //console.log("Decrypted content length: " + docBuffer.length);
  //this.state.documentClear = docBuffer;
  return docBuffer;
}