//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// AppMain - The main application page (Home Page) for the ContractFlow Application
//              Version 1.005 - May 14, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { } from 'react';                   
import './App.css';
import './custom.scss';                                
//import '@progress/kendo-theme-default/dist/all.css';
import { Link } from 'react-router-dom'
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js'; 
import { SessionInfo } from './App';                    

class AppMain extends React.Component {
  constructor(props) {
    super(props);  
    SessionInfo.History = props.history;
    //console.log("AppMain SessionInfo History: " + JSON.stringify(SessionInfo.History));  
    if (SessionInfo.session === '')
      props.history.push("/");
  }
  state = {
    searchValue: '',
    MessagesValue: '',
    currentTime: '',
    style: { width: 1000, height: 1000 }
  };
  refreshCount = 6;
  async componentDidMount() {
    const style = { width: 0, height: 0, visible: false };
    this.setState({ style });
    //console.log("AppMain Production Mounted");
    //await TestREST();
    //console.log("After Test REST");
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    //let value = this.context; 
  }

  render() {
    return (
      <div>
        <div ref="snav" className="overlay" style={this.state.style}>
          <img src={require("./images/loading.gif")} alt="" style={this.state.style} />
        </div>
        <div id="appMain" className="pageMain">
          <PageHeader L1='Home' Select='0' Title='Contract Flow' />
          <div id="mainCntr">
            <div id="contentCntr">
              <div id="topCntr">
                <div id="topCntrinner">
                  {/*  / WELCOME BOX \ */}
                  <div className="welcomeBox">
                    <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Technology Leadership</h2>
                    <p>Omecron delivers omnichannel customer engagement software products, tools, apps, and services. We provide solutions to enable retail success.<br />
                      Looking for a better way to facilitate trust based workflows and transactions, Omecron has turned to the Blockchain to provide the immutable record of transaction history that companies and government agencies are looking for.
                    <br /><br /><b>Unify - Engage - Optimize</b></p>
                    <a href="https://Omecron.com/" target="_blank" rel="noopener noreferrer">Learn More...</a>
                  </div>
                  <div className="whatBox">
                    <h2>What is Contract Flow?</h2>
                    <p>Contract Flow from Omecron, allows anyone to start using the Ethereum Blockchain now, without any investment, to see how the Blockchain can be used to expedite and track multi-party business transactions and workflows.<br />
                       Contract Flow uses a blockchain program to manage and track the workflow. Find out what the blockchain can do for you and your business. How it provides transparency by recording and allowing verification of any type of business transaction.</p>
                    <a href="/AboutPage">More Info...</a>
                  </div>
                  <div className="serviceBox">
                    <h2>How to use Contract Flow</h2>
                    <p>Use our step by step instructions on how to register with Contract Flow - and then how to sign up for a project and use our unique Blockchain based workflow engine.<br />
                       You'll get instructions on how to set up your Ethereum wallet and your Ethereum account - and then how you can use that information to register with Contract Flow. And then we'll show you how to sign-up for one of the open projects available for testing so that you can create your own Blockchain program.</p>
                    <Link to="/AboutPage">More Info...</Link>
                  </div>
                </div>
              </div>

              <div id="bottomCntr" style={{ height: '1300px' }}>
                <div className="leftC">
                  <div className="getBox">
                    <img src={require("./images/sub/get_icon.png")} alt="" />
                    <h2>Get Started with Contract Flow</h2>
                    <p>If you don't have one yet, the first thing that you will need is an Ethereum Rinkeby Account and MetaMask installed inorder to gain access to it. Check out the MetaMask link on the right side of this page to see how to install it.</p>
                  </div>
                  <div className="ourblogBox">
                    <div className="top">
                      <div className="bottom">

                        <h2>Setup Your Profile</h2>
                        <p className="last"><span>You will need an Ethereum Account</span>
                          Once you have MetaMask installed, you can create an Ethereum Account by clicking on the circle icon a the top right of the MetaMask drop down and click on '+ Create Account'
							            <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">&nbsp;&nbsp;Go to MetaMask...</a></p>

                        <p><span>Now you can register</span>
                          Once you have your Rinkeby account and MetaMask installed, you can register with Contract Flow. With your account set up, you can enter your own profile information and you'll be ready to start using Contract Flow. It's easy, just a bare minumum of information is required.
							            <Link to="/Register">&nbsp;&nbsp;Register...</Link></p>

                        <p><span>Once you have registered</span>
                          Sign on any time to go and view your active Contracts and Projects. Note, you can Log On or Register at any time in the Contract Flow Application by clicking on the Sign On Icon<img src={require("./images/Lock.png")} alt="" className="loginImage" /> at the top of this page, on the right hand side.
                        <Link to="/Login">&nbsp;&nbsp;Log on...</Link></p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="rightC">
                  <div className="companyBox">
                    <div className="companyBox1">
                      <h2 className="icon1">What is Ethereum?</h2>
                      <p>At its simplest, Ethereum is an open software platform based on blockchain technology that enables developers to build and deploy decentralized applications. A Smart contract is a program that runs on Ethereum that facilitates the exchange of money, content, property, shares, or anything of value. It can also process business transactions and record each transaction on the blockchain where anyone can view it - but no one can change it. That is a the value of the blockchain, a permanent record of transactions.</p>
                      <a href="https://www.ethereum.org/" target="_blank" rel="noopener noreferrer">Ethereum...</a>
                    </div>

                    <div className="companyBox2">
                      <h2 className="icon2">Smart Contracts</h2>
                      <p>A Smart Contract is a software program which is executed on the Blockchain virtual machine.<br/>The Smart Contract is a program which can interact with the Blockchain based on instructions passed to it from an outside agent - normally a web based application. The contract contains a set of rules under which the parties to that Smart Contract agree to interact with each other. All Smart Contracts activities which change the state or condition of the contract are recorded as transactions on the Blockchain.</p>
                      <a href="https://en.wikipedia.org/wiki/Smart_contract" target="_blank" rel="noopener noreferrer">Smart Contracts...</a>
                    </div>
                  </div>

                  <div className="companyBox">
                    <div className="companyBox3">
                      <h2 className="icon3">You'll Need MetaMask</h2>
                      <p>MetaMask provides a secure identity vault, allowing you to manage your identities on different sites and sign blockchain transactions right in your browser.<br/>You'll need MetaMask to securely verify your Ethereum transactions using your secure wallet</p>
                      <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">Go to MetaMask...</a>
                    </div>

                    <div className="companyBox4">
                      <h2 className="icon4">Contract Flow Projects</h2>
                      <p>Using a Contract Flow Project, any business or trading group can set up and use a blockchain verified business workflow. All transactions are confirmed by project participants and the transactions are recorded on the Ethereum blockchain for all to see. Verification of transactions and a full audit trail related to any business activity is avaialble for anyone to see on the blockchain</p>
                      <a href="/AboutPage">More Info...</a>
                    </div>
                  </div>

                  <div className="companyBox">
                    <div className="companyBox5">
                      <h2 className="icon5">Gas Required</h2>
                      <p>Ethereum Gas is the internal pricing for running a transaction or contract in Ethereum. Every transaction or smart contract executed on the Ethereum blockchain requires gas.<br/>Gas is a small fraction of an Ethereum token, and is used by the contract to pay the processing costs for running the Contract.</p>
                      <a href="https://blockgeeks.com/guides/ethereum-gas/" target="_blank" rel="noopener noreferrer">What is Gas...</a>
                    </div>

                    <div className="companyBox6">
                      <h2 className="icon6">InterPlanetary File System</h2>
                      <p>InterPlanetary File System is a protocol and network designed to create a content-addressable, peer-to-peer method of storing and sharing hypermedia in a distributed file system. Similar to a torrent, IPFS allows users to not only receive but host content. As opposed to a centrally located server IPFS is built around a decentralized system of user-operators who hold a portion of the overall data, creating a resilient system of file storage and sharing.</p>
                      <a href="https://en.wikipedia.org/wiki/InterPlanetary_File_System" target="_blank" rel="noopener noreferrer">IPFS...</a>
                    </div>
                  </div>  

                  <div className="companyBox">
                    <div className="companyBox5">
                      <h2 className="icon7">Business Workflow</h2>
                      <p>A workflow consists of an orchestrated and repeatable pattern of activity, enabled by the systematic organization of resources into processes that transform materials, provide services, or process information. It can be depicted as a sequence of operations, the work of a person or group, the work of an organization of staff, or one or more simple or complex mechanisms.</p>
                      <a href="https://en.wikipedia.org/wiki/Workflow" target="_blank" rel="noopener noreferrer">Workflows...</a>
                    </div>

                    <div className="companyBox6">
                      <h2 className="icon8">Supply Chain Management</h2>
                      <p>Supply chain management (SCM) is the active management of supply chain activities to maximize customer value and achieve a sustainable competitive advantage. It represents a conscious effort by the supply chain firms to develop and run supply chains in the most effective and efficient ways possible. <br />Supply chain activities cover everything from product development, sourcing, production, and logistics, as well as the information systems needed to coordinate these activities..</p>
                      <a href="https://en.wikipedia.org/wiki/Supply-chain_management" target="_blank" rel="noopener noreferrer">Supply Chain...</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <PageFooter L1='Home' />
        </div >
      </div >
    );
  }
}

export default AppMain