//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// omAPI Register - Contract Flow Participant  -   Version 1.005 - May 17, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';  
import { SessionInfo } from './App.js';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import { UserRegister, LogOff, GetTableFilter, displayMessage, displayWarning, displayError } from './CommonCode.js';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});

const styles = theme => ({
  palette: {
    primary: blue,
    secondary: blue,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    primary: blue,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: '#0288d1', //theme.palette.secondary.main,
    primary: blue,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: 0, /*theme.spacing.unit * 1, */
  },
});
            
function Register(props) {
  const { classes } = props;
  var FirstName = '';
  var LastName = '';
  var Email = '';
  var PW = '';
  var ConfirmPW = '';
  var message = '';
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (PW !== ConfirmPW) {
      message = "Passwords must match";
      displayWarning("Register: " + message);
    }
    else if (PW.length < 8) {
      displayWarning("Register: Password must be at least 8 characters"); 
    }
    else if (SessionInfo.registerCounter > 0)
      displayWarning("Register: You must wait to Register");
    else {
      console.log("Call Register");
      SessionInfo.registerCounter = 10;
      let m = await UserRegister(FirstName, LastName, Email, PW, ConfirmPW); // Register in CommonCode
      console.log("Return from UserRegister - m: " + m);
      if (m === 'ok') {
        displayMessage("Registration Successful - ID: " + SessionInfo.userID);  
        if (SessionInfo.userID) {
          let CD = await GetTableFilter(543619, "OMUserID=" + SessionInfo.userID); // Get Participant ID 
          if (!CD.d.rows || !CD.d.rows.length) {
            displayError("Unable to Find Participant for the current User: " + SessionInfo.logonName);
            await LogOff();
            if (SessionInfo.History)
              SessionInfo.History.push("/AppMain"); // Note - that the componentWillUnmount will execute for the current page 
          }
          else {
            //console.log("Get User CD: " + JSON.stringify(CD));
            SessionInfo.CFParticipantID = CD.d.rows[0]["CFParticipantID"];
            SessionInfo.SelectedParticipantID = CD.d.rows[0]["CFParticipantID"];
            props.history.push("/ParticipantProfile");
          }
        }
      }
      else if (m === 'pa') {
        displayWarning("Register: Passwords must match");       
      }
      else if (m)
        displayError("Register Error: " + m);
      else
        displayError("Register Error");
    }
  }
  const chgFirstName = (event) => {
    event.preventDefault();
    FirstName = event.target.value;
  }
  const chgLastName = (event) => {
    event.preventDefault();
    LastName = event.target.value;
  }
  const chgEmail = (event) => {
    event.preventDefault();
    Email = event.target.value;
  }
  const chgPW = (event) => {
    event.preventDefault();
    PW = event.target.value;
  }
  const chgConfirmPW = (event) => {
    event.preventDefault();
    ConfirmPW = event.target.value;
  }
  const gotoLogin = (event) => {
    event.preventDefault();
    props.history.push("/Login")
    //click(ToRegister);
  }

  return (
    <div id="Register" className="pageMain">
      <PageHeader L1='Home' Select='0' Title='Contract Flow - Register' IsLogon='y' />
      <main className={classes.main}>
        <MuiThemeProvider theme={theme}>                     
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Contract Flow Register</Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="firstName">First Name</InputLabel>
                <Input id="firstName" name="firstName" autoComplete="firstName" autoFocus onChange={chgFirstName} />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <Input id="lastName" name="lastName" autoComplete="lastName" onChange={chgLastName} />
              </FormControl>
              <br />
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input id="email" name="email" autoComplete="email" onChange={chgEmail} />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" autoComplete="current-password" onChange={chgPW} />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Confirm Password</InputLabel>
                <Input name="confirmPassword" type="password" id="confirmPassword" autoComplete="current-password" onChange={chgConfirmPW} />
              </FormControl>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={handleSubmit}>Register</Button>
              <Typography component="h6" variant="h6"><br />Already Registered? Go to Sign In</Typography>
              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={gotoLogin}>Sign In</Button>
            </form>
          </Paper>
        </MuiThemeProvider>
      </main>
      <PageFooter L1='Home' />
    </div>
  );
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Register);