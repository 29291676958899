//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// WorkflowDefinition - Describe the Workflow Roles, Steps and Transitions
//              Version 1.008 - June 11, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import 'whatwg-fetch';                                    
import './App.css';
import './custom.scss';                                
//import '@progress/kendo-theme-default/dist/all.css';  
import { Grid as TGrid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-react-layout';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
import { SessionInfo } from './App';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import EditGridCommandCell from './EditGridCommandCell.jsx';  // edit grid
import accessOMAPI, { GetTableRow, GetTableData, GetTableFilter, GetTableSearch, UpdateRow, DeleteRow, SaveRow, displayMessage, displayError, GetDropdownData, GetDependentDropdownData, displayWarning, notify } from './CommonCode.js';  
import FormControl from '@material-ui/core/FormControl'; 
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';    
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
//import red from '@material-ui/core/colors/red';
// Set State synchronously: this.setState((prevState, _) => ({ counter: prevState.counter + 1 }))
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});
            
class WorkflowDescription extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    if (SessionInfo.session === '')
      props.history.push("/AppMain");
    if (SessionInfo.WorkflowDescription) { // Data saved for this session
      //console.log("contractAccess: " + JSON.stringify(SessionInfo.contractAccess));
      this.state = SessionInfo.WorkflowDescription;
      this.loadExistingPage = true;
    }
    else {
      this.loadExistingPage = false;
      this.searchCurrent();
    }
    //if (props.history !== undefined)
    //  console.log("ProjectDirectory HISTORY!!!");        
    //------------- Edit Grid ------------------------------
    this.enterInsertRole = this.enterInsertRole.bind(this);
    this.itemChangeRole = this.itemChangeRole.bind(this);
    const enterEditRole = this.enterEditRole.bind(this);
    const saveRole = this.saveRole.bind(this);
    const cancelRole = this.cancelRole.bind(this);
    const removeRole = this.removeRole.bind(this);
    this.RoleCommandCell = EditGridCommandCell(enterEditRole, removeRole, saveRole, cancelRole, "inEdit", "RoleName", "CFWorkflowRoleID");
    this.enterInsertStep = this.enterInsertStep.bind(this);
    this.itemChangeStep = this.itemChangeStep.bind(this);
    const enterEditStep = this.enterEditStep.bind(this);
    const saveStep = this.saveStep.bind(this);
    const cancelStep = this.cancelStep.bind(this);
    const removeStep = this.removeStep.bind(this);
    this.StepCommandCell = EditGridCommandCell(enterEditStep, removeStep, saveStep, cancelStep, "inEdit", "StepName", "CFWorkflowStepID");
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    CFWorkflowID: 0,
    ProjectTypeID: 0,
    CFWorkflowOwnerID: 0,
    ApprovalID: 0,
    RoleCount: 0,
    StepCount: 0,
    SortOrder: 0,
    CFWorkflowDiagram: 0,
    IsPrivate: false,
    IsValid: false,
    CFWorkflowRoleID: 0,
    CFWorkflowStepID: 0,
    CFControlRoleID: 0,
    TransitionTo1ID: 0,
    TransitionTo2ID: 0,
    TransitionTo3ID: 0,
    TransitionTo4ID: 0,

    string: '',
    WorkflowName: '',
    WorkflowSummary: '',
    WorkflowDescription: '',
    Comments: '',
    StepName: '',
    StepDescription: '',

    selectedTab: 0,
    stateHasChanged: false,
    sortSteps: [{ field: 'SortOrder', dir: 'asc' }],
    CFWorkflows: [],
    workflowsSkip: 0,
    workflowsTake: 8,
    saveCFWorkflows: [],
    CFWorkflowRoles: [],     // Roles Screen Data
    saveCFWorkflowRoles: [],
    CFWorkflowSteps: [],     // Workflow Steps Data
    saveCFWorkflowSteps: [],
    ProjectTypes: [],
    WorkflowOwners: [],
    WorkflowRoles: [],      // Roles DropDown Data
    WorkflowSteps: [],      // Steps DropDown Data
    panes: [{ collapsible: true }, { size: '70%', min: '20px', collapsible: false }], // Note {}, removed
    nestedPanes: [{ size: '45%' }, { collapsible: true}]  // Note {}, removed
  };
  loadExistingPage = false;
  rolesUpdated = false;

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      this.props.history.push("/AppMain");
    else {
      SessionInfo.currentPage = "WorkdflowDescription";
      SessionInfo.currSaveFunc = this.saveRecord;
      SessionInfo.currDeleteFunc = this.deleteRecord;
      SessionInfo.currClearFunc = this.clearRecord;
      SessionInfo.searchFunc = this.searchCurrent;
      if (this.loadExistingPage == true)
        this.forceUpdate();
      else {
        if (this.state.ProjectTypes.length <= 1) {
          let CD = await GetDropdownData(492545);
          this.setState({ ProjectTypes: CD.d });
          //this.setState({ ProjectTypeID: this.AssignDDEntry(this.state.ProjectTypeID, this.state.ProjectTypes) });
        }
        if (this.state.WorkflowOwners.length <= 1) {
          let CD = await GetDropdownData(543619);
          this.setState({ WorkflowOwners: CD.d });
        }
        //await this.getWorkflowRoles();
        //await this.getWorkflowSteps();
        const CD = await accessOMAPI(1, 0, 543767, 1);
        //console.log("WorkflowDescription Fetch CD: " + JSON.stringify(CD));
        if (CD.x.o === 0)
          displayError("Access to OM Has Failed");
        else if (CD.x.o < 9500) {
          try {
            const CFWorkflows = CD.d.rows;
            //console.log("WorkFlow data: " + JSON.stringify(CFWorkflows));
            this.state.saveCFWorkflows = CFWorkflows;
            this.setState({ CFWorkflows });
            //console.log("Fetch Accounts successful");
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.WorkflowDescription = this.state;
  }            
  getWorkflowRoles = async () => {
    //console.log("Get RoleDD - CFWorkflowID: " + this.state.CFWorkflowID);
    let CD = await GetDependentDropdownData(543821, this.state.CFWorkflowID, 543769);
    //console.log("getWorkflowRoles: " + JSON.stringify(CD));
    this.state.WorkflowRoles = CD.d;
  }
  getWorkflowSteps = async () => {
    //console.log("getWorkflowSteps - WFID: " + this.state.CFWorkflowID);
    let CD = await GetDependentDropdownData(543800, this.state.CFWorkflowID, 543769);
    //console.log("getWorkflowSteps: " + JSON.stringify(CD));
    this.state.WorkflowSteps = CD.d;
  }

  onLayoutChange = (updatedState) => {
    this.setState({ panes: updatedState });
  }
  onNestedLayoutChange = (updatedState) => {
    this.setState({ nestedPanes: updatedState });
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  searchParticipants = async (search) => {
    displayMessage("Search for Participants");
    await this.getParticipantRecords(search);
  }
  saveRecord = async () => {
    //console.log("Save WorkflowDescription - state: " + JSON.stringify(this.state));
    let copyState = Object.assign({}, this.state);
    let type = 0;
    for (var prop in copyState) {
      if (prop === "string") {
        type = 1;
        copyState[prop] = undefined;
      }
      else if (prop === "selectedTab")
        type = 2;
      else if (type === 0) {          
      }
      if (type === 2) {
        copyState[prop] = undefined;
      }
    }
    if (this.state.CFWorkflowID > 0 && this.state.CFWorkflowOwnerID > 0 && this.state.CFWorkflowOwnerID !== SessionInfo.CFParticipantID)
      displayWarning("Only the Workflow Owner can update the Workflow");
    else {
      //console.log("WorkflowID: " + JSON.stringify(copyState));
      await SaveRow(543767, copyState, copyState.CFWorkflowID, "CFWorkflowID"); // Save to OM 
      //await this.getRecords();
      this.setState({ stateHasChanged: false });
    }
  }
  deleteRecord = async () => {                                            
    await DeleteRow(543767, this.state.CFWorkflowID);
    this.setState({ stateHasChanged: false });
  }
  clearRecord = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //console.log("Clear Screen - state: " + JSON.stringify(this.state)); 
    let type = 0;
    for (var prop in this.state) {
      if (prop === "selectedTab")
        break;
      else if (prop === "string")
        type = 1;
      else if (type === 0) {
        let obj = {};
        if (prop.indexOf("Date") >= 0)
          obj[prop] = new Date();
        else
          obj[prop] = 0;
        this.setState(obj);
      }
      else if (type === 1) {
        let obj = {};
        obj[prop] = '';
        this.setState(obj);
      }
    }
    this.setState({ CFWorkflows: [] });
    this.setState({ stateHasChanged: false });
  }
  getWFRoles = async () => {
    let CD = await GetTableFilter(543821, "*CFWorkFlowID=" + this.state.CFWorkflowID); // Roles for Workflow  
    //console.log("after Roles Get: " + JSON.stringify(CD));
    if (CD.x.o === 0)
      displayError("Access to OM for Get Workflow Roles Has Failed in " + SessionInfo.currentPage);
    else if (CD.x.o < 9500) {
      try {
        this.setState({ CFWorkflowRoles: CD.d.rows });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  getWFSteps = async () => {
    let CD = await GetTableFilter(543800, "*CFWorkFlowID=" + this.state.CFWorkflowID); // Steps for Workflow  
    //console.log("Get WorkflowSteps: " + JSON.stringify(CD));
    if (CD.x.o === 0)
      displayError("Access to OM for Steps Has Failed in " + SessionInfo.currentPage);
    else if (CD.x.o < 9500) {
      try {
        this.setState({ CFWorkflowSteps: CD.d.rows });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  searchCurrent = async (search) => {
    await this.getWorkflowRecords(search);
  }
  getWorkflowRecords = async (search) => { // Get the available Participants
    let CD;
    if (!search)
      CD = await GetTableData(543767); // Get Participants attached to this Project
    else
      CD = await GetTableSearch(543767, search); // Get Participants attached to this Project
    if (CD.x.o === 0)
      displayError("Access to OM Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
    else if (CD.x.o < 9500) {
      try {
        const CFWorkflows = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
        this.setState({ CFWorkflows });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  getRecord = async (Key) => {
    let CD = await GetTableRow(543767, Key); // Get Workflow   
    //console.log("after Get: " + JSON.stringify(CD.d));
    this.setState({ CFWorkflowID: Key });
    for (var prop in CD.d.row) {
      if (prop in this.state) {
        //console.log("Set " + prop + ": " + CD.d.row[prop]); 
        let obj = {};
        obj[prop] = CD.d.row[prop];
        if (prop.indexOf("Date") >= 0) {
          console.log("Date - " + prop + ": " + CD.d.row[prop]);
          obj[prop] = new Date(CD.d.row[prop]); //new Date()
        }
        this.setState(obj);
        //this.state[prop] = CD.d[prop];
        //this.setState({ [prop]: CD.d[prop] });
      }
    }
    await this.getWFRoles();
    await this.getWFSteps();
  }
  rowClick = async (event) => { // Select a workflow
    //let last = this.lastSelectedIndex;
    const current = this.state.CFWorkflows.findIndex(dataItem => dataItem === event.dataItem);
    //this.lastSelectedIndex = last = current;
    //this.state.CFWorkflows.forEach(item => item.selected = false);
    //const select = !event.dataItem.selected;
    //for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
    //  this.state.CFWorkflows[i].selected = select;
    //}
    let PKID = this.state.CFWorkflows[current].CFWorkflowID;
    this.getRecord(PKID);
    this.state.CFWorkflowID = PKID;  
    this.forceUpdate();
    //this.setState({ CFWorkflowID: PKID }); // Note - delayed update meant getWorkflowRoles did not work
    await this.getWorkflowRoles();
    await this.getWorkflowSteps();
  }
  workflowsPageChange = (event) => {
    this.setState({ workflowsSkip: event.page.skip, workflowsTake: event.page.take });
  } 
  //------------------------- Edit Grid ------------------------------------------------------------------    
  RoleCommandCell;
  StepCommandCell;
  enterInsertRole() {
    if (this.state.CFWorkflowID > 0 && this.state.CFWorkflowOwnerID > 0 && this.state.CFWorkflowOwnerID !== SessionInfo.CFParticipantID) {
      displayWarning("Only the Workflow Owner can update the Workflow Roles");
      return;
    }
    const dataItem = { inEdit: true };
    const allRecords = this.state.CFWorkflowRoles.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateRole(allRecords, dataItem);
    this.setState({ CFWorkflowRoles: allRecords });
    this.rolesUpdated = true;
  }
  enterEditRole(dataItem) {
    if (this.state.CFWorkflowID > 0 && this.state.CFWorkflowOwnerID > 0 && this.state.CFWorkflowOwnerID !== SessionInfo.CFParticipantID) {
      displayWarning("Only the Workflow Owner can update the Workflow Roles");
      return;
    }
    this.updateRole(this.state.CFWorkflowRoles, dataItem).inEdit = true;
    this.setState({ CFWorkflows: this.state.CFWorkflowRoles.slice() });
  }
  saveRole(dataItem) {
    if (this.state.CFWorkflowID > 0 && this.state.CFWorkflowOwnerID > 0 && this.state.CFWorkflowOwnerID !== SessionInfo.CFParticipantID) {
      displayWarning("Only the Workflow Owner can update the Workflow Roles");
      return;
    }
    dataItem.inEdit = undefined;
    if (dataItem.CFWorkflowRoleID === undefined)
      dataItem.CFWorkflowRoleID = 0;
    dataItem["CFWorkflowID"] = this.state.CFWorkflowID;
    //console.log("Save Role - WorkflowID: " + this.state.CFWorkflowID + " Item: " + JSON.stringify(dataItem));
    dataItem.CFWorkflowRoleID = this.updateRole(this.state.CFWorkflowRoles, dataItem).CFWorkflowRoleID;
    //let index = this.state.CFWorkflowRoles.findIndex(p => p === dataItem || dataItem.CFWorkflowRoleID && p.CFWorkflowRoleID === dataItem.CFWorkflowRoleID);
    this.setState({ CFWorkflowRoles: this.state.CFWorkflowRoles.slice() });
    UpdateRow(543821, this.state.CFWorkflowRoles, dataItem, dataItem.CFWorkflowRoleID, "CFWorkflowRoleID"); // Save to OM
  }
  cancelRole(dataItem) {
    if (dataItem.CFWorkflowRoleID) {
      let originalItem = this.state.saveCFWorkflowRoles.find(p => p.CFWorkflowRoleID === dataItem.CFWorkflowRoleID)
      if (originalItem !== undefined && originalItem.inEdit) originalItem.inEdit = false;
      this.updateRole(this.state.CFWorkflowRoles, originalItem);
    } else {
      this.updateRole(this.state.CFWorkflowRoles, dataItem, !dataItem.CFWorkflowRoleID); // remove false
    }
    this.setState({ CFWorkflowRoles: this.state.CFWorkflowRoles.slice() });
  }
  removeRole(dataItem) {
    if (this.state.CFWorkflowID > 0 && this.state.CFWorkflowOwnerID > 0 && this.state.CFWorkflowOwnerID !== SessionInfo.CFParticipantID) {
      displayWarning("Only the Workflow Owner can update the Workflow Roles");
      return;
    }
    dataItem.inEdit = undefined;
    let key = dataItem.CFWorkflowRoleID;
    this.updateRole(this.state.CFWorkflowRoles, dataItem, true);
    this.updateRole(this.state.saveCFWorkflowRoles, dataItem, true);
    DeleteRow(543821, key); // Delete in OM
    this.setState({ CFWorkflowRoles: this.state.CFWorkflowRoles.slice() });
  }
  itemChangeRole(event) {
    const value = event.value;
    const name = event.field;
    if (name) {
      const updatedData = this.state.CFWorkflowRoles.slice();
      const item = this.updateRole(updatedData, event.dataItem);
      item[name] = value;
      this.setState({ CFWorkflowRoles: updatedData });
    }
  }
  updateRole(data, item, remove) {  // data - is the entire data set (JSON), item - is the current line item  
    let updated;                                
    //console.log("update - item: " + JSON.stringify(item));
    //eslint-disable-next-line            
    let index = data.findIndex(p => p === item || item.CFWorkflowRoleID && p.CFWorkflowID === item.CFWorkflowRoleID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }
  //------------------------- End Edit Role Grid ------------------------------------------------------------------   
  enterInsertStep() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.CFWorkflowSteps.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.updateStep(allRecords, dataItem);
    this.setState({ CFWorkflowSteps: allRecords });
  }
  enterEditStep(dataItem) {
    this.updateStep(this.state.CFWorkflowSteps, dataItem).inEdit = true;
    this.setState({ CFWorkflows: this.state.CFWorkflowSteps.slice() });
  }
  saveStep(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.CFWorkflowStepID === undefined)
      dataItem.CFWorkflowStepID = 0;
    dataItem.CFWorkflowStepID = this.updateStep(this.state.CFWorkflowSteps, dataItem).CFWorkflowStepID;
    this.setState({ CFWorkflowSteps: this.state.CFWorkflowSteps.slice() });
    UpdateRow(543821, this.state.CFWorkflowSteps, dataItem, dataItem.CFWorkflowStepID, "CFWorkflowStepID"); // Save to OM
  }
  cancelStep(dataItem) {
    if (dataItem.CFWorkflowStepID && dataItem.CFWorkflowStepID !== 0) {
      let originalItem = this.state.saveCFWorkflowSteps.find(p => p.CFWorkflowStepID === dataItem.CFWorkflowStepID)
      if (originalItem !== undefined) {
        if (originalItem.inEdit) originalItem.inEdit = false;
        this.updateStep(this.state.CFWorkflowSteps, originalItem);
      }
      else {
        this.setState({ CFWorkflowSteps: this.state.saveCFWorkflowSteps.slice() })
      }
    } else {
      this.updateStep(this.state.CFWorkflowSteps, dataItem, !dataItem.CFWorkflowStepID); // remove false
    }
    this.setState({ CFWorkflowSteps: this.state.CFWorkflowSteps.slice() });
  }
  removeStep(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.CFWorkflowStepID;
    this.updateStep(this.state.CFWorkflowSteps, dataItem, true);
    this.updateStep(this.state.saveCFWorkflowSteps, dataItem, true);
    DeleteRow(543821, key); // Save to OM
    this.setState({ CFWorkflowSteps: this.state.CFWorkflowSteps.slice() });
  }
  itemChangeStep(event) {
    const value = event.value;
    const name = event.field;
    if (name) {
      const updatedData = this.state.CFWorkflowSteps.slice();
      const item = this.updateStep(updatedData, event.dataItem);
      item[name] = value;
      this.setState({ CFWorkflowSteps: updatedData });
    }
  }
  updateStep(data, item, remove) {  // data - is the entire data set (JSON), item - is the current line item
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.CFWorkflowStepID && p.CFWorkflowID === item.CFWorkflowStepID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }
  //------------------------- End Edit Step Grid ------------------------------------------------------------------
  //------------------------- Field Edit ------------------------------------------------------------------  
  lastSelectedStepIndex;
  stepsRowClick = async (event) => {
    //let last = this.lastSelectedStepIndex;
    //console.log("dataitem: " + JSON.stringify(event.dataItem));
    const index = this.state.CFWorkflowSteps.findIndex(dataItem => dataItem === event.dataItem);
    //this.lastSelectedStepIndex = last = current;
    //this.state.CFWorkflowSteps.forEach(item => item.selected = false);
    //const select = !event.dataItem.selected;
    //for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
    //  this.state.CFProjects[i].selected = select;
    //}                      
    this.state.CFWorkflowStepID = this.state.CFWorkflowSteps[index].CFWorkflowStepID;
    console.log("Row-Click CFWorkflowStepID: " + this.state.CFWorkflowStepID + " index: " + index);
    this.getStepRecord(this.state.CFWorkflowStepID);  
    this.setState({ selectedTab: 5 });
  }

  handleSelect = async (e) => {              
    if (e.selected >= 3) {
      if (this.state.CFWorkflowID === 0) {
        displayWarning("A workflow must be selected or added first");
        return;
      }   
      if (e.selected === 5) {
        if (this.rolesUpdated === true)
          this.getWorkflowRoles();
      }
    }       
    this.setState({ selectedTab: e.selected });
  }
  getStepRecord = async (Key) => {
    let CD = await GetTableRow(543800, Key); // Get WorkflowStepInfo 
    //console.log("StepRecord: " + JSON.stringify(CD));  
    if (this.state.WorkflowRoles.length <= 1) {
      await this.getWorkflowRoles();
    }
    //console.log("Roles: " + JSON.stringify(this.state.WorkflowRoles));
    //console.log("Steps: " + JSON.stringify(this.state.WorkflowSteps));
    //const DDs = {
    //  "CFWorkflowRoleID": this.state.WorkflowRoles, "TransitionTo1ID": this.state.WorkflowSteps, "TransitionTo2ID": this.state.WorkflowSteps, "TransitionTo3ID": this.state.WorkflowSteps
    //};
    this.setState({ CFWorkflowStepID: Key });
    for (var prop in CD.d.row) {
      if (prop in this.state) {
        //console.log("Set " + prop + ": " + CD.d.row[prop]);
        let obj = {};
        obj[prop] = CD.d.row[prop];
        //if (prop.indexOf("ID") > 0 && prop !== "CFWorkflowStepID" && prop !== "CFWorkflowID" && DDs[prop] !== undefined) {
        //  //console.log("Lookup Prop: " + prop + " ddID: " + CD.d.row[prop] + " DDs: " + JSON.stringify(DDs[prop]) + " entries: " + DDs[prop].length);
        //  for (let ix = 0; ix < DDs[prop].length; ix++) {
        //    let objp = DDs[prop][ix];
        //    //console.log("Lookup val: " + JSON.stringify(objp));
        //    if (objp.ddID === CD.d.row[prop]) {
        //      obj[prop] = objp;
        //      //console.log("Found Prop: " + prop + " ddID: " + CD.d.row[prop] + " value:" + JSON.stringify(objp));
        //      break;
        //    }
        //  }
        //}
        this.setState(obj);
        //this.state[prop] = CD.d[prop];
        //this.setState({ [prop]: CD.d[prop] });
      }
    }
  }
  onSaveStep = async (event) => {
    //console.log("Save Step"); // + JSON.stringify(this.state));
    if (this.state.CFWorkflowID > 0 && this.state.CFWorkflowOwnerID > 0 && this.state.CFWorkflowOwnerID !== SessionInfo.CFParticipantID) {
      displayWarning("Only the Workflow Owner can update the Workflow Steps");
      return;
    }
    const DDs = {
      "CFWorkflowRoleID": this.state.WorkflowRoles, "TransitionTo1ID": this.state.WorkflowSteps, "TransitionTo2ID": this.state.WorkflowSteps, "TransitionTo3ID": this.state.WorkflowSteps
    };
    if (this.state.StepName.length === 0) {
      displayError("Step Name is Required");
      return;
    } else if (this.state.StepName.length > 32) {
      displayError("Step Name cannot be more than 32 characters (" + this.state.StepName.length + ")");
      return;
    }
    let copyState = Object.assign({}, this.state);
    let type = 0;
    for (var prop in copyState) {
      if (prop === "string") {
        type = 1;
        copyState[prop] = undefined;
      }
      else if (prop === "selectedTab")
        type = 2;
      else if (type === 0) {
        if (prop.indexOf("ID") > 0 && prop !== "CFWorkflowStepID" && prop !== "CFWorkflowID" && DDs[prop] !== undefined) {
          //console.log("Lookup Prop: " + prop  + " entries: " + DDs[prop].length);
          for (let ix = 0; ix < DDs[prop].length; ix++) {
            let objp = DDs[prop][ix];
            //console.log("Lookup val: " + JSON.stringify(objp));
            if (objp.ddName === copyState[prop]) {
              copyState[prop] = objp.ddID;
              //console.log("Found Prop: " + prop + " ddID: " + copyState[prop] + " value:" + JSON.stringify(objp));
              break;
            }
          }
        }
        //if (prop !== "CFWorkflowID" && prop !== "CFWorkflowStepID" && prop.indexOf("ID") > 0) {
        //  console.log("Prop: " + prop);
        //  copyState[prop] = copyState[prop].ddID;
        //  console.log("Prop val: " + copyState[prop]);
        //}
      }
      if (type === 2) {
        copyState[prop] = undefined;
      }
    }
    //console.log("Workflow Step Save: " + JSON.stringify(copyState));
    await SaveRow(543800, copyState, copyState.CFWorkflowStepID, "CFWorkflowStepID"); // Save to OM 
    this.setState({ stateHasChanged: false });
    await this.getWFSteps();
    if (this.state.CFWorkflowStepID === 0) // Not an update
      this.onClearStep();
    await this.getWorkflowRoles();
    await this.getWorkflowSteps();
  }
  onClearStep = async (event) => {
    this.setState({ CFWorkflowStepID: 0 });
    this.setState({ StepName: '' });
    this.setState({ StepDescription: '' });
    this.setState({ CFWorkflowRoleID: 0 });
    this.setState({ TransitionTo1ID: 0 });
    this.setState({ TransitionTo2ID: 0 });
    this.setState({ TransitionTo3ID: 0 });
  }

  GetWorkflowSteps = (sort) => {
    return orderBy(this.state.CFWorkflowSteps, sort);
  }
  
  onVerifyWorkflow = async (event) => {
    displayWarning("Workflow Verify is not implemented");
  }
  handleSubmit = (event) => {
    event.preventDefault();
    //console.log('Submitted: ' + this.state.Email + '-' + PW);
    //if (PW !== ConfirmPW) {
    //  message = "Passwords must match";
    //}
    //else {
    //  UserRegister(FirstName, LastName, Email, PW, ConfirmPW);
    //  props.history.push("/AppMain");
    //}
  }
  chgFldVal(event) {
    var stateCopy = Object.assign({}, this.state[event.target.name]);
    stateCopy = event.target.value;
    this.setState({ [event.target.name]: stateCopy });
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
  }
  chgCheckboxVal(event) {
    var stateCopy = Object.assign({}, this.state[event.target.name]);
    stateCopy = !stateCopy;
    this.setState({ [event.target.name]: stateCopy });
  }
  //------------------------- Field Edit ------------------------------------------------------------------ 

  render() {
    try {
      return (
        <div id="WorkflowDescription" className="pageMain">
          <PageHeader L1='Home' Select='5' Title='Workflow Descriptions' IsApp='y' />
          <div id="mainCntr">
            <Splitter style={{ height: '100%' }} panes={this.state.nestedPanes} orientation={'vertical'} onLayoutChange={this.onNestedLayoutChange}>
              <Splitter panes={this.state.panes} onLayoutChange={this.onLayoutChange}>
                <div id="splitterLeft" className="pane-content">
                  <div id="div1" className="editInside">
                    <MuiThemeProvider theme={theme}>
                      <form >
                        <Input type="hidden" id="543769" name="CFWorkflowID" value={this.state.CFWorkflowID} />
                        <FormControl margin="dense" required fullWidth>
                          <InputLabel htmlFor="WorkflowName">Workflow Name</InputLabel>
                          <Input id="WorkflowName" name="WorkflowName" value={this.state.WorkflowName} autoComplete="WorkflowName" autoFocus onChange={evt => this.chgFldVal(evt)} />
                        </FormControl>
                        <FormControl margin="dense" required fullWidth>
                          <InputLabel htmlFor="WorkflowSummary">Workflow Summary</InputLabel>
                          <Input id="WorkflowSummary" name="WorkflowSummary" autoComplete="WorkflowSummary" value={this.state.WorkflowSummary} onChange={evt => this.chgFldVal(evt)} />
                        </FormControl>
                      </form>
                    </MuiThemeProvider >
                  </div>
                </div>
                <div id="splitterRight" className="pane-content">
                  <TabStrip selected={this.state.selectedTab} name="WorkflowTab" onSelect={this.handleSelect} animation={true}>
                    <TabStripTab title="Control Information">
                      <div className="editTab">
                        <div className="editTabLeft">
                          <div id="div1" className="editInside">
                            <h4>Ownership Information</h4>
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Workflow Owner</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.WorkflowOwners} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowOwners.find(c => c.ddID === this.state.CFWorkflowOwnerID)} name="CFWorkflowOwnerID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Project Type</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.ProjectTypes} textField="ddName" dataItemKey="ddID" value={this.state.ProjectTypes.find(c => c.ddID === this.state.ProjectTypeID)} name="ProjectTypeID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Control Role</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.WorkflowRoles} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowRoles.find(c => c.ddID === this.state.CFControlRoleID)} name="CFControlRoleID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="editTabRight">
                          <div id="div1" className="editInside">
                            <h4>Control Info</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Role Count</span>
                              <input value={this.state.RoleCount} name="RoleCount" readOnly={true} onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Step Count</span>
                              <input value={this.state.StepCount} name="StepCount" readOnly={true} onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <div id="div2" className="editField">
                              <input type="checkbox" checked={this.state.IsPrivate} name="IsPrivate" onChange={evt => this.chgCheckboxVal(evt)} />
                              <span className="editFieldLabel">Is Private</span>
                            </div>
                            <br />
                            <br />
                            {(1 === 0) &&
                              <div className="editField">
                                <Button icon="refresh" color="primary" onClick={this.onVerifyWorkflow}>Verify Workflow</Button>
                              </div>
                              }
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Description">
                      <div className="editTab">
                        <div id="div1" className="editInside">
                          <h4>Workflow Description</h4>
                          <div className="editTAField">
                            <span className="editFieldLabel">Summary</span>
                            <textarea value={this.state.WorkflowSummary} name="WorkflowSummary" onChange={evt => this.chgFldVal(evt)} className="editTAInputWide" />
                          </div>
                          <div className="editTAField">
                            <span className="editFieldLabel">Description</span>
                            <textarea value={this.state.WorkflowDescription} name="WorkflowDescription" onChange={evt => this.chgFldVal(evt)} className="editTAInputWideLong" />
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Comments">
                      <div className="editTab">
                        <div id="div1" className="editInside">
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Comments</span>
                            <textarea value={this.state.Comments} name="Comments" onChange={evt => this.chgFldVal(evt)} className="editTAInputWideLong" />
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Roles">
                      <div className="editTab">
                        <div className="editInside">
                          <TGrid style={{ position: 'absolute', top: 2, height: '99%', width: '100%' }} data={this.state.CFWorkflowRoles} onItemChange={this.itemChangeRole} editField="inEdit" sortable={true} resizable={true}>
                            <GridToolbar>
                              <button title="Add New Role" className="k-button k-primary" style={{ 'backgroundColor': '#0059b3', height: '30px', borderRadius: '5px' }} onClick={this.enterInsertRole}>Add New Role</button>
                              {this.state.CFWorkflowRoles.filter(p => p.inEdit).length > 0 && (
                                <button className="k-button" style={{ height: '30px' }} onClick={(e) => this.setState({ CFWorkflowRoles: this.state.saveCFWorkflowRoles.slice() })}>Cancel Current Changes</button>
                              )}
                            </GridToolbar>
                            <Column field="CFWorkflowRoleID" title="" width='1px' />
                            <Column field="RoleName" title="Role Name" />
                            <Column field="Description" title="Role Description" />
                            <Column cell={this.RoleCommandCell} width="260px" />
                          </TGrid>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Steps">
                      <div className="editTab">
                        <div id="div4" className="editInside">
                          <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFWorkflowSteps} sortable={true} resizable={true} onRowClick={this.stepsRowClick} rowHeight={30}
                            sortable={{ allowUnsort: true, mode: 'multiple' }} sort={this.state.sortSteps} onSortChange={(event) => { this.setState({ CFWorkflowSteps: this.GetWorkflowSteps(event.sort), sortSteps: event.sort }); }}>
                            <Column field="CFWorkflowStepID" title="" filter="numeric" width='1px' />
                            <Column field="StepName" title="Name" width='180px' />
                            <Column field="SortOrder" title="Order" width='50px' />
                            <Column field="StepDescription" title="Description" width='250px' />
                            <Column field="CFWorkflowRoleID" title="Role" />
                            <Column field="TransitionTo1ID" title="Next Step" />
                            <Column field="TransitionTo2ID" title="Next Step" />
                            <Column field="TransitionTo3ID" title="Next Step" />
                            <Column field="TransitionTo4ID" title="Next Step" />
                          </TGrid>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="Step Detail">
                      <div className="editTab">
                        <div className="editTabLeft">
                          <div id="div1" className="editInside">
                            <h4>Step Detail Information</h4>
                            <div className="editField">
                              <span className="editFieldLabel">Step Name</span>
                              <input value={this.state.StepName} name="StepName" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                            </div>
                            <div className="editField">
                              <span className="editFieldLabel">Step Description</span>
                              <textarea value={this.state.StepDescription} name="StepDescription" onChange={evt => this.chgFldVal(evt)} className="editTAInput" />
                            </div>
                            <br />
                            <br />
                            <div className="editField">
                              <span className="editFieldLabel">Step Role</span>
                              <div className="editDDPos">
                                <DropDownList data={this.state.WorkflowRoles} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowRoles.find(c => c.ddID === this.state.CFWorkflowRoleID)} name="CFWorkflowRoleID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                              </div>
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Sort Order</span>
                              <input value={this.state.SortOrder} name="SortOrder" readOnly={false} onChange={evt => this.chgFldVal(evt)} className="editNumShortInput" />
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.onSaveStep}>Save Step</Button>
                            </div>
                          </div>
                        </div>
                        <div className="editTabRight">
                          <div id="div1" className="editInside">
                            <h4>Transition Steps</h4>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Next Step</span>
                              <DropDownList data={this.state.WorkflowSteps} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowSteps.find(c => c.ddID === this.state.TransitionTo1ID)} name="TransitionTo1ID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Next Step</span>
                              <DropDownList data={this.state.WorkflowSteps} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowSteps.find(c => c.ddID === this.state.TransitionTo2ID)} name="TransitionTo2ID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Next Step</span>
                              <DropDownList data={this.state.WorkflowSteps} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowSteps.find(c => c.ddID === this.state.TransitionTo3ID)} name="TransitionTo3ID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                            <div id="div2" className="editField">
                              <span className="editFieldLabel">Next Step</span>
                              <DropDownList data={this.state.WorkflowSteps} textField="ddName" dataItemKey="ddID" value={this.state.WorkflowSteps.find(c => c.ddID === this.state.TransitionTo4ID)} name="TransitionTo4ID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                            </div>
                            <br />
                            <br />
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.onClearStep}>Clear Step Detail</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                  </TabStrip>
                </div>
              </Splitter>
              <div id="splitterBottom" className="pane-content">
                <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFWorkflows.slice(this.state.workflowsSkip, this.state.workflowsTake + this.state.workflowsSkip)}
                  onRowClick={this.rowClick} resizable={true}
                  skip={this.state.workflowsSkip} take={this.state.workflowsTake} total={this.state.CFWorkflows.length} onPageChange={this.workflowsPageChange}
                  pageable={{ info: true, type: 'numeric', pageSizes: true, previousNext: true }}
                  pageSize={8}>
                  <Column field="CFWorkflowID" title="Workflow ID" filter="numeric" width='1px' />
                  <Column field="WorkflowName" title="Workflow Name" width='250px'/>
                  <Column field="CFWorkflowOwnerID" title="Workflow Owner" width='250px'/>
                  <Column field="WorkflowSummary" title="Summary" />
                </TGrid>
              </div>
            </Splitter>
          </div>
          <PageFooter L1='Home' />
        </div>
      );
    }
    catch {
      this.props.history.push("/AppMain");
      notify("Session Timeout - Login Required");
    }
    return (
      <div id="WorkflowDescription" className="pageMain">
        <PageHeader L1='Home' Select='5' Title='Workflow Descriptions' IsApp='y' />
        <div id="mainCntr">
        </div>
        <PageFooter L1='Home' />
      </div>
    );
  }
}

export default WorkflowDescription;