import React, { } from 'react';              
import './App.css';
import './style.css';                
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';

class AboutPage extends React.Component {
  constructor(props) {
    super(props);    
  }
  state = {
    searchValue: '',
    MessagesValue: '',
    currentTime: '',
  };
  refreshCount = 6;
  //componentDidMount() {
  //  console.log("history 2: " + JSON.stringify(this.props.history)); 
  //}
  //componentDidUpdate() {
  //  //let value = this.context;
  //}

  render() {
    //if (!this.props.history) 
    //return (<Redirect to='/AppMain' />);
    return (
      <div id="appMain" className="pageMain">
        <PageHeader L1='Home' Title='About Contract Flow' />
        <div id="mainCntr">
          <div className="headingBox">
            <div className="heading">
              <h2>Contract Flow Tutorial</h2>
            </div>
          </div>
          <div id="bottomCntr">
            <div className="leftC">
              <div className="getBox">
                <img src={require("./images/sub/get_icon.png")} alt="" />
                <h2>Get Started on a Workflow</h2>
                <p>Each person involved in a Project Workflow is referred to as a <em>Participant</em>. Each Participant must have a Contract Flow account and each Participant must have a Ethereum Wallet Address setup and registered with MetaMask.<br />
                  When the accounts have been set up, you're ready to start using Contract Flow and the Ethereum Blockchain.</p>
              </div>
              <div className="ourblogBox">
                <div className="top">
                  <div className="bottom">
                    <h2>Contract Flow Alpha</h2>
                    <p><span>This is a Test Version of Contract Flow</span>
                      We have a lot of testing to do - and we're looking for your feedback. This test version of Contract Flow runs on the Rinkeby Test Network. The Test Network allows us to run the Solidity Contract on a real Ethereum network without having to spend money to buy real Ether (Ethereum currency).<br />
                      We would appreciate feedback and to hear about any questions or problems that you may have using Contrct Flow. Please go to our Contact Page <img src={require("./images/WriteMessage.png")} alt="" className="loginImage" /> to submit questions.
							  <a href="https://cryptocurrencyhub.io/test-ether-101-introduction-to-ethereum-test-networks-7fa0bfcdf7c7" target="_blank" rel="noopener noreferrer">&nbsp;Ethereum Test</a></p>
                    <p className="last"><span>You'll need some Ether</span>
                      To use Contract Flow, you'll need that Rinkeby Account, and then you will need some Ether. Fortunately, as we are running on the Rinkeby Test Network, the Ether doesn't cost money. It does involve a little challenge to get some test Ether though.<br />
                      A <em>Faucet</em> is a website that will provide you with some test Ether. You can access the Faucet with the link below. Beware however, it does not always work the first time.<br />Read the instructions carefully, post the wallet address (from MetaMask) in your social media and click on your post. This will give you the URL.<br />
                      When you click on the 'Give me Ether' button and select the 18.75/3 days - it may present you with a Captcha request with photos. It should then tell you with a <em>green popup</em> that it is granting your request. Note that it may take a while to show up in your account.
							  <a href="https://faucet.rinkeby.io/" target="_blank" rel="noopener noreferrer">&nbsp;Rinkeby Faucet</a></p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div id="rightCntr">
              <div className="textBox">
                <h3 className="icon1"><span>Creating a new Project Contract</span></h3>
                <p className="first">The first step is to get Registered with Contract Flow and MetaMask. Then, you will need to join a Contract Flow Project as a Participant. Now, you are ready to create a <em>Project Contract</em> and start working with the Blockchain.<br /><br />
                  A Project Contract is associated with an actual Ethereum Contract. Using Contract Flow, you create and run your own copy of the contract on the Blockchaing. The Ethereum Contract is loaded with the Workflow associated with the Contract Flow Project. Workflows are described under the Workflow Tab.<br />
                  <br />
                  There are a number of Projects which have been set up, each with a different Workflow. When you click on a Project in the Projects Directory, you will see the Project Detail, which includes information about the Project - including the Workflow and who has been invited to participate in the project.</p>
                <p>You'll need some other people to work with you to exercise the Contract Workflow. Each participant will take their turn to advance the workflow.</p>
                <p>For instance, let's say that it is a Supply Chain Workflow that includes a <b>Supplier</b>, a <b>Customer</b>, a <b>Payer</b> and a <b>Payee</b>.
                  The <b>Supplier</b> sends an invoice to the <b>Customer</b>, the <b>Customer</b> agrees that the invoice is correct and advances the workflow, the <b>Payer</b> is notified and advances the work flow, the <b>Payee</b> receives the payment and advances the workflow, the <b>Supplier</b> receives payment and marks the workflow as Paid.<br/>
                  That's it, the workflow is complete. And, you can verify that each step in the workflow was recorded as a <b>Transaction</b> on the Rinkeby Test Network by viewing the Transactions available on the Project Contract page.</p>
              </div>      

              <div className="portfolioBox">
                <h3>Join a Project
					         <span>Joining a Project is relatively simple, you just have to get someone to invite you - unless it is an Open Project. A project can be one of 4 types: Hidden, Private, Controlled or Open.<br/>
                    In an Open project, anyone who is registered with Contract Flow can go in and invite themselves. With the other Project types, you will need to be invited by the Project Owner.<br />
                    Once you have been invited into a project and become a Project Participant, you can participate in a Project.<br /><br />
                    If you have been assigned a Workflow Control Role, or no Role in the Project, you can initiate a new Project Contract.<br />
                    Click on the Project of interest in the Projects list. In the Detail screen, you will see a <b>Create Project Contract</b> button. Click on the Create button and this will create a new Project Contract and save the Contract address under the <b>Entehereum Contract</b> tab.</span></h3>
                <div className="portfolioBox1">
                  <img src={require("./images/sub/portfolio_img1.jpg")} style={{ width: '26%', }} alt="" />
                  <p>In the Project Contract screen you can set up the contract description and invite other Participants of the Project to participate in your Contract.<br />
                    You can also Set the Price for gas and the amount of Ether you will contribute to the project to pay for transaction processing<br/>You can also set the Start Date for the Contract - and the expected End Date</p> 
                </div>
                <h3><span>When you have filled every Role for your Workflow, you can then start working with the actual Ethereum Contract.<br/>Click on the <b>Create Ethereum Contract</b> button. This will create a new copy of the Solidity Smart Contract and load it with your information and the workflow description.<br />
                  First, verify that you have a Smart Contract set up - click on the <b>Ethereum Contract</b> tab on the Contract Page - you should see your Contract Address. You can view your Smart Contract on Etherscan by clicking on the Display Contract button.</span></h3>
                 {/*<a href="/WorkflowDefinition">View Workflows...</a>*/}
              </div>

              <div className="portfolioBox">
                <h3>Add a Document to your Contract
					         <span>Now that you have your <b>ContractFlow Contract</b> set up - and all of the Workflow Roles have been assigned to Participants - and the <b>Ethereum Contract</b> has been initialized - it's time to add the business document to your Contract.<br />
                    Click on the <b>Documents Tab,</b> on the Contract Page to select, view, encrypt and save the document on IPFS - and save the document reference information on Ethereum Blockchain.<br /></span></h3>
                <div className="portfolioBox1">
                  <img src={require("./images/sub/portfolio_img2.jpg")} style={{ width: '27%', }} alt="" />
                  <p>It would be prohibitively expensive to save the actual document on the blockchain, so instead, we <em>encrypt</em> the document and save it on a public storage network and then <em>encrypt</em> the key information for the document and save it on the blockchain along with your workflow information.</p>
                </div>
                  <h3><span>The Key for the document is <em>encrypted</em> before it is saved on the blockchain using your Public Key which is saved in your profile. To download and read the document at a later time, you will need to provide your Private Key.<br />
                    Your private key may be saved in Contract Flow, or you may copy it to the Contract Flow screen before pressing the Load File from IPFS button.<br />
                    If the document is a pdf, you will be able to view it in the browser, otherwise you will have to open from your computer file system.</span></h3>
              </div>

              <div className="portfolioBox">
                <h3>Step Through Your Workflow
					         <span>Now that you have your <b>ContractFlow Contract</b> set up - and the business document is attached to your Contract - its time to start working with your <b>Workflow</b> with the other participants for your Contract.<br />
                    When you select your Contract from the list in Project Detail screen, or in your Profile, it will load the Contract Flow information from the Contract Flow server and then verify that the information matches the the information in the Ethereum Contract.</span></h3>
                <div className="portfolioBox1">
                  <img src={require("./images/sub/portfolio_img3.jpg")} style={{ width: '26%', }} alt="" /> 
                  <p>The Contract Info screen will tell you the Current Role (who is signed on to Metamask) and the Current Step in the workflow. It will also tell you the Active Role(s) for the workflow. If the Current Role matches one of the Active Roles, there will be Options in the Next Step drop down.<br />
                    <br />Select which Next Step is appropriate for your situation and press the <em>Go To Next Step</em> button.</p> 
                  <p>The Contract Flow program will tell the Etherum to advance the <b>Workflow</b> to the desired step.<br/>You will need to confirm an Ethereum transaction with MetaMask to advance the workflow and record the event transaction on the Blockchain.</p>                                                                                                 
                </div>
                  <h3><span>Once the <b>Workflow</b> has been advanced, the information on the Contract screen will refresh with the updated Ethereum information. The Transaction will be visible on the Transactions tab and on Etherscan. The Workflow tab will show the current Step in the Workflow.</span></h3>
              </div>

              <div className="portfolioBox">
                <h3>The Steps to Setting up a Project
					         <span>Setting up a new Project is relatively simple, once you know the steps. First, you'll need a Workflow. The Workflow will define your business process, and the role that each participant plays in the project. Once you have your workflow, you set up a Project that references that workflow.
                      Then you invite people to participate in your project. Once you have Project established, you can create a Project Contract and assign Participants to the Contract - one participant for each Role in the Project Workflow. When your Project Contract has been setup - you can run your project.</span></h3>
                <div className="portfolioBox1">
                  <img src={require("./images/sub/portfolio_img1.jpg")} style={{ width: '26%', }} alt="" />
                  <p>Once you have your Workflow set up, you can then define a Project and start by inviting people to join your project.</p>
                  <p>When you have a invited a number of people to join your project, you can create Create a Project Contract by clicking on the 'Create Project Contract' button.</p>
                </div>    
              </div>
            </div>
          </div>
        </div>
        <PageFooter L1='Home' />
      </div >
    );
  }
}

export default AboutPage