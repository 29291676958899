//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ParticipantProfile - Contract Flow Participant  - Detailed information about the participant, participant settings, and information regarding subscribed projects
//                      Version 1.007 - June 5, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import 'whatwg-fetch';                   
import './App.css';
import './custom.scss';                                
//import '@progress/kendo-theme-default/dist/all.css';
import { Grid as TGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab, Splitter } from '@progress/kendo-react-layout';
import {  DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';    
import { SessionInfo } from './App';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js';
import { GetTableRow, UpdateRow, DeleteRow, SaveRow, displayMessage, displayError, GetDropdownData, GetTableData, GetTableSearch, CreateKeys } from './CommonCode.js';
import { getEthAccounts } from './SolidityInterface.js';
import FormControl from '@material-ui/core/FormControl';     
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';      
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';              
               
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});  

class ParticipantProfile extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    //console.log("ParticipantProfile");
    if (!SessionInfo || !SessionInfo.session)
      this.props.history.push("/AppMain");
    else {
      this.loadExistingPage = false;
      if (SessionInfo.SelectedParticipantID === 0) {
        if (SessionInfo.ParticipantProfile) {
          this.state = SessionInfo.ParticipantProfile;
          if (this.state.CFParticipantID === 0)
            SessionInfo.SelectedParticipantID = SessionInfo.CFParticipantID; // Session User
          else if (SessionInfo.HasPerm51 === false && this.state.CFParticipantID !== SessionInfo.CFParticipantID)
            SessionInfo.SelectedParticipantID = SessionInfo.CFParticipantID;
          else
            this.loadExistingPage = true;
        }
        else if (SessionInfo.SelectedParticipantID !== SessionInfo.CFParticipantID)
          SessionInfo.SelectedParticipantID = SessionInfo.CFParticipantID;
      }
      //console.log("start Bind");
      this.getRecords = this.getRecords.bind(this);
      this.saveRecord = this.saveRecord.bind(this);
      this.clearRecord = this.clearRecord.bind(this);
      this.deleteRecord = this.deleteRecord.bind(this);
      //console.log("Bind Keys");
      this.createKeys = CreateKeys.bind(this);
      //console.log("finish Bind");   
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    CFParticipantID: 0,
    CurrencyID: 0,
    EmailAllowed: true,
    string: '',
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    Address: '',
    CompanyName: '',
    PhoneNumber: '',
    BusinessPhone: '',
    LinkedIn: '',
    WalletAddress: '',
    PrivateWalletKey: '',
    SwiftNumber: '',
    PublicKey: '',
    PrivateKey: '',

    selectedTab: 0,
    selectedTab2: 0,
    stateHasChanged: false,

    isWalletUser: false,
    contractsVisible: false,
    CFProjectID: 0,   
    participantsSkip: 0,
    participantsTake: 8,
    contractsSkip: 0,
    contractsTake: 8,
    projectsSkip: 0,
    projectsTake: 8,
    CFParticipants: [],
    saveCFParticipants: [],
    CFProjects: [],
    CFProjectContracts: [],
    Currencies: [],
    panes: [{ collapsible: true }, { size: '70%', min: '20px', collapsible: false }], // Note {}, removed
    nestedPanes: [{ size: '45%' }, { collapsible: true }]  // Note {}, removed
  };
  createKeys;
  loadExistingPage = false;
  selectParticipantID = 0
  lastSelectedPID = 0;

  async componentDidMount() {
    //console.log("Component Did Mount"); 
    SessionInfo.currentPage = "ParticipantProfile";
    if (this.loadExistingPage === true)
      this.forceUpdate();
    else {
      this.selectParticipantID = SessionInfo.CFParticipantID;
      this.lastSelectedPID = this.selectParticipantID;
      if (SessionInfo.SelectedParticipantID > 0) {
        //console.log("Get Participant Info for: " + SessionInfo.SelectedParticipantID);
        await this.getRecord(SessionInfo.SelectedParticipantID);
        //console.log("Get Record Complete");
        displayMessage("GREEN");
        SessionInfo.SelectedParticipantID = 0;
      }
      SessionInfo.currSaveFunc = this.saveRecord;
      SessionInfo.currDeleteFunc = this.deleteRecord;
      SessionInfo.currClearFunc = this.clearRecord;
      SessionInfo.searchFunc = this.searchCurrent;
      if (this.state.Currencies.length <= 1) {
        let CD = await GetDropdownData(100717);
        this.setState({ Currencies: CD.d });
      }
      //console.log("DidMount = Session: " + SessionInfo.session);
      if (SessionInfo.HasPerm51 === true) {
        await this.getRecords();
      }
      else {
        await this.getProjectRecords();
        this.setState({ selectedTab2: 0 }); // Tab 0 is not included
      }
      // Verify the Definition JSON and Initialize the StateMachine Contract 
    }
    const accounts = await getEthAccounts();
    if (accounts[0] === this.state.WalletAddress)
      this.setState({ isWalletUser: true });
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.ParticipantProfile = this.state;
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  CommandCell;
  //-------------------- Page Edit ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  getRecords = async (search) => { // Get the available Participants
    let CD;
    if (!search)
      CD = await GetTableData(543619); // Get Participants
    else
      CD = await GetTableSearch(543619, search); // Get Participants by Search
    if (CD.x.o === 0)
      displayError("Access to OM Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
    else if (CD.x.o < 9500) {
      try {
        const CFParticipants = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
        //console.log("Participants: " + JSON.stringify(CFParticipants));
        this.setState({ CFParticipants });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  getProjectRecords = async (search) => { // Get the available Participants 
    if (!search)
      search = '';
    let PID = this.state.CFParticipantID;
    if (this.selectParticipantID > 0)
      PID = this.selectParticipantID;          
    PID = 10; // Debug
    console.log("Get Project Records - ParticipantID: " + PID);
    //let CD = await GetTableSearch(544145, search + "*CFParticipantID=" + PID + " and CFProjectContractID=0"); // Get Participants attached to this Project
    let CD = await GetTableSearch(544145, search + "*CFProjectContractID>=0"); // Get Participants attached to this Project
    //console.log("Projects CD: " + JSON.stringify(CD)); 
    if (CD.x.o >= 9000 && CD.x.o < 9500) {
      try {
        const CFProjects = CD.d.rows;
        this.setState({ CFProjects });
      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      displayError("Unable to retrieve Projects for Participant");
      this.setState({ CFProjects: [] });
    }
  }
  getCFProjectContracts = async (search) => {
    if (!search)
      search = '';
    //console.log("Get Project Contracts - ParticipantID: " + this.state.CFParticipantID + " CFProjectID=" + this.state.CFProjectID);
    let CD = await GetTableSearch(544148, search + "*CFParticipantID=" + this.state.CFParticipantID + " and CFProjectID=" + this.state.CFProjectID); // Get ProjectContracts for the Project this Participant
    //console.log("Project Contracts CD: " + JSON.stringify(CD)); 
    if (CD.x.o === 0)
      displayError("Access to OM Has Failed in " + SessionInfo.currentPage + " - Session: " + CD.x.s);
    else if (CD.x.o < 9500) {
      try {
        const CFProjectContracts = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
        //console.log("Participants: " + JSON.stringify(CFProjectContracts));    
        this.setState({ CFProjectContracts });
      } catch (error) {
        console.log(error.message);
      }
    }
  }
  getRecord = async (Key) => {
    //console.log("Get Participant Key: " + Key);
    let CD = await GetTableRow(543619, Key); // Save to OM   
    //console.log("Get Participant CD: " + JSON.stringify(CD));
    this.setState({ CFParticipantID: Key });
    if (CD && CD.d && CD.d.row) {
      for (var prop in CD.d.row) {
        if (prop in this.state) {
          //console.log("Set " + prop + ": " + CD.d.row[prop]); 
          let obj = {};
          obj[prop] = CD.d.row[prop];
          if (prop.indexOf("ID") > 0 && prop !== "CFParticipantID" && prop !== "CurrencyID") {
            ////console.log("Lookup Prop: " + prop + " ddID: " + CD.d.row[prop] + " DDs: " + JSON.stringify(DDs[prop]) + " entries: " + DDs[prop].length);
            //for (let ix = 0; ix < DDs[prop].length; ix++) {
            //  let objp = DDs[prop][ix];
            //  //console.log("Lookup val: " + JSON.stringify(objp));
            //  if (objp.ddID === CD.d.row[prop]) {
            //    obj[prop] = objp;
            //    //console.log("Found Prop: " + prop + " ddID: " + CD.d.row[prop] + " value:" + JSON.stringify(objp));
            //    break;
            //  }
            //}
          }
          this.setState(obj);
          //this.state[prop] = CD.d[prop];
          //this.setState({ [prop]: CD.d[prop] });
        }
      }
      SessionInfo.Name = this.state.FirstName + " " + this.state.LastName;
      SessionInfo.EmailAddress = this.state.EmailAddress;
      const accounts = await getEthAccounts();
      if (accounts[0] === this.state.WalletAddress)
        this.setState({ isWalletUser: true });
      else
        this.setState({ isWalletUser: false });
    } else {
      this.setState({ isWalletUser: false });
      displayError("Unable to Fetch Participant Information (" + Key + ")");
    }
  }
  // Select Tabs
  searchCurrent = async (search) => {
    if (SessionInfo.HasPerm51) {
      if (this.state.selectedTab2 === 0) {
        displayMessage("Search Participants");
        await this.getRecords(search);
      }
      else if (this.state.selectedTab2 === 1) {
        displayMessage("Search Participant Projects");
        await this.getProjectRecords(search);
      }
      else if (this.state.selectedTab2 === 2) {
        displayMessage("Search Participant Contracts");
        await this.getCFProjectContracts(search);
      }
    }
    else {
      if (this.state.selectedTab2 === 0) {
        displayMessage("Search Participant Projects");
        await this.getProjectRecords(search);
      }
      else if (this.state.selectedTab2 === 1) {
        displayMessage("Search Participant Contracts");
        await this.getCFProjectContracts(search);
      }
    }
  }
  saveRecord = async () => {
    console.log("Save ParticipantProfile");
    let copyState = Object.assign({}, this.state);
    let type = 0;
    for (var prop in copyState) {
      //console.log("Prop: " + prop + " type: " + type);
      if (prop === "string") {
        type = 1;
        copyState[prop] = undefined;
      }
      else if (prop === "selectedTab")
        type = 2;
      else if (type === 0) {
      }
      if (type === 2) {
        copyState[prop] = undefined;
      }
    }
    console.log("copystate: " + JSON.stringify(copyState));
    await SaveRow(543619, copyState, copyState.CFParticipantID, "CFParticipantID"); // Save to OM 
    await this.getRecords();
    //await this.getProjectRecords();
    this.setState({ stateHasChanged: false });
  }
  deleteRecord = async () => {
    //console.log("Delete ParticipantProfile: " + this.state.CFParticipantID);
    let CD = await DeleteRow(543619, this.state.CFParticipantID);
    console.log("Delete Participant - o: " + CD.x.o);
    this.setState({ stateHasChanged: false });
  }
  clearRecord = () => {
    console.log("Clear Screen - state: " + JSON.stringify(this.state));
    let type = 0;
    //Object.keys(this.state).map
    //  ((prop) => console.log("Clear - " + prop + " type: " + type));
    for (var prop in this.state) {
      //console.log("Clear - " + prop + " type: " + type);
      if (prop === "selectedTab")
        break;
      else if (prop === "string")
        type = 1;
      else if (type === 0) {
        let obj = {};
        obj[prop] = 0;
        this.setState(obj);
      }
      else if (type === 1) {
        let obj = {};
        obj[prop] = '';
        this.setState(obj);
      }
    }
    this.setState({ stateHasChanged: false });
  }
  //-------------------- End of Page Edit -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------- Edit Grid ------------------------------------------------------------------
  enterInsert() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.CFParticipants.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.update(allRecords, dataItem);
    this.setState({ CFParticipants: allRecords });
  }
  enterEdit(dataItem) {
    this.update(this.state.CFParticipants, dataItem).inEdit = true;
    this.setState({ CFParticipants: this.state.CFParticipants.slice() });
  }
  save(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.CFParticipantID === undefined)
      dataItem.CFParticipantID = 0;
    dataItem.CFParticipantID = this.update(this.state.CFParticipants, dataItem).CFParticipantID;
    //let index = this.state.CFParticipants.findIndex(p => p === dataItem || dataItem.CFParticipantID && p.CFParticipantID === dataItem.CFParticipantID);
    this.setState({ CFParticipants: this.state.CFParticipants.slice() });
    UpdateRow(543619, this.state.CFParticipants, dataItem, dataItem.CFParticipantID, "CFParticipantID"); // Save to OM
  }
  cancel(dataItem) {
    if (dataItem.CFParticipantID) {
      let originalItem = this.state.saveCFParticipants.find(p => p.CFParticipantID === dataItem.CFParticipantID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.update(this.state.CFParticipants, originalItem);
    } else {
      this.update(this.state.CFParticipants, dataItem, !dataItem.CFParticipantID); // remove false
    }
    this.setState({ CFParticipants: this.state.CFParticipants.slice() });
  }
  remove(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.CFParticipantID;
    this.update(this.state.CFParticipants, dataItem, true);
    this.update(this.state.saveCFParticipants, dataItem, true);
    DeleteRow(543619, key); // Save to OM
    this.setState({ CFParticipants: this.state.CFParticipants.slice() });
  }
  itemChange(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.CFParticipants.slice();
    const item = this.update(updatedData, event.dataItem);
    item[name] = value;
    this.setState({ CFParticipants: updatedData });
  }
  update(data, item, remove) {  // data - is the entire data set (JSON), item - is the current line item
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.CFParticipantID && p.CFParticipantID === item.CFParticipantID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }
  //------------------------- Select ----------------------------------------------------------------------
  selectionChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }

  rowParticipantClick = (event) => {
    const current = this.state.CFParticipants.findIndex(dataItem => dataItem === event.dataItem);                                                              
    this.lastSelectedPID = this.selectParticipantID;
    let PKID = this.state.CFParticipants[current].CFParticipantID;
    this.selectParticipantID = PKID;
    //console.log("Select PKID: " + PKID);
    this.getRecord(PKID);
    this.forceUpdate();
  }
  rowProjectClick = async (event) => {
    let last = this.lastSelectedIndex;
    const current = this.state.CFProjects.findIndex(dataItem => dataItem === event.dataItem);
    this.lastSelectedIndex = last = current;
    this.state.CFProjects.forEach(item => item.selected = false);
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.CFProjects[i].selected = select;
    }
    this.state.CFProjectID = this.state.CFProjects[current].CFProjectID;
    console.log("Project Select: " + this.state.CFProjectID);
    await this.getCFProjectContracts();
    if (SessionInfo.HasPerm51)
      this.setState({ selectedTab2: 2 });
    else
      this.setState({ selectedTab2: 1 });
    this.setState({ contractsVisible: true });
  }
  rowProjectContractClick = async (event) => {
    const current = this.state.CFProjectContracts.findIndex(dataItem => dataItem === event.dataItem);
    if (current >= 0) {
      SessionInfo.SelectedProjectID = this.state.CFProjectID;
      SessionInfo.SelectedProjectContractID = this.state.CFProjectContracts[current].CFProjectContractID;
      this.props.history.push("/ProjectContract")
    }
  }

  headerSelectionChange = (event) => {
    //const checked = event.syntheticEvent.target.checked;
    //this.state.CFParticipants.forEach(item => item.selected = checked);
    //this.forceUpdate();
  }
  //------------------------- Field Edit ------------------------------------------------------------------  
  // Tab Selection
  handleSelect = (e) => {
    this.setState({ selectedTab: e.selected })
  }
  handle2Select = async (e) => {
    this.setState({ selectedTab2: e.selected });
    console.log("handle2Select: " + this.selectParticipantID);
    if (SessionInfo.HasPerm51) {
      if (e.selected === 0) {
        if (!this.state.CFParticipants)
          await this.getRecords();
      }
      else if (e.selected === 1) {
        console.log("selectID: " + this.selectParticipantID);
        if (this.selectParticipantID != this.lastSelectedPID) {
          await this.getProjectRecords();
          this.lastSelectedPID = this.selectParticipantID;
        }           
        else if (!this.state.CFProjects)
          await this.getProjectRecords();
      }
      else if (e.selected === 2) {
        if (!this.state.CFProjectContracts)
          await this.getCFProjectContracts();
      }
    }
    else {
      if (e.selected === 0) {
        if (!this.state.CFProjects)
          await this.getProjectRecords();
      }
      else if (e.selected === 1) {
        if (!this.state.CFProjectContracts)
          await this.getCFProjectContracts();
      }
    }
  }
  //handleSubmit = (event) => {
  //  event.preventDefault();             
  //}
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }

  chgDDFldVal = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
  }

  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ stateHasChanged: true });
  }
  //------------------------- Field Edit ------------------------------------------------------------------ 
  
  participantsPageChange = (event) => {
    this.setState({ participantsSkip: event.page.skip, participantsTake: event.page.take });
  }
  contractsPageChange = (event) => {
    this.setState({ contractsSkip: event.page.skip, contractsTake: event.page.take });
  }
  projectsPageChange = (event) => {
    this.setState({ projectsSkip: event.page.skip, projectsTake: event.page.take });
  }

  onLayoutChange = (updatedState) => {
    this.setState({ panes: updatedState });
  }
  onNestedLayoutChange = (updatedState) => {
    this.setState({ nestedPanes: updatedState });
  }

  render() {
    if (!SessionInfo.session)
      return (<Redirect to='/' />);
    return (
      <div id="ParticipantProfile" className="pageMain">
        <PageHeader L1='Home' Select='4' Title='Participant Profile' IsApp='y' />
        <div id="mainCntr">
          <Splitter style={{ height: '100%' }} panes={this.state.nestedPanes} orientation={'vertical'} onLayoutChange={this.onNestedLayoutChange}>
            <Splitter panes={this.state.panes} onLayoutChange={this.onLayoutChange}>
              <div id="splitterLeft">
                <div id="div1" className="editInside">
                  <MuiThemeProvider theme={theme}>
                    <form >
                      <Input type="hidden" id="543621" name="CFParticipantID" value={this.state.CFParticipantID} />
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="firstName">First Name</InputLabel>
                        <Input id="firstName" name="FirstName" value={this.state.FirstName} autoComplete="firstName" autoFocus onChange={evt => this.chgFldVal(evt)} />
                      </FormControl>
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="lastName">Last Name</InputLabel>
                        <Input id="lastName" name="LastName" autoComplete="lastName" value={this.state.LastName} onChange={evt => this.chgFldVal(evt)} />
                      </FormControl>
                      <br />
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="EmailAddress">Email Address</InputLabel>
                        <Input id="EmailAddress" name="EmailAddress" autoComplete="EmailAddress" value={this.state.EmailAddress} onChange={evt => this.chgFldVal(evt)} />
                      </FormControl>
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="Address">Address</InputLabel>
                        <Input name="Address" id="Address" autoComplete="Address" value={this.state.Address} onChange={evt => this.chgFldVal(evt)} />
                      </FormControl>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel htmlFor="CompanyName">Company Information</InputLabel>
                        <Input name="CompanyName" id="1234567" autoComplete="CompanyName" value={this.state.CompanyName} onChange={evt => this.chgFldVal(evt)} />
                      </FormControl>
                    </form>
                  </MuiThemeProvider >
                </div>
              </div>
              <div id="splitterRight">
                <TabStrip selected={this.state.selectedTab} onSelect={this.handleSelect}>
                  <TabStripTab title="Additional Info">
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4>Contact Information</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Phone Number</span>
                            <input value={this.state.PhoneNumber} name="PhoneNumber" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                          <div id="div3" className="editField">
                            <span className="editFieldLabel">Business Phone</span>
                            <input value={this.state.BusinessPhone} name="BusinessPhone" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <h4>Social Media Info</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Linked In</span>
                            <input value={this.state.LinkedIn} name="LinkedIn" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Wallet Addresses">
                    <div className="editTab">
                      <div id="div1" className="editInside">
                        <h4>Ethereum Address Information</h4>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Wallet Address</span>
                          <input value={this.state.WalletAddress} name="WalletAddress" onChange={evt => this.chgFldVal(evt)} className="editInputWide" />
                        </div>
                        <div id="div2" className="editField">
                          <span className="editFieldLabel">Wallet Private Key</span>
                          <input value={this.state.PrivateWalletKey} name="PrivateWalletKey" onChange={evt => this.chgFldVal(evt)} className="editInputWide" />
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Bank Info">
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4>Banking</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Swift Number</span>
                            <input value={this.state.SwiftNumber} name="SwiftNumber" onChange={evt => this.chgFldVal(evt)} className="editInput" />
                          </div>
                        </div>
                      </div>
                      <div className="editTabRight">
                        <div id="div1" className="editInside">
                          <h4>Additional Info</h4>
                          <div id="div2" className="editField">
                            <span className="editFieldLabel">Currency</span>
                            <DropDownList data={this.state.Currencies} textField="ddName" dataItemKey="ddID" value={this.state.Currencies.find(c => c.ddID === this.state.CurrencyID)} name="CurrencyID" onChange={evt => this.chgDDFldVal(evt)} className="editInputDD" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Setup">
                    <div className="editTab">
                      <div className="editTabLeft">
                        <div id="div1" className="editInside">
                          <h4>Permissions</h4>
                          <div id="div2" className="editField">
                            <input type="checkbox" checked={this.state.EmailAllowed} name="EmailAllowed" onChange={evt => this.chgCheckboxVal(evt)} />
                            <span className="editFieldLabel">Email Allowed</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Keys">
                    <div className="editTab">
                      <div id="div4" className="editInside">
                        <div id="div5" className="editTAField">
                          <span className="editFieldLabel">Public Key</span>
                          <textarea value={this.state.PublicKey} name="PublicKey" onChange={evt => this.chgFldVal(evt)} className="editTAInputWide" />
                        </div>
                        {this.state.isWalletUser &&
                          <div>
                            <div id="div6" className="editTAField">
                              <span className="editFieldLabel">Private Key</span>
                              <textarea value={this.state.PrivateKey} name="PrivateKey" onChange={evt => this.chgFldVal(evt)} className="editTAInputWide" />
                            </div>
                            <br />
                            <div className="editField">
                              <Button icon="refresh" color="primary" onClick={this.createKeys}>Create Key Pair</Button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </TabStripTab>
                </TabStrip>
              </div>
            </Splitter>
            <div id="splitterBottom" onMouseDown={e => e.preventDefault()}>
              <TabStrip selected={this.state.selectedTab2} onSelect={this.handle2Select}>
                {SessionInfo.HasPerm51 &&
                  <TabStripTab title="All Participants">
                    <div className="editTab">               
                    <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFParticipants.slice(this.state.participantsSkip, this.state.participantsTake + this.state.participantsSkip)} 
                      /*selectedField="selected" onSelectionChange={this.selectionChange} onHeaderSelectionChange={this.headerSelectionChange}*/
                      skip={this.state.participantsSkip} take={this.state.participantsTake} total={this.state.CFParticipants.length} onPageChange={this.participantsPageChange}
                      onRowClick={this.rowParticipantClick} resizable={true}
                      pageable={{ info: true, type: 'numeric', pageSizes: true, previousNext: true }}
                      pageSize={8}>
                        <Column field="CFParticipantID" title="Participant Id" filter="numeric" width='1px' />
                        <Column field="FirstName" title="First Name" />
                        <Column field="LastName" title="Last Name" />
                        <Column field="EmailAddress" title="Email Address" />
                        <Column field="WalletAddress" title="Wallet" />
                      </TGrid>
                    </div>
                  </TabStripTab>
                }
                <TabStripTab title="Participant Projects">
                  <div className="editTab">
                    <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFProjects.slice(this.state.contractsSkip, this.state.contractsTake + this.state.contractsSkip)}
                      skip={this.state.contractsSkip} take={this.state.contractsTake} total={this.state.CFProjects.length} onPageChange={this.contractsPageChange}
                      onRowClick={this.rowProjectClick} resizable={true}
                      pageable={{ info: true, type: 'numeric', pageSizes: true, previousNext: true }}
                      pageSize={8}>
                      <Column field="CFProjectID" filter="numeric" width="1px" />
                      <Column field="ProjectName" title="Project Name" />
                      <Column field="CompanyName" title="Company Name" />
                      <Column field="ProjectSummary" title="Project Summary" />
                      {/*<Column cell={this.CommandCell} width="280px" />*/}
                    </TGrid>
                  </div>
                </TabStripTab>
                {this.state.contractsVisible &&
                  <TabStripTab title="Project Contracts for Participant">
                    <div className="editTab">
                      <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFProjectContracts.slice(this.state.projectsSkip, this.state.projectsTake + this.state.projectsSkip)}
                        skip={this.state.projectsSkip} take={this.state.projectsTake} total={this.state.CFProjectContracts.length} onPageChange={this.projectsPageChange}
                        onRowClick={this.rowProjectContractClick} resizable={true}
                        pageable={{ info: true, type: 'numeric', pageSizes: true, previousNext: true }}
                        pageSize={8}>
                        <Column field="CFProjectContractID" title="Contract ID" filter="numeric" width='1px' />
                        <Column field="ProjectContractName" title="Project Contract" width='200px' />
                        <Column field="Description" title="Description" width='350px' />
                        <Column field="CurrentStepID" title="Current Step" width='180px' />
                        <Column field="CurrentRoleID" title="Current Role" width='180px' />
                        <Column field="CFProjectStatusID" title="Status" width='180px' />
                      </TGrid>
                    </div>
                  </TabStripTab>
                }
              </TabStrip>
            </div>
          </Splitter>
        </div>
        <PageFooter L1='Home' IsApp='y' />
      </div>
    );
  }
}

export default ParticipantProfile;