//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ContractFlow - Application Main control and router
//                Version 0.56 - April 6, 2021
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Access omAPI at ucsinventory.com  - see URI in CommonCode
// Bugs:
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react'
import { Switch, Route } from 'react-router-dom'                 
import './App.css';
//import "@progress/kendo-theme-default/dist/all.css";  // scss.all.scss
////import "@progress/kendo-theme-default/dist/all.css"; // dist.all.css
import './custom.scss';                
import AppMain from './AppMain'
import AboutPage from './AboutPage'
import SettingsPage from './SettingsPage'
import ProjectDirectory from './ProjectDirectory'
import ProjectDetail from './ProjectDetail'
import ProjectContract from './ProjectContract'
import WorkflowDefinition from './WorkflowDefinition' 
import ParticipantProfile from './ParticipantProfile'
import ContactUs from './ContactUs'
import Login from './Login'
import Register from './Register'              
                   
export const SessionInfo = {
  session: '',
  sequenceid: 0,
  logonName: '',
  message: '', 
}

class App extends React.Component {
  constructor(props) {
    super(props);
    SessionInfo.session = '';
    SessionInfo.sequenceid = 0;
    //----
    SessionInfo.debug = false; // Note - debug=true connects to local server - false to 137.135.60.70
    SessionInfo.debugProd = SessionInfo.debug ? "D" : "P";
    SessionInfo.appVersion = 0.56; // April 6, 2021 - Upgrade to current CommonCode.js    
    //SessionInfo.appVersion = 0.55; // CURRENT VERSION - January 31, 2020  
    //SessionInfo.appVersion = 0.54; // CURRENT VERSION - June 17 2019      
    //SessionInfo.appVersion = 0.32; // CURRENT VERSION - May 30, 2019
    SessionInfo.URI = 'http://localhost:2000/api/omUI';  // Development - NOTE - on http and Port: 2000 - 8Feb20 - somehow the 2000 is getting translated to 43348??  
    //SessionInfo.URI = 'http://localhost:433/api/omUI';  // Development - NOTE - on http and Port: 433 - this does not work
    SessionInfo.Platform = 'D';
    if (!SessionInfo.debug) {
      //SessionInfo.URI = 'https://ucsinventory.com:44348/api/omUI'; //Production 
      SessionInfo.URI = 'https://ucsinventory.com:443/api/omUI'; //Production
      //SessionInfo.URI = 'http://omecron.com:80/api/omUI'; //Production 
      SessionInfo.Platform = 'P';
    }
    SessionInfo.logonName = '';
    SessionInfo.message = "";
    SessionInfo.notifyMessage = "";
    SessionInfo.flagLevel = 0;
    SessionInfo.debug = true;
    SessionInfo.isApp = false;
    SessionInfo.History = undefined;
    //SessionInfo.web3 = undefined;
    SessionInfo.registerCounter = 0;
    SessionInfo.tempLogon = false;
    // Logged In Information
    SessionInfo.userID = 0;
    SessionInfo.CFParticipantID = 0;
    SessionInfo.Name = '';
    SessionInfo.EmailAddress = '';
    // Control Information
    SessionInfo.ProjectContractsUpdated = true;
    // Permissions
    SessionInfo.HasPerm51 = false; // View all Participants
    // Page specific Functions
    SessionInfo.currentPage = '';
    SessionInfo.currSaveFunc = 0;
    SessionInfo.currDeleteFunc = 0;
    SessionInfo.currClearFunc = 0;
    SessionInfo.searchFunc = undefined;
    // Page State Storage 
    SessionInfo.ParticipantProfile = undefined;
    SessionInfo.ProjectDetail = undefined;
    SessionInfo.ProjectContract = undefined;
    SessionInfo.WorkflowDescription = undefined;
    SessionInfo.SettingsPage = undefined;
    // Key Selections
    SessionInfo.SelectedProjectID = 0;
    SessionInfo.SelectedParticipantID = 0;
    SessionInfo.SelectedProjectContractID = 0;
    SessionInfo.PersonInfo = {
      // Customer Info
      CustomerID: 0,
      FirstName: '',
      AdditionalName: '',
      LastName: '',
      Address: '',
      City: '',
      ProvinceStateID: 0,
      CountryID: 0,
      PostalCode: '',
      DateOfBirth: undefined,
      Gender: '',
      PhoneNumber: '',
      PhoneNumber2: '',
      EmailAddress: '',
      EmailReceipts: '',
      EmailPromotions: '',
      SMSReminders: '',
      PersonPhotoID: 0,
    }
    SessionInfo.MenuTree = undefined;
    SessionInfo.PersonPhotoURL = undefined;
  }
  state = {
    loading: true,
  }
  componentDidMount() {
    this.setState({ loading: false });
  }

  loggedIn() {
    return true;
  }

  requireAuth(nextState, replace) {
    if (SessionInfo.session === '') {
      replace({
        pathname: '/login'
      })
    }
  }

  render() {
    return (
      <div>
        <Switch> 
          <Route exact path='/' component={AppMain} />
          <Route exact path='/AppMain' component={AppMain} />
          <Route exact path='/Login' component={Login} />
          <Route exact path='/Register' component={Register} />
          <Route exact path='/AboutPage' component={AboutPage} />
          <Route exact path='/SettingsPage' component={SettingsPage} />
          <Route path='/ProjectDirectory' component={ProjectDirectory} onEnter={this.requireAuth} />
          <Route path='/ProjectDetail' component={ProjectDetail} onEnter={this.requireAuth} />
          <Route path='/ProjectContract' component={ProjectContract} onEnter={this.requireAuth} />
          <Route path='/ParticipantProfile' component={ParticipantProfile} onEnter={this.requireAuth} />
          <Route path='/WorkflowDefinition' component={WorkflowDefinition} onEnter={this.requireAuth} />
          <Route path='/ContactUs' component={ContactUs} onEnter={this.requireAuth} />
        </Switch>
      </div>
    )
  }
}

export  default App
