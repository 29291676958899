//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CommonCode - Code to support working with React in conjunction with Telerik Controls and the omUIapi and general helper functions
//              Version 1.2 - April 5, 2021
// Note: This is the ContractFlow version - uses ProductCodeID = 260
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';                                                                                                                                  
import { SessionInfo } from "./App";
//import { PageHeader } from 'react-bootstrap';
//import { useGoogleLogin } from 'react-use-googlelogin';   
//const web3 = new Web3(window.web3.currentProvider); 
const sjcl = require('sjcl'); // Not sure about this - required for codec sub      
var keypair = require("keypair");
const GoogleAuthContext = React.createContext();
// https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
// Note - control parm goes to y: used for parameter parsing 
export default async function accessOMAPI(operation, control, tableID, key, rdata, modexMethod, optionColumns) {
  let CD;
  let result;
  let httpMethod;                  
  let SessionID = SessionInfo.session;
  if (SessionInfo.sequenceid < 100001) {
    console.log(`Bad Call Sequence: ${SessionInfo.sequenceid} - set to 100001`);
    SessionInfo.sequenceid = 100001;
  }
  else
    SessionInfo.sequenceid += 1;
  if (Number.isInteger(tableID) === false) {
    console.log("*NOT INTEGER* TableID: " + tableID + " Operation: " + operation);
    return;
  }
  else if (Number.isInteger(key) === false) {
    key = parseInt(key, 0);
    if (Number.isInteger(key) === false) {     
      console.log("*NOT INTEGER* key: " + key + " Operation: " + operation);
      return;
    }
  }      
  if (!modexMethod)
    modexMethod = '';
  if (!optionColumns)
    optionColumns = '';
  //console.log("SessionID: " + SessionID + " SequenceID: " + SessionInfo.sequenceid + " Operation: " + operation);
  try { //--------------------------------------------------------------------------------------------------- 
    if (operation < 100) {
      const RestHeader = {"Content-Type": "application/json; charset=utf-8"};
      httpMethod = "GET";
      let getCD = { x: { s: SessionID, q: SessionInfo.sequenceid, t: tableID, k: key, i: 0, o: operation, y: control, f: modexMethod, m: optionColumns }, d: {} };
      if (!rdata)
        rdata = {};
      getCD.d = rdata;
      //console.log("GetCD: " + JSON.stringify(getCD)); 
      //console.log("Get URI: " + JSON.stringify(SessionInfo.URI));
      const getData = encodeURI(SessionInfo.URI + '?CDataStr=' + JSON.stringify(getCD));  //  + '?CDataStr={x:{s:' + SessionID + ',q:' + SequenceID + ',t:' + tableID + ',k:' + key + ',y:0,o:' + operation + ',m:""},d:{}}');
      result = await fetch(getData, { mode: "cors", headers: RestHeader });  // HTTP GET  , {request-no-cors}
      //console.log("Get Result: " + JSON.stringify(result));
    } //----------------------------------------------------------------------------------------------------
    else {
      if (operation >= 100 && operation < 200) {
        httpMethod = "POST";
        operation -= 100;
      }
      else if (operation >= 200 && operation < 300) {
        httpMethod = "PUT";
        operation -= 200;
      }
      else if (operation >= 300 && operation < 400) {
        httpMethod = "PATCH";
        operation -= 300;
      }
      else if (operation >= 400 && operation < 500) {
        httpMethod = "DELETE";
        operation -= 400;
      }
      else {
        httpMethod = "UNKNOWN";
      }
      let updata = { x: { s: SessionID, q: SessionInfo.sequenceid, t: tableID, k: key, i: 0, o: operation, y: control, f: modexMethod, m: optionColumns }, d: {} };
      if (rdata === undefined)
        rdata = {};  
      updata.d = rdata;
      let upstr = JSON.stringify(updata);
      //console.log("Access: '" + httpMethod + "' Command: " + upstr);
      const RestHeader = {
        "Content-Type": "application/json; charset=utf-8",
        "Content-Length": upstr.length
      };                    
      //console.log("Update Header: " + JSON.stringify(RestHeader)); 
      //console.log("Put URI: " + JSON.stringify(SessionInfo.URI));
      result = await fetch(SessionInfo.URI, {
        method: httpMethod, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        headers: RestHeader,
        body: upstr // body data type must match "Content-Type" header
      });
    }//---------------------------------------------------------------------------------------------------
    //console.log("result: " + JSON.stringify(result)); //- this displays {}
    CD = await result.json();                             
    //console.log(httpMethod + " post CD: " + JSON.stringify(CD)); // GET post CD:
    //if (CD === undefined || CD === null) { // undefined for 61 ping                 
    if (!CD) { // undefined for 61 ping
      console.log("OMAPI operation: " + operation + " returned NULL CD");
      if (++SessionInfo.errorCount > 5)
        LogOffResetMsg("Unable To Access Server");
      else
        displayError("Unable to Access Server");
      CD = { x: { s: SessionID, q: SessionInfo.sequenceid, t: 0, k: 0, i: 0, o: 0, y: 0, f: '', m: '' }, d: {} };
    }
    else {
      if (operation < 100 || operation > 110) { // Signon/Register
        displayCDMessage(CD);
        //.log("o: " + CD.x.o);              
        //if (CD.x.o > 9800) // Error Level in OMAPI
        if (CD.x.o > 9990) // Error Level in OMAPI
          LogOffReset("LogOff Error: " + CD.x.o + " operation: " + operation);
      }
      SessionInfo.session = CD.x.s;
      if (SessionInfo.errorCount > 0) {
        displayNotify("Access to Server Restored");
        SessionInfo.errorCount = 0;
      }
    }
    //console.log("SessionInfo.errorCount: " + SessionInfo.errorCount);
  } catch (error) { //****** COMM ERROR ***************
    console.log("Fail accessOMAPI: " + httpMethod + " Op: " + operation + "(" + SessionInfo.errorCount + ") Error: " + error.message);
    if (++SessionInfo.errorCount > 5)
      LogOffResetMsg("Lost Access to Server");
    else
      displayError("Unable to Access Server (" + SessionInfo.errorCount + ")");
    CD = { x: { s: SessionID, q: SessionInfo.sequenceid, t: 0, k: 0, i: 0, o: 0, y: 0, f: '', m: '' }, d: {} };
  }
  return CD;
}  
// Log on, signon, sign on, Login, log in
export async function LogOn(userName, password) {
  let product = 260; // ContractFlow
  let site = 2000007;
  let entity = 0; // default 0, 3000487, 3000488
  let timeAdjust = -480;
  let manufacturer = window.navigator.language; //window.navigator.DeviceInfo.manufacturer; 
  if (!manufacturer)
    manufacturer = "browser";
  //let deviceName = window.navigator.geolocation; // Device Name (Motz's iPhone)
  let cookieEnabled = navigator.cookieEnabled;
  let deviceName = window.navigator.platform; // Device Name (Motz's iPhone)
  if (!deviceName)
    deviceName = "";
  let platform = window.navigator.appName; // Platform (Android)
  if (!platform)
    platform = "React";
  let idiom = "Web"; // Idiom (Phone)
  let Timezone = window.navigator.timezone;
  if (!Timezone)
    Timezone = "unknown";
  let deviceType = window.navigator.language; // Device Type (Physical)
  if (!deviceType)
    deviceType = "unknown";
  const currentTime = new Date();
  if (!currentTime)
    currentTime = "Unknown";
  //const currentTime = CT.toString();
  let ldata = {
    UserName: userName, Password: password, AppProductID: product, AppSiteID: site, AppEntityID: entity, TimeAdjust: timeAdjust, KeyValue: "", Manufacturer: manufacturer, OptiVersion: SessionInfo.appVersion.toString(),Platform: platform, Idiom: idiom, SystemName: deviceName.toString()
  }; // Name: deviceName}; // Platform: platform, Idiom: idiom, OptiVersion: SessionInfo.appVersion,DeviceType: deviceType.toString(), TimeZone: Timezone.toString(), DaylightSavings: "N", Time: currentTime.toString()};
  if (SessionInfo.session === undefined)
    SessionInfo.session = 0;
  console.log(" LogOn ldata: " + JSON.stringify(ldata));
  const CD = await accessOMAPI(101, 0, 0, 0, ldata);
  if (!CD) {
    console.log('Logon Failed - Communication Problem');
    notify('Logon Failed - Communication Problem');
  }
  else {
    //console.log(" LogOn CD: " + JSON.stringify(CD));
    if (CD.x.o < 9500) { // Successful logon
      SessionInfo.session = CD.x.s;   
      if (SessionInfo.sequenceid > CD.x.q) {
        // Report a problem    
        console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        //SessionInfo.sequenceid = CD.x.q;
      }
      else
        SessionInfo.sequenceid = CD.x.q;
      if (SessionInfo.sequenceid < 100001) {
        console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        SessionInfo.sequenceid = 100001;
      }
      SessionInfo.appEntityID = CD.x.t;
      SessionInfo.logonName = userName;
      SessionInfo.logonEmailAddress = userName; // Should return email address from logon (and phonenumber)
      SessionInfo.userID = CD.x.k;       
      await GetCustomerInfo();
      if (CD.x.s !== undefined && SessionInfo.userID > 100000)
        SessionInfo.loggedOn = true;
      let PCD = await accessOMAPI(51, 0, 0, 51); // Perm51       
      SessionInfo.PermLevel = 0;
      PCD = await accessOMAPI(51,0, 0, 492, 0, null, null); 
      //console.log(` Perm 492: ${PCD.x.y}`);
      if (PCD.x.y > 0)
        SessionInfo.PermLevel = 492;
      PCD = await accessOMAPI(51, 0, 0, 494, 0, null, null);
      //console.log(` Perm 494: ${PCD.x.y}`);
      if (PCD.x.y > 0)
        SessionInfo.PermLevel = 494;
      PCD = await accessOMAPI(51, 0, 0, 497, 0, null, null);
      //console.log(` Perm 497: ${PCD.x.y}`);
      if (PCD.x.y > 0)
        SessionInfo.PermLevel = 497;
      console.log("Login - Set Session ID: " + CD.x.s + " set: " + SessionInfo.session + " Has51: " + JSON.stringify(PCD) + " PermLvl: " + SessionInfo.PermLevel);
      if (userName === "CFUser")
        SessionInfo.HasPerm51 = true;
      else
        SessionInfo.HasPerm51 = false;
      return "ok";
    }
    else
      return CD.x.m;
  }
}
export async function KeyLogOn() {
  const CD = await accessOMAPI(104, 0, 0, 0);
  if (!CD) {
    //console.log('Logon Failed - Communication Problem');
    notify('Key Logon Failed - Communication Problem');
  }
  else {
    console.log("Key LogOn CD: " + JSON.stringify(CD));
    if (CD.x.o < 9500) { // Successful logon
      SessionInfo.session = CD.x.s;
      if (SessionInfo.sequenceid > CD.x.q) {
        // Report a problem    
        console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        //SessionInfo.sequenceid = CD.x.q;
      }
      else
        SessionInfo.sequenceid = CD.x.q;
      if (SessionInfo.sequenceid < 100001) {
        console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
        SessionInfo.sequenceid = 100001;
      }
      SessionInfo.logonName = "Temp";
      SessionInfo.userID = CD.x.k;  
      await GetCustomerInfo();
      if (CD.x.s !== undefined && SessionInfo.userID > 100000)
        SessionInfo.loggedOn = true;
      return "ok";
    }
    else
      return CD.x.m;
  }
}
export async function AddressLogOn() {      
  console.log("Address Log On");
  //const accounts = await getEthAccounts();
  //console.log("Accounts: " + accounts[0]);
  //if (accounts && accounts.length > 0) {
  //  const CD = await accessOMAPI(105, 0, 0, accounts[0]);
  //  if (!CD) {
  //    //console.log('Logon Failed - Communication Problem');
  //    notify('Token Logon Failed - Communication Problem');
  //  }
  //  else {
  //    console.log("Key LogOn CD: " + JSON.stringify(CD));
  //    if (CD.x.o < 9500) { // Successful logon
  //      SessionInfo.session = CD.x.s;
  //      SessionInfo.sequenceid = 1;
  //      SessionInfo.logonName = "Temp";
  //      SessionInfo.userID = CD.x.k;
  //      return "ok";
  //    }
  //    else
  //      return CD.x.m;
  //  }
  //}
  //else
  //  displayError("MetaMask must be set up and Logged On");
}
export async function GetCustomerInfo() {
  let CD = await CallOM("GetCustomerInfo", 0, 0, 0);
  console.log(" Cust data: " + JSON.stringify(CD));
  console.log(" Session data: " + JSON.stringify(SessionInfo.PersonInfo));
  if (CD !== undefined) {
    //this.setState({ CustomerID: Key });
    for (var prop in CD.d) {
      if (prop in SessionInfo.PersonInfo) {
        console.log("Set " + prop + ": " + CD.d[prop]);
        let obj = {};
        if (prop.indexOf("Date") >= 0) {
          SessionInfo.PersonInfo[prop] = new Date(CD.d[prop]); //new Date()
        }
        else
          SessionInfo.PersonInfo[prop] = CD.d[prop];
      }
    }      
    SessionInfo.PersonPhotoID = SessionInfo.PersonInfo["PersonPhotoID"];
  }
  console.log("Get Customer - photoID: " + SessionInfo.PersonPhotoID);
}
//export const GoogleAuthProvider = ({ children }) => {
//  const googleAuth = useGoogleLogin({
//    clientId: process.env.GOOGLE_CLIENT_ID, // Your clientID from Google.
//  })

//  return (
//    <GoogleAuthContext.Provider value={googleAuth}>
//      {children}
//    </GoogleAuthContext.Provider>
//  )
//}
//export const useGoogleAuth = () => React.useContext(GoogleAuthContext);

//export const LogoutButton = () => {
//  const { signOut } = useGoogleAuth();

//  return (
//    <button onClick={signOut}>Logout</button>
//  );
//};

  //export default LogoutButton;
export async function UserRegister(firstName, lastName, userName, password, confirmPassword) {
  if (password !== confirmPassword) {
    return ("pa")
  }     
  let token = '';
  //try {
  //  const accounts = await getEthAccounts(); //bring in user's metamask account walletAddresses 
  //  console.log("Register - Account 0: " + accounts[0]);
  //  if (accounts[0])
  //    token = accounts[0];
  //}
  //catch {
  //  console.log("Error on metamask access");
  //} 
  let ldata = { FirstName: firstName, LastName: lastName, UserName: userName, Password: password, ConfirmPassword: confirmPassword, Token: token, AppProductID: 260 };
  if (SessionInfo.session === undefined)
    SessionInfo.session = 0;
  console.log(" register ldata: " + JSON.stringify(ldata));
  const CD = await accessOMAPI(103, 0, 0, 0, ldata);
  console.log(" UserRegister CD: " + JSON.stringify(CD));
  if (CD.x.o < 9500) { // Successful logon
    SessionInfo.session = CD.x.s;
    if (SessionInfo.sequenceid > CD.x.q) {
      // Report a problem    
      console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
      //SessionInfo.sequenceid = CD.x.q;
    }
    else
      SessionInfo.sequenceid = CD.x.q;
    if (SessionInfo.sequenceid < 100001) {
      console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
      SessionInfo.sequenceid = 100001;
    }
    SessionInfo.logonName = userName;
    SessionInfo.logonEmailAddress = userName; // Should return email address from logon (and phonenumber)
    SessionInfo.userID = CD.x.k;
    if (CD.x.s !== undefined && SessionInfo.userID > 100000)
      SessionInfo.loggedOn = true;
    SessionInfo.errorCount = 0;
    console.log("Set Session ID: " + CD.x.s + " set: " + SessionInfo.session);
    return "ok";
  }
  else
    return CD.x.m;
}
export async function CallConfirmation(AppProductID, ConfirmationKey) {
  console.log(" Confirmation Key: " + JSON.stringify(ConfirmationKey));
  const CD = await accessOMAPI(108, 2, AppProductID, 1, ConfirmationKey);
  console.log(" Confirmation CD: " + JSON.stringify(CD));
  if (CD.x.o < 9500) { // Successful Confirmation
    SessionInfo.session = CD.x.s;
    if (SessionInfo.sequenceid > CD.x.q) {
      // Report a problem    
      console.log(`Bad Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
      //SessionInfo.sequenceid = CD.x.q;
    }
    else
      SessionInfo.sequenceid = CD.x.q;
    if (SessionInfo.sequenceid < 100001) {
      console.log(`Bad Sequence - Return Sequence ${CD.x.q}, Current Sequence: ${SessionInfo.sequenceid}`);
      SessionInfo.sequenceid = 100001;
    }
    SessionInfo.logonName = "";
    SessionInfo.userID = CD.x.k;      
    SessionInfo.logonEmailAddress = "";
    //if (CD.x.s !== undefined && SessionInfo.userID > 100000)
    //  SessionInfo.loggedOn = true;
    SessionInfo.errorCount = 0;
    console.log("Confirmation ID: " + CD.x.s + " set: " + SessionInfo.session);
    return "ok";
  }
  else
    return CD.x.m;
}
export async function CheckPermission(bit) {
  const CD = await accessOMAPI(51, 0, 0, bit);
  if (!CD) {
    if (CD.p === 1)
      return true;
  }
  return false;
}
export async function QueryPing() { // Ping Server
  if (SessionInfo.session && SessionInfo.loggedOn === true) {
    const CD = await accessOMAPI(61, 0, 0, 0, "0|0"); // Lat|Long
    if (CD) {
      if (CD.x.p === 1)
        return true;
      else
        console.log("Query Ping Failed = p=" + CD.x.p);
    }
  }
  return false;
}
export async function LogOff() {
  SessionInfo.loggedOn = false;
  SessionInfo.message = ""; // This will clear the messages in the Header message display - Header copies this value into the Display Box
  displayMessage(SessionInfo.logonName + " - Signed Out");
  await accessOMAPI(102, 0, 0, 0); // OM Logoff          
  console.log("Clear Session in Logoff");
  SessionInfo.session = '';
  SessionInfo.sequenceid = 100000;       
  // Logged In Information  
  SessionInfo.logonName = '';
  SessionInfo.userID = 0;      
  SessionInfo.logonEmailAddress = '';
  SessionInfo.loggedOn = false;
  SessionInfo.errorCount = 0;
  SessionInfo.Name = '';
  SessionInfo.notifyMessage = '';
  SessionInfo.flagLevel = 0;
  SessionInfo.currentPage = '';
  // Control Information     
  SessionInfo.notifyMessage = "";                       
  SessionInfo.flagLevel = 0;      
  SessionInfo.isApp = false;
  //SessionInfo.History = undefined; Bad - don't do this
  console.log("Session History: " + JSON.stringify(SessionInfo.History));
  SessionInfo.registerCounter = 0;  
  SessionInfo.displayHeader = true;
  //SessionInfo.displayFooter = true;  
  SessionInfo.currentPage = '';
  // Functions
  SessionInfo.currentPage = '';
  SessionInfo.currSaveFunc = 0;
  SessionInfo.currDeleteFunc = 0;
  SessionInfo.currClearFunc = 0;
  SessionInfo.searchFunc = undefined;
  SessionInfo.contractAccess = undefined; 
  SessionInfo.currRefreshGridFunc = undefined;
  SessionInfo.currAddGridRowFunc = undefined;
  SessionInfo.currExcelExportFunc = undefined;
  SessionInfo.currExpandLPane = undefined;
  SessionInfo.currCollapseLPane = undefined;
  SessionInfo.currExpandRPane = undefined;
  SessionInfo.currCollapseRPane = undefined;
  // Page Session Info                   
  SessionInfo.CustomerProfile = undefined;
  SessionInfo.StoreLocations = undefined;
  SessionInfo.StoreLocationDetail = undefined;
  SessionInfo.SettingsPage = undefined;  
  SessionInfo.AppMainPage = undefined;
  SessionInfo.StoreLocationDetail = undefined;
  SessionInfo.StoreItemDetail = undefined;
  SessionInfo.CustomerProfile = undefined;
  SessionInfo.SettingsPage = undefined;
  SessionInfo.ManagerPage = undefined;
  // Key Information
  SessionInfo.SelectLocationID = 0;
  console.log("Session Cleared"); 
}
// Log off, logoff, sign out, sign off, signoff, force logoff, force sign off, 
export async function LogOffReset(msg) {
  console.log("Logoff Reset: " + msg);
  console.log("Session History: " + JSON.stringify(SessionInfo.History));
  await LogOff();
  //console.log("End SessionInfo History: " + JSON.stringify(SessionInfo.History));
  displayWarning("Session Expired - Logoff Reset");
  if (SessionInfo.History) {
    console.log("Session goto AppMain");
    SessionInfo.History.push("/AppMain"); // Note - that the componentWillUnmount will execute for the current page
  }
  else if (this && this.props && this.props.History) {
    console.log("Goto AppMain");
    this.props.History.push("/Login"); // Note - that the componentWillUnmount will execute for the current page
  }
  else {
    SessionInfo.History.push("/Login");
  }
}
export function LogOffResetMsg(message) { 
  console.log("LogOffReset: " + message);
  displayError(message);
  LogOffReset(message);
}
export async function TestREST() {
  console.log("Test REST API");
  const CD = await accessOMAPI(70, 0, 0, 0);
  if (!CD)
    console.log("Unable to get CD");
  else
    console.log("CD Returned: " + JSON.stringify(CD));
}
export function reportError(level, message) {
  if (level > 2)
    LogOffResetMsg("Severe Error: " + message);
  else {
    let levelMsg = "Warning (" + SessionInfo.errorCount + "):";
    if (level > 1)
      levelMsg = "Rep Error: "
    if (++SessionInfo.errorCount > 5)
      LogOffResetMsg(message);
    else
      console.log(levelMsg + message)
  }
}
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export async function GetTableRow(tableID, key) {
  let CD;
  try {
    CD = await accessOMAPI(2, 0, tableID, key); // Get Stanadrd Row
  } catch (error) {
    reportError(1,"GetTableRow Error: " + error.message);
  }
  return CD;
}
export async function GetTableRowTrans(tableID, key) {
  let CD;
  try {
    CD = await accessOMAPI(2, 2, tableID, key); // 2 - Get Translated Row
  } catch (error) {
    reportError(1, "GetTableRowTrans Error: " + error.message);
  }
  return CD;
}
export async function GetTableRowRaw(tableID, key) {
  let CD;
  try {
    CD = await accessOMAPI(2, 1, tableID, key); // No Translate - 0 is standard
  } catch (error) {
    reportError(1, "GetTableRowRaw Error: " + error.message);
  }
  return CD;
}
export async function addContactInfo(item) {             
  let PKID = 0;
  try {                                                                          
    const CD = await accessOMAPI(201, 0, 544162, 0, item);
    //console.log("addContactInfo - CD: " + JSON.stringify(CD));
    item["QuestionID"] = CD.d.PKID;
    PKID = CD.d.PKID;
  } catch (error) {
    reportError(1, "addContactInfo Error: " + error.message);
  }
  return PKID;
}
export async function GetTableValues(tableID,keyName,key,fieldNames) { // Note: Assign CD.d.rows to the table data          
  return await accessOMAPI(21, 0, tableID, key, fieldNames, keyName);
}
export async function GetTableData(tableID, fieldNames) { // Note: Assign CD.d.rows to the table data  
  let CD;
  try {                                     
    CD = await accessOMAPI(1, 0, tableID, 0, undefined, '', fieldNames);
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableData (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableData Error: " + error.message);
  }
  return CD;
}
export async function GetTableDataRaw(tableID) { // Note: Assign CD.d.rows to the table data 
  let CD;
  try {
    CD = await accessOMAPI(1, 1, tableID, 0);
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableDataRaw (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableDataRaw Error: " + error.message);
  }
  return CD;
}
export async function GetTableFilter(tableID, filterCond) { // Note: Assign CD.d.rows to the table data
  //console.log("GetTableFilter: " + tableID + " - filterCond: " + filterCond);           
  let CD;
  try {
    CD = await accessOMAPI(3, 0, tableID, 0, filterCond);
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableFilter (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableFilter Error: " + error.message);
  }
  return CD;
}
export async function GetTableSearch(tableID, searchCond) { // Note: Assign CD.d.rows to the table data
  if (!searchCond)
    searchCond = '';
  else if (searchCond[0] !== "*")
    searchCond = "`" + searchCond;  // ` related search - search attached tables   
  if (SessionInfo.debug === true)
    console.log("--> TableSearch: " + JSON.stringify(searchCond));
  let CD;
  try {
    CD = await accessOMAPI(4, 2, tableID, 0, searchCond); // Translate All values (2) 
    //if (SessionInfo.debug === true)
    //  console.log("--> return TableSearch (" + tableID + "): " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "GetTableSearch Error: " + error.message);
  }
  return CD;
}
export async function GetTableSearchRaw(tableID, searchCond, columns, isSub) { // GetTableSearch in Mdx - Note: Assign CD.d.rows to the table data  Note: Can use: *DISP, *DSPO, *EDIT, *EDTO in place of column names
  if (!searchCond)
    searchCond = '';
  else if (searchCond[0] !== "*" && isSub)
    searchCond = "`" + searchCond;
  if (SessionInfo.debug === true)
    console.log("--> TableSearchRaw: " + JSON.stringify(searchCond));
  const CD = await accessOMAPI(4, 1, tableID, 0, searchCond, '', columns); // NOTranslate values - For Edit Grid 
  //if (SessionInfo.debug === true)
  //  console.log("--> return TableSearchRaw (" + tableID + "): " + JSON.stringify(CD));
  return CD;
}
export async function UpdateRow(tableID, control, allData, item, key, keyName) { // SaveRow
  let CD;
  let PKID = item[keyName];   
  //console.log("Update Row - tableID: " + tableID + " - key: " + key + " Item: " + JSON.stringify(item));
  try {
    CD = await accessOMAPI(201, control, tableID, key, item);
    if (PKID == 0) {
      PKID = CD.d.PKID;
      if (PKID > 0) {
        item[keyName] = PKID;
        if (allData)
          allData[0][keyName] = PKID;
      }
    }
  } catch (error) {
    reportError(1, "Update Row Error: " + error.message);
  }
} 
export async function DeleteRow(tableID, key) { // DeleteTableRow
  let CD;
  try {
    console.log(`DeleteRow table: ${tableID}, key: ${key}`); 
    CD = await accessOMAPI(401, 0, tableID, key);
    console.log("DeleteRow post CD: " + JSON.stringify(CD)); 
  } catch (error) {
    reportError(1, "Delete Row Error: " + error.message);
  }
  return CD;
}
export async function DeleteRowForce(tableID, key) { // DeleteTableRow
  let CD;
  try {
    console.log(`DeleteRowForce table: ${tableID}, key: ${key}`);
    CD = await accessOMAPI(402, 0, tableID, key);
    console.log("DeleteRow post CD: " + JSON.stringify(CD));
  } catch (error) {
    reportError(1, "Delete Row Force Error: " + error.message);
  }
  return CD;
}
export async function SaveRow(tableID, item, key, keyName) { // old common
  //console.log("SaveRow - key: " + key + " Item: " + JSON.stringify(item));
  const CD = await accessOMAPI(201, 0, tableID, key, item);
  let PKID = 0;
  try {
    //console.log("SaveRow - CD: " + JSON.stringify(CD));
    item[keyName] = CD.d.PKID;
    PKID = CD.d.PKID;
  } catch (error) {
    console.log("SaveRow Error: " + error.message);
  }
  return PKID;
} 
//------------------ Support Access ------------------
export async function GetDropdownData(tableID) {
  let CD;
  try {                                        
    CD = await accessOMAPI(11, 0, tableID, 1); 
    if (CD.d === undefined || CD.d === null)
      CD.d = [{ ddName: '', ddID: 0 }];
  } catch (error) {
    reportError(1, "Get Dropdown Info: " + error.message);
  }
  return CD;
}
export async function GetDependentDropdownData(tableID,key,dependentOnObjectID) {
  let CD;
  try {
    console.log("Get Dependent Dropdown table: " + tableID + " Key: " + key);
    CD = await accessOMAPI(13, 0, tableID, key, dependentOnObjectID);        
    if (CD.d === undefined || CD.d === null)
      CD.d = [{ ddName: '', ddID: 0 }];
  } catch (error) {
    reportError(1, "Get Dependent Dropdown Info: " + error.message);
  }
  return CD;
} 
// Create a Key Pair
export async function CreateKeys() { // Create Key Pair
  let pair = keypair(512); //3072); Key Pair Library
  let PublicKey = pair["public"];
  PublicKey = PublicKey.substr(31, 101);
  this.setState({ PublicKey });           
  let PrivateKey = pair["private"];
  PrivateKey = PrivateKey.substr(32);
  PrivateKey = PrivateKey.substr(0, PrivateKey.length - 31);
  await this.setState({ PrivateKey });
  await this.saveRecord();
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export async function CallOM(modexMethod,tableID,key,control,rdata,columns)
{ // Note - Control MUST be set - see OMProxy
          //case 0: None - Supports Column Specification
          //case 1: // Int
          //case 2: // String  
          //case 3: // Decimal
          //case 10: // List of Object Values 
          //case 11: // List of int Values 
          //case 12: // List of string Values 
          //case 13: // Dictionary of name-object Values  
          //case 14: // Dictionary of name-string Values
          //case 15: // List of Dictionary of name-string Values
          //case 16: // Int Array
          //case 17: // String Array
          //case 18; // Byte Array
          //case 19: // Byte Tuple   
          //case 20: // Byte Tuple  
          //case 21: // WorkTaskS
          //case 50: // Type Parameter Array
  if (!control)
    control = 0;
  if (!rdata)
    rdata = '';
  else if (control === 0)
    console.log("ERROR: parm control is 0");
  //console.log("rdata typeof: " + typeof (rdata));
  modexMethod = "CheckoutMdx_" + modexMethod;
  return await accessOMAPI(291,control,tableID,key,rdata,modexMethod,columns);
} 
export async function OMTrace(level, message) {
  let rc = -1;
  if (message) {
    let CD = await accessOMAPI(120, 420, level, 0, "", message);
    if (CD)
      rc = CD.x.o;
  }
  return rc;
}
//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const ConsoleLog = ({ children }) => {
  console.log(children);
  return false;
};
export function displayHex(mesg, value) {
  try {
    let hexStr = toHex(value);
    console.log(mesg + ": " + hexStr);
  }
  catch (error) {
    console.log(error);
  } //catch
}
// Display Message(s) returned from OM
export function displayCDMessage(CD) {
  if (CD.x.o >= 9000) {
    if (CD.x.o >= 9800) { // Error - Red
      displayError(CD.x.m);
      SessionInfo.flagLevel = 3;
    }
    else if (CD.x.o >= 9500) { // Warning - Yellow
      displayWarning(CD.x.m);
      SessionInfo.flagLevel = 2;
    }
    else { // Message - Green 
      SessionInfo.flagLevel = 1;
      if (CD.x.o < 9005 || SessionInfo.debug === true)
        displayMessage(CD.x.m);
    }
    if (SessionInfo.session !== '')
      CD.x.m = ''; // Only erase the message if currently in session
  }
  else {
    //console.log("display CD Message: '" + CD.x.m + "' - o: " + CD.x.o);
    if (CD.x.m !== "")
      SessionInfo.flagLevel = 0;
  }
}
export function displayMessage(message) {
  if (message !== undefined && message.length > 0) {
    try { // Add message to the Context message which is displayed by PageHeader 
      if (message === "GREEN") {
        SessionInfo.flagLevel = 1;
        //console.log("Message: " + message + " flag: " + SessionInfo.flagLevel);
      }
      else if (message === "YELLOW")
        SessionInfo.flagLevel = 2;
      else if (message === "RED")
        SessionInfo.flagLevel = 3; 
      else {
        if (message.length > 240)
          message = message.substring(0, 240) + " ...";
        if (SessionInfo.message === "")
          SessionInfo.message = message;
        else {
          if (SessionInfo.message.length > 2200)
            SessionInfo.message = message + '<br/>' + SessionInfo.message.substring(0, 2100) + " ...";
          else
            SessionInfo.message = message + '<br/>' + SessionInfo.message;
        }
        if (SessionInfo.debug === true)
          console.log("--> " + message);
      }
    }
    catch (error) {
      reportError(1, "displayMessage Error: " + error.message);
    }
  }
}  
export function notify(message) {
  SessionInfo.notifyMessage = message;
  displayMessage(message);
}
export async function askQuestion(message) {
  SessionInfo.question = message;
}
function apiFunctionWrapper(query) {
  return new Promise((resolve, reject) => {
    apiFunction(query, (successResponse) => {
      resolve(successResponse);
    }, (errorResponse) => {
      reject(errorResponse)
    });
  });
}
function apiFunction(query, successCallback, errorCallback) {
  if (query === "bad query") {
    errorCallback("problem with the query");
  }
  successCallback("Your query was <" + query + ">");
}
export function displayWarning(message) {
  try {
    if (message.length > 0) {
      displayMessage("<span style='color:#BF8D02'>" + message + "</span>");
      SessionInfo.flagLevel = 2;
    }
  }
  catch (error) {
    console.log("displayWarning: " + error);
  }
}          
export function displayError(message) { // OMTrace
  try {
    if (message.length > 0) {
      displayMessage("<span style='color:#ff1a75;font-size:10pt;font-weight:bold'>" + message + "</span>");
      SessionInfo.flagLevel = 3; 
    }
  }
  catch (error) {
    console.log("displayError: " + error);
  }
}
export function displayNotify(message) {
  try {
    if (message.length > 0) {
      displayMessage("<span style='color:#ed1cd1;font-size:14pt;font-weight:bold'>" + message + "</span>");
      SessionInfo.flagLevel = 3; 
      SessionInfo.notifyMessage = message;
    }
  }
  catch (error) {
    console.log("Error in displayNotify: " + error);
  }
}
//export async function SaveToIPFS() { // save File on IPFS - onSaveToIPFS = async (event) => {  // Save File to IPFS
//  //event.preventDefault();
//  const accounts = await getEthAccounts(); //bring in user's metamask account walletAddresses 
//  this.setCurrentParticipantID(accounts[0]);
//  if (this.state.ownerBalance === 0)
//    displayError('The contract must be Initialized First - Press: Init Contract Info');
//  else if (accounts[0] !== this.state.ownerAddress) {
//    console.log("Account0: " + accounts[0] + " ownerAddress: " + this.state.ownerAddress + " Current Role: " + this.state.currentRole + " isCurrOwner: " + this.state.isCurrOwner);
//    displayError('Only the Contract Owner can Save a Document');
//  }
//  else {
//    let isInitial = await CFContract.methods.isStepInitial.call({ from: accounts[0] });
//    if (this.state.isSavingDocument) {
//      displayMessage("Document Save in Progress");
//      return;
//    }
//    else if (isInitial === false)
//      displayError("Can only Save a File when in Initial State");
//    else if (!this.state.documentClear)
//      displayError('A file must be selected');
//    else if (!this.state.DocumentKey)
//      displayError('A Document Key Must be Provided');
//    else if (this.state.DocumentKey.length < 8)
//      displayError('The Document Key Must be at least 8 Characters');
//    else {
//      this.state.isSavingDocument = true;
//      displayMessage('Saving Document');
//      let ownerAddress = await CFContract.methods.getOwnerAddress().call({ from: accounts[0] });
//      if (ownerAddress === '') {
//        this.setState({ ownerAddress: accounts[0] });
//        this.state.ownerAddress = accounts[0];
//      }
//      else
//        await this.setState({ ownerAddress });
//      this.state.walletAddresses[0] = this.state.ownerAddress;
//      const key = sjcl.random.randomWords(4);
//      let saltStr = sjcl.codec.base64.fromBits(key);
//      //console.log("saltStr:  " + saltStr);
//      let lth = saltStr.length;
//      //console.log("saltStr lth:  " + lth);
//      if (lth > 16)
//        saltStr = saltStr.toString().substring(0, 16);
//      //console.log("saltStr: " + saltStr);
//      //console.log("document length: " + this.state.documentClear.length);
//      let bytes = new Uint8Array(this.state.documentClear); // Fr-1
//      //console.log("bytes length: " + bytes.length);
//      let bits = toBitArrayCodec(bytes); // Fr-2
//      //console.log("bits length: " + bits.length);
//      let base64bits = sjcl.codec.base64.fromBits(bits); // Fr-3 - Base64 Encode Document  
//      //console.log("base64bits - length: " + base64bits.length);
//      //this.saveTextFile(base64bits, 'before.txt');
//      //-----------------------------------------------------------------------------------
//      // Test decrypt and decode - Keep 
//      //let docBuf = sjcl.codec.base64.toBits(base64bits); // Reduce size of document  
//      //console.log("docBuf-3: " + docBuf); // Bits   
//      //let byteBuf = this.fromBitArrayCodec(docBuf);
//      //console.log("docBuf-2: " + byteBuf);
//      //let byteBuf2 = Uint8Array.from(byteBuf);       
//      //docBuf = new TextDecoder("utf-8").decode(byteBuf2.buffer);
//      //console.log("docBuf-1: " + docBuf);
//      //-----------------------------------------------------------------------------------
//      //let enObj = sjcl.encrypt(this.state.DocumentKey, base64bits, { count: 2048, salt: saltStr, ks: 256 }); 
//      //console.log("before encrypt dockey Length: " + base64bits.length); // Bits  
//      let encDocumentStr = sjcl.encrypt(this.state.DocumentKey, base64bits, { salt: saltStr, ks: 256 }); //, { count: 2048, salt: saltStr, ks: 256 }); 
//      //console.log("encrypted document Length: " + encDocumentStr.length); // Bits  
//      //--------------------------------------------------------  
//      //let salt = "jrEp464459dE2Tyls="; //                                                                                         
//      //var out = sjcl.encrypt("password", "The quick brown fox jumps over the lazy dog", { count: 2048, salt: salt, ks: 256 });
//      //console.log("Encrypt Object: " + enObj);
//      //let encObj = JSON.parse(out);
//      //displayMessage("encrypt: " + encObj.ct);
//      //out = sjcl.decrypt("password", out);
//      //displayMessage("original: " + out);
//      //------------------------------------------------------------
//      let encObj = JSON.parse(encDocumentStr);
//      let encBuffer = encObj.ct;
//      //console.log("Encrypted: " + buffer);
//      //console.log("Plain: " + this.state.buffer);
//      //out = sjcl.decrypt("password", this.state.DocumentKey);
//      //displayMessage("encrypted: " + out);

//      this.setState({ DocumentNonce: encObj.iv }); // Nonce
//      this.setState({ DocumentSalt: saltStr });      // Salt 
//      let ContractDocumentType = GetFileExtension(this.state.ContractFileName);
//      this.setState({ ContractDocumentType });
//      //encObj.ct = '';
//      encDocumentStr = JSON.stringify(encObj);
//      this.setState({ ipfsEncDocument: encDocumentStr });
//      //console.log("Encrypt Str: " + encObjStr);
//      //---------------------------------------------------

//      //save document to IPFS,return its hash#, and set hash# to state
//      //https://github.com/ipfs/interface-ipfs-core/blob/master/SPEC/FILES.md#add  
//      await ipfs.add(Buffer.from(encBuffer), (ipfsError, IPFSDocumentAddress) => {
//        if (ipfsError)
//          console.log("IPFS Error: " + ipfsError);
//        console.log("IPFS Doc Address: " + IPFSDocumentAddress);
//        this.setState({ IPFSDocumentAddress: IPFSDocumentAddress[0].hash });
//        this.saveDocumentInContract(); // Save the Document and supporting information in the Contract
//        // Init Contract will also save the document hash (address in IPFS)
//        if (this.state.CFProjectStatusID >= 4)
//          displayMessage("Encrypted Document (" + ContractDocumentType + ") saved on IPFS and in the Contract");
//      }); //await ipfs.add 
//      if (this.state.CFProjectStatusID >= 4 && ContractDocumentType === 'pdf') {
//        // Display the file
//        var blob = new Blob([this.state.documentClear], { type: 'application/pdf' }); //"text/plain;charset=utf-8" });
//        var url = URL.createObjectURL(blob);
//        var a = document.createElement('a');
//        a.download = "loadFile.pdf";
//        a.href = url;
//        a.click();
//      }
//      this.state.isSavingDocument = true;
//    }
//  }
//}; //onSaveToIPFS  
//---------- General Functions --------------------------------------------
export function toHex(str) {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    if (hex.length === 1)
      hex = '0' + hex;
    arr1.push(hex);
  }
  return arr1.join('');
}
         
export function GetFileExtension(filename) {
  //console.log("filename: " + filename);
  var a = filename.split(".");         
  //onsole.log("a: " + JSON.stringify(a));
  if (a.length <= 1 || (a[0] === "" && a.length === 2)) {
    return "";
  }
  return a.pop();
}
export function saveTextFile(docBuff, filename) {
  var blob = new Blob([docBuff], { type: 'text/plain;charset=utf-8' });
  var url = URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.download = filename;
  a.href = url;
  a.click();
}
export function convertDataURIToBinary(dataURI) {
  const BASE64_MARKER = ';base64,';
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
//-------------------------------------------------------------------------------------------------   
export function toBitArrayCodec(bytes) {
  var out = [], i, tmp = 0;
  for (i = 0; i < bytes.length; i++) {
    tmp = (tmp << 8) | bytes[i];
    if ((i & 3) === 3) {
      out.push(tmp);
      tmp = 0;
    }
  }
  if (i & 3) {
    out.push(sjcl.bitArray.partial(8 * (i & 3), tmp));
  }
  return out;
}
// Convert from a bitArray to an array of bytes.
export function fromBitArrayCodec(arr) {
  let out = [];
  let bl = sjcl.bitArray.bitLength(arr), i, tmp;
  for (i = 0; i < bl / 8; i++) {
    if ((i & 3) === 0) {
      tmp = arr[i / 4];
    }
    out.push(tmp >>> 24);
    tmp <<= 8;
  }
  return out; //.buffer; // Convert to Array Buffer
}
                 