import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'                 
import 'whatwg-fetch'; // Support for Window.fetch - replace XMLHttpRequest
import './App.css';
//import '@progress/kendo-theme-default/dist/all.css'; // dist/all.css  
import './custom.scss';                                
import { Grid as TGrid, GridColumn as Column } from '@progress/kendo-react-grid';
import { SessionInfo } from './App';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter.js'; 
import { UpdateRow, DeleteRow, displayMessage, displayError, GetTableData, GetTableSearch } from './CommonCode.js'; 

class ProjectDirectory extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);            
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  state = {            
    CFProjects: [],
    saveCFProjects: [], 
  };

  async componentDidMount() {
    if (!SessionInfo.session)
      return;
    else {
      SessionInfo.searchFunc = this.searchCurrent;
      await this.getProjectRecords();
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  CommandCell;
  searchCurrent = async (search) => {
    displayMessage("Search for Current Projects");
    await this.getProjectRecords(search);
  }
  getProjectRecords = async (search) => { // Get the available Participants
    let CD;                          
    //if (!search)
    //  CD = await GetTableData(543609); // Get Participants attached to this Project
    //else
      CD = await GetTableSearch(543609, search); // Get Participants attached to this Project
    if (CD.x.o >= 9000 && CD.x.o < 9500) {
      try {
        const CFProjects = CD.d.rows;
        //const saveCFProjects = CFProjects; //CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
        this.state.saveCFProjects = CFProjects;
        this.setState({ CFProjects });
      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      displayError("Unable to retrieve Projects - 1 : " + CD.x.m);
      this.setState({ CFProjects: [] });
    }
  }
  //------------------------- Edit Grid ------------------------------------------------------------------
  enterInsert() {
    const dataItem = { inEdit: true };
    const allRecords = this.state.CFProjects.slice();
    allRecords.unshift(dataItem); // Add to the beginning
    this.update(allRecords, dataItem);
    this.setState({ CFProjects: allRecords });
  }
  enterEdit(dataItem) {
    this.update(this.state.CFProjects, dataItem).inEdit = true;
    this.setState({ CFProjects: this.state.CFProjects.slice() });
  }
  save(dataItem) {
    dataItem.inEdit = undefined;
    if (dataItem.CFProjectID === undefined)
      dataItem.CFProjectID = 0;
    dataItem.CFProjectID = this.update(this.state.CFProjects, dataItem).CFProjectID;
    //let index = this.state.CFProjects.findIndex(p => p === dataItem || dataItem.CFProjectID && p.CFProjectID === dataItem.CFProjectID);
    this.setState({ CFProjects: this.state.CFProjects.slice() });
    UpdateRow(543619, this.state.CFProjects, dataItem, dataItem.CFProjectID, "CFProjectID"); // Save to OM
  }
  cancel(dataItem) {
    if (dataItem.CFProjectID) {
      let originalItem = this.state.saveCFProjects.find(p => p.CFProjectID === dataItem.CFProjectID)
      if (originalItem.inEdit) originalItem.inEdit = false;
      this.update(this.state.CFProjects, originalItem);
    } else {
      this.update(this.state.CFProjects, dataItem, !dataItem.CFProjectID); // remove false
    }
    this.setState({ CFProjects: this.state.CFProjects.slice() });
  }
  remove(dataItem) {
    dataItem.inEdit = undefined;
    let key = dataItem.CFProjectID;
    this.update(this.state.CFProjects, dataItem, true);
    this.update(this.state.saveCFProjects, dataItem, true);
    DeleteRow(543619, key); // Save to OM
    this.setState({ CFProjects: this.state.CFProjects.slice() });
  }
  itemChange(event) {
    const value = event.value;
    const name = event.field;
    if (!name) {
      return;
    }
    const updatedData = this.state.CFProjects.slice();
    const item = this.update(updatedData, event.dataItem);
    item[name] = value;
    this.setState({ CFProjects: updatedData });
  }
  update(data, item, remove) {  // data - is the entire data set (JSON), item - is the current line item
    let updated;
    //eslint-disable-next-line                   
    let index = data.findIndex(p => p === item || item.CFProjectID && p.CFProjectID === item.CFProjectID); // Note - for new line will find 0
    if (index >= 0) {
      updated = Object.assign({}, item);
      data[index] = updated;
    }
    if (remove) {
      data = data.splice(index, 1);
    }
    return data[index];
  }

  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    const current = this.state.CFProjects.findIndex(dataItem => dataItem === event.dataItem);  
    this.lastSelectedIndex = last = current;
    this.state.CFProjects.forEach(item => item.selected = false);
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.CFProjects[i].selected = select;
    }
    let PKID = this.state.CFProjects[current].CFProjectID;
    SessionInfo.SelectedProjectID = PKID;
    console.log("Select PKID: " + PKID);
    this.props.history.push("/ProjectDetail")
  }

  //------------------------- Common Code ------------------------------------------------------------------ 

  render() {
    if (!SessionInfo.session)
      return (<Redirect to='/' />); 
    return (
      <div id="projectDirectory" className="pageMain">       
        <PageHeader L1='Home' Select='1' Title='Project Directory' IsApp='y'/>
        <div id="mainCntr">
          <div>
            <TGrid style={{ position: 'absolute', top: 0, height: '100%' }} data={this.state.CFProjects} selectedField="selected"
              onSelectionChange={this.selectionChange}
              onHeaderSelectionChange={this.headerSelectionChange}
              onRowClick={this.rowClick} resizable={true}
              sortable={true}
              pageable={true}
              pageSize={8}> 
              <Column field="CFProjectID" filter="numeric" width="1px" />
              <Column field="ProjectName" title="Project Name" width="250px" />
              <Column field="CompanyName" title="Company Name" width="250px" />
              <Column field="ProjectSummary" title="Project Summary" />
              {/*<Column cell={this.CommandCell} width="280px" />*/}
            </TGrid>
          </div>
        </div>            
        <PageFooter L1='Home' IsApp='y' />
      </div>
      );
  }
}

export default ProjectDirectory;